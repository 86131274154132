import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../../api/AxiosInstance";
import Styles from "./_chat.module.css";
import { ImAttachment, ImCross, ImSpinner11 } from "react-icons/im";
import { SocketContext } from "../../../ioContext/socketIo";
import Conversation from "./Conversation";
import Message from "./Message";
import UserNameData from "./UserNameData";
import wavEncoder from "wav-encoder";
import {
  Modal,
  ButtonToolbar,
  DatePicker,
  Toggle,
  Tooltip,
  Whisper,
} from "rsuite";
import { AiFillDelete } from "react-icons/ai";
import {
  BsFillBarChartFill,
  BsFillMicFill,
  BsFillMicMuteFill,
  BsFillPlayCircleFill,
} from "react-icons/bs";
import FileUpload from "./FileUpload";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import { IoSendSharp } from "react-icons/io5";
import { globalContext } from "../UsersContext";
import Cookies from "js-cookie";
import { FaMinus, FaPlus } from "react-icons/fa";
import { BiPlusMedical } from "react-icons/bi";
import handleError from "../ErrorHandler";
// text editor
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import RemoveHtmlTag from "../../functions/RemoveHtmlTag";
import { Box, Button, Divider, Modal as ModalMui } from "@mui/material";
const styleModal1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
};
const UserGroupComp = () => {
  let navigate = useNavigate();
  let { groupCode } = useParams();
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const socket = useContext(SocketContext);
  let scrollRef = useRef();
  let [loading, setLoading] = useState(false);

  let [selctBatchStud, setSelctBatchStud] = useState([]);
  let [batchStdentData, setBatchStdentData] = useState([]);
  let [particularStudent, setParticularStudent] = useState({});

  let [online, setOnline] = useState();
  let [selected, setSelected] = useState(false);

  let [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [number1, SetNumber1] = useState(0);
  let [groupStudents, setGroupStudents] = useState("");

  const [open, setOpen] = React.useState(false);

  const [openPoll, setOpenPoll] = React.useState(false);
  const [areOptionsVisible, setAreOptionsVisible] = useState(false);
  const [msgseen, setMsgSeen] = useState("");
  let [messageCount, setMessageCount] = useState(0);
  let [lengthmsg, setLengthMsg] = useState(0);
  let [lengthStudentsGroup, setLengthStudentsGroup] = useState(0);
  const [stopscrollGroup, setStopScrollGroup] = useState(true);
  let [loadingGroupStudents, setLoadingGroupStudents] = useState(true);
  const [reply, setReply] = useState(false);
  const [forward, setForward] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [pastedImage, setPastedImage] = useState(null);
  const iconRef = useRef(null);
  const [isBackActive, setIsBackActive] = useState(false);
  const handleBackClick = () => {
    setIsBackActive(true);
  };
  const handleBackClickBack = () => {
    setIsBackActive(false);
  };
  const toggleOptions = () => {
    setAreOptionsVisible(!areOptionsVisible);
  };
  const [poll, setPoll] = useState([
    {
      question: "",
      options: [""],
      toggleValue: false,
      toggleValue1: false,
      selectedDate: null,
    },
  ]);
  const [errors, setErrors] = useState({});

  const handleClickOutside = event => {
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setAreOptionsVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOpen = () => {
    setOpen(true);
    setAreOptionsVisible(!areOptionsVisible);
  };
  const handleClose = () => {
    // setImagePreview(null);
    setOpen(false);
  };
  const handleOpenPoll = () => {
    setOpenPoll(true);
    setAreOptionsVisible(!areOptionsVisible);
  };
  const handleClosePoll = () => {
    setOpenPoll(false);
    setPoll([
      {
        question: "",
        options: [""],
        toggleValue: false,
        toggleValue1: false,
        selectedDate: null,
      },
    ]);
    setErrors({});
  };
  let [permenentBlock, setPermanentBlock] = useState(false);
  let { switchComp, setSwitchComp } = useContext(globalContext);

  let [batchBlock, setBatchBlock] = useState(false);
  let [data, setSData] = useState("");
  const [arrivalMessageFile, setArrivalMessageFile] = useState([]);

  let [searchgroup, setSearchGroup] = useState("");
  let [searchapiGroup, setSearchApiGroup] = useState("");
  let [clearGroup, setClearGroup] = useState(false);
  // //(socket)
  let [sortGroup, setSortGroup] = useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const [isPlaybackActive, setIsPlaybackActive] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [stop, setStop] = useState(false);
  const [isAudioVisible, setIsAudioVisible] = useState(false);
  let [msgScrollMsgGroup, setMsgScrollMsgGroup] = useState(0);
  const [stopscrollMsg, setStopScrollMsg] = useState(true);

  let HandleSearchGroup = e => {
    setSearchApiGroup(e);
  };
  let HandleClearGroup = () => {
    setSearchApiGroup("");

    setClearGroup(true);
    setSearchGroup("");
  };

  const handleDateChange = (index, newDate) => {
    const updatedPoll = [...poll];

    updatedPoll[index].selectedDate = newDate?.toISOString();
    setPoll(updatedPoll);
  };

  const handleToggleChange = pollIndex => {
    const updatedPoll = [...poll];
    updatedPoll[pollIndex].toggleValue = !updatedPoll[pollIndex].toggleValue;
    setPoll(updatedPoll);
  };

  const handleToggleChange1 = index => {
    const updatedPoll = [...poll];
    updatedPoll[index].toggleValue1 = !updatedPoll[index].toggleValue1;
    setPoll(updatedPoll);
  };
  const handleOptionChange = (index, ind, newOption) => {
    const updatedPoll = [...poll];
    updatedPoll[index].options[ind] = newOption;
    setPoll(updatedPoll);
  };

  const handleAddOption = pollIndex => {
    const updatedPoll = [...poll];
    updatedPoll[pollIndex].options = [...updatedPoll[pollIndex].options, ""];

    setPoll(updatedPoll);
  };

  const handleClearOption = (index, ind) => {
    const updatedPoll = [...poll];

    updatedPoll[index].options.splice(ind, 1);
    setPoll(updatedPoll);
  };
  const handleQuestionChange = (pollIndex, newQuestion) => {
    const updatedPoll = [...poll];
    updatedPoll[pollIndex].question = newQuestion;
    setPoll(updatedPoll);
  };

  const isFormValid = () => {
    const newErrors = {};

    for (let i = 0; i < poll?.length; i++) {
      if (!poll[i]?.question) {
        newErrors[`question_${i}`] = "Question is required";
      }

      const emptyOptions = poll[i].options.filter(option => !option.trim());

      if (emptyOptions.length > 0) {
        newErrors[`options_${i}`] = "All options must be filled";
      }
      if (poll[i]?.options?.length == 1) {
        newErrors[`options_${i}`] = "Add  atleast 2 options ";
      }
      if (!poll[i]?.selectedDate) {
        newErrors[`date_${i}`] = "Date is required";
      }
    }

    // Clear errors for fields that are now valid
    for (let i = 0; i < poll?.length; i++) {
      if (poll[i]?.question) {
        delete newErrors[`question_${i}`];
      }

      if (poll[i]?.selectedDate) {
        delete newErrors[`date_${i}`];
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const addDiv = () => {
    setPoll([
      ...poll,
      {
        question: "",
        options: [""],
        toggleValue: false,
        toggleValue1: false,
        selectedDate: null,
      },
    ]);
  };
  const deleteDiv = index => {
    const newQuestionAnswers = [...poll];
    newQuestionAnswers.splice(index, 1);
    setPoll(newQuestionAnswers);
  };

  const handleSubmit = () => {
    setErrors({});

    if (!isFormValid()) {
      const newErrors = {};

      for (let i = 0; i < poll?.length; i++) {
        if (!poll[i]?.question) {
          newErrors[`question_${i}`] = "Question is required";
        }
        if (poll[i]?.options?.some(option => !option)) {
          newErrors[`options_${i}`] = "Add options";
        }
        if (poll[i]?.options?.length === 1) {
          newErrors[`options_${i}`] = "Add  atleast 2 options ";
        }
        if (!poll[i]?.selectedDate) {
          newErrors[`date_${i}`] = "Date is required";
        }
      }

      setErrors(newErrors);
    } else {
      const currentDate = new Date();
      const currentDateTime = currentDate.toISOString();
      // //("From Submit");
      let payload = poll.map((ele, ind) => {
        return {
          pollcreaterid: user?._id,
          poll_question: ele?.question,
          options: ele?.options,
          startTime: currentDateTime,
          endTime: ele?.selectedDate,
          stu_view_vote: ele?.toggleValue1,
          vote_type: ele?.toggleValue,
        };
      });
      // //(payload);
      async function students() {
        await AxiosInstance.post(`/employee/users_pollcreate`, payload, {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        })
          .then(x => {
            console?.log(x);
            let data = x?.data;
            // //(data);
            let poll1 = data?.map((ele, ind) => {
              const date = new Date(ele?.endTime);

              // Extract date and time components
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to month because it's 0-based
              const day = String(date.getDate()).padStart(2, "0");
              const hours = String(date.getHours()).padStart(2, "0");
              const minutes = String(date.getMinutes()).padStart(2, "0");

              // Create the desired format
              const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
              let time = formattedDate;
              // //(time);
              return {
                _id: ele._id,
                poll_question: ele.poll_question,
                endTime: ele.endTime,
                stu_view_vote: ele.stu_view_vote,
                user_id: user._id,
                time: time,
              };
            });
            // //(poll1);
            let aa = poll1?.map(x => {
              return x;
            });
            let aaa = poll1.map(x => {
              return reply
                ? socket.emit(
                    "sendMessage",
                    {
                      files: [x],
                      senderId: user._id,
                      receiverId: particularStudent?.studentId,
                      role: user?.role1,
                      code: groupName,
                      type: "group",
                      device: "web",
                      replayback: {
                        message: selectedMessages[0]?.messages?.text
                          ? selectedMessages[0]?.messages?.text
                          : "",
                        file: selectedMessages[0]?.messages?.files
                          ? selectedMessages[0]?.messages?.files
                          : "",
                      },
                    },
                    function callback(msg) {
                      setLoading(false);
                      setReply(false);
                      setSelectedMessages([]);

                      msg.tick = false;
                      setArrivalMessage(msg);
                    }
                  )
                : new Promise((resolve, reject) => {
                    socket?.emit(
                      "sendMessage",
                      {
                        senderId: user._id,
                        receiverId: particularStudent?.studentId,
                        text: x.poll_question,
                        role: user?.role1,
                        code: groupName,
                        type: "batch",
                        device: "web",
                        poll: x,
                      },
                      function callback(msg) {
                        setLoading(false);
                        resolve(msg);
                        msg.tick = false;
                      }
                    );
                  });
            });
            Promise.all(aaa)
              .then(message => {
                // //(message, "promis");
                message.forEach(msg => {
                  setMessages(prevMessages => prevMessages.concat(msg));
                });
              })
              .catch(error => {
                console.error("Error:", error);
                // Handle any errors that may occur during the socket.emit calls
              });
            // //(aa);
          })
          .catch(x => {
            console?.log(x);
            handleClosePoll();
            if (x?.response?.status === 403) {
              handleError(navigate, socket);
            }
          })
          .finally(() => {
            handleClosePoll();
          });
      }
      students();
    }
  };

  // text Editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showEditor, setShowEditor] = useState(false);
  const handleEditorChange = state => {
    setEditorState(state);
  };

  const handleAddIconClick = () => {
    setShowEditor(!showEditor); // Toggle editor visibility
  };
  // text Editor modal
  const [openEditor, setOpenEditor] = React.useState(false);

  const handleOpenEditor = () => {
    const contentState = ContentState.createFromText(newMessage);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
    setOpenEditor(true);
    setShowEditor(!showEditor);
  };
  const handleCloseEditor = () => {
    setOpenEditor(false);
    setShowEditor(false);
    setEditorState(EditorState.createEmpty());
  };

  useEffect(() => {
    let audioStream;
    let recorder;

    const startRecording = async () => {
      try {
        audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        recorder = new MediaRecorder(audioStream);
        recorder.addEventListener("dataavailable", handleDataAvailable);
        recorder.start();
        setMediaRecorder(recorder);
        setIsRecording(true);
        // setRecordingStatus("Recording...");
      } catch (error) {
        alert("Error accessing microphone");
        // setStop(true)
        setIsRecording(false);

        console.error("Error accessing microphone:", error);
      }
    };

    const stopRecording = () => {
      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
        mediaRecorder.removeEventListener("dataavailable", handleDataAvailable);
        setIsRecording(false);
        // setRecordingStatus("Recording stopped.");
      }
      if (audioStream) {
        audioStream.getTracks().forEach(track => track.stop());
      }
    };

    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
      setRecordedChunks([]); // Clear recorded
    }

    return () => {
      stopRecording();
    };
  }, [isRecording]);

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleDataAvailable = event => {
    if (event.data.size > 0) {
      const audioData = event.data;

      setRecordedAudio(audioData);
      setRecordedChunks(prevChunks => [...prevChunks, event.data]);
    }
  };

  const handleStopRecording = () => {
    setStop(true);
    setIsRecording(false);

    if (recordedAudio) {
      // storeAudioLocally(recordedAudio);
    }
  };
  const handlePlayButtonClick = () => {
    setIsPlaying(true);
    setIsPlaybackActive(prevPlaybackState => !prevPlaybackState);
    // Clear recorded chunks when playback is triggered
    // setRecordedChunks([]);
  };

  useEffect(() => {
    if (recordedAudio) {
      storeAudioLocally(recordedAudio);
    }
  }, [recordedAudio]);
  // //("object");
  const getAudioURL = () => {
    if (recordedChunks.length === 0) {
      console.error("No audio recorded");
      return null;
    }
    const audioBlob = new Blob(recordedChunks, { type: "audio/webm" });
    //(audioBlob);

    return URL.createObjectURL(audioBlob);
  };

  const audioPlayer = isPlaybackActive ? (
    <audio
      controls
      autoPlay
      key={isPlaybackActive ? "audio-playback" : "audio-empty"}
    >
      <source src={getAudioURL()} type="audio/webm" />
      Your browser does not support the audio element.
    </audio>
  ) : null;

  const storeAudioLocally = audioBlob => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        localStorage.setItem("audioBlob", base64String);
        //("Audio stored locally");
      };
      reader.readAsDataURL(audioBlob);
    } catch (error) {
      console.error("Error storing audio locally:", error);
    }
  };

  let HandleAudio = async e => {
    e.preventDefault();
    setStop(false);
    setIsPlaying(false);
    const base64String = localStorage.getItem("audioBlob");
    const blobToArrayBuffer = blob => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    };

    const decodeAudioData = arrayBuffer => {
      return new Promise((resolve, reject) => {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        audioContext.decodeAudioData(arrayBuffer, resolve, reject);
      });
    };
    if (base64String) {
      const arrayBuffer = await blobToArrayBuffer(recordedAudio);
      const audioBuffer = await decodeAudioData(arrayBuffer);

      const wavArrayBuffer = await wavEncoder.encode({
        sampleRate: audioBuffer.sampleRate,
        channelData: [audioBuffer.getChannelData(0)], // Assuming mono audio
      });

      const wavBlob = new Blob([wavArrayBuffer], { type: "audio/wav" });
      const wavFile = new File(
        [wavBlob],
        `audio${Math.round(Math.random() * 100)}.wav`,
        {
          type: "audio/wav",
        }
      );

      if (wavFile !== "") {
        try {
          let payload = {
            wavFile,
            sender: user._id,
          };

          const options = {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
              "Content-Type": "multipart/form-data",
            },
          };
          const res = await AxiosInstance.post(
            "/chat/newmsgfiles",
            payload,
            options
          );
          let filesData = res.data.files;
          //("object");

          socket.emit(
            "sendMessage",
            {
              files: filesData,
              senderId: user._id,
              receiverId: particularStudent?.studentId,
              role: user?.role1,
              code: groupCode,
              type: groupCode ? "group" : "batch",
              device: "web",
              poll: null,
            },
            function callback(msg) {
              //(msg);
              msg.tick = false;
              setMessages([...messages, msg]);
              setIsPlaybackActive(false);
              setIsAudioVisible(false); // Hide the audio tag
              setRecordedChunks([]); // Clear the recorded audio chunks
              //("end");
              setIsPlaying(false);
            }
          );
          setIsAudioVisible(false); // Hide the audio tag
          setRecordedChunks([]); // Clear the recorded audio chunks
          //("asdasdasda");
          setIsPlaying(false);
        } catch (error) {
          if (error?.response?.status === 403) {
            handleError(navigate, socket);
          }
          //(error);
        }
      } else {
      }
      // return file;
    }
    return null;
  };
  let handleDeleteButtonClick = () => {
    localStorage.removeItem("audioBlob");
    setStop(false);
    setIsPlaying(false);
    setRecordedAudio(null);
    setIsPlaybackActive(false);
    setIsAudioVisible(false); // Hide the audio tag
    setRecordedChunks([]); // Clear the recorded audio chunks
    setIsPlaying(false);
  };

  useEffect(() => {
    if (socket) {
      socket.on("batchclose_open", data => {
        //(data);
        setBatchBlock(true);
        setSData(data.result.res);
      });
    }
  }, [socket]);
  useEffect(() => {
    socket.on("batchclose_open", data => {
      //(data);
      setBatchBlock(true);
      setSData(data.result.res);
    });
  }, []);
  if (batchBlock === true) {
    toast.error(data, { position: "top-right" });
    function greeting() {
      // window.location.reload()
      navigate("/user-dashboard");
    }
    setTimeout(greeting, 3000);
    setBatchBlock(false);
    setSData("");
  }

  useEffect(() => {
    socket.on("blocked", data => {
      //(data)
      setPermanentBlock(true);
    });
  }, []);
  if (permenentBlock === true) {
    toast.error("Contact Administrator", { position: "top-right" });
    function greeting() {
      socket.disconnect();
      navigate("/");
      // window.location.reload();
    }
    setTimeout(greeting, 3000);
  }
  socket.on("reconnect", attemptNumber => {
    //(`Reconnected after ${attemptNumber} attempts`);
  });

  useEffect(() => {
    async function fetchlist() {
      //(searchapiGroup);import RemoveHtmlTag from "../../functions/RemoveHtmlTag";
      setLoadingGroupStudents(true);
      AxiosInstance.get(
        `users/groups/changes/${encodeURIComponent(
          groupCode
        )}/?scroll=${number1}&search=${searchapiGroup}&sortby=${sortGroup}`,
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      )
        .then(data => {
          let payload = data?.data?.groupData;
          let groupStudentsNumber = data?.data?.studentsCount;
          //(groupStudentsNumber)
          let studentData = data?.data?.usersdata;
          setLengthStudentsGroup(studentData?.length);
          if (number1 === groupStudentsNumber) {
            setStopScrollGroup(false);
            // setBatchStdentData([]);
          } else if (
            searchapiGroup?.length > 0 &&
            searchapiGroup?.length !== ""
          ) {
            setBatchStdentData(studentData);
          } else if (number1 > 0) {
            let mmm = [...batchStdentData, ...studentData];
            let nnn = mmm.filter(x => {
              return x.username && x;
            });
            setBatchStdentData(nnn);
          } else if (number1 > 0 && sortGroup === true) {
            SetNumber1(0);
            setBatchStdentData(studentData);
          } else {
            setBatchStdentData(studentData);
          }
          //(studentData.length);
          let groupname = data?.data?.groupData?.groupCode;

          if (studentData.length > 0) {
            const result = studentData.some(
              obj =>
                studentData.length > 0 ||
                (obj?.lastmsg?.usersNotification > 0 &&
                  (!obj.hasOwnProperty("usersNotification") ||
                    typeof obj?.lastmsg?.usersNotification !== "number"))
            );

            if (result) {
              //("");
            } else {
              if (groupname) {
                socket.emit("batchNotification", {
                  userId: user?._id,
                  role: user?.role1,
                  groupCode: groupname,
                  device: "web",
                });
              }
            }
          }
          setGroupStudents(groupStudentsNumber);
          setGroupName(groupname);
          setSelctBatchStud(payload);
          // setBatchStdentData(studentData);
        })
        .catch(x => {
          if (x.response.status === 403) {
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("device");
            Cookies.remove("name", { path: "", domain: ".qspiders.com" });

            Cookies.remove("student", { path: "", domain: ".qspiders.com" });
            Cookies.remove("visitedHomePage", {
              path: "",
              domain: ".qspiders.com",
            });
            socket.disconnect();
            navigate("/");
          }
        })
        .finally(() => {
          setLoadingGroupStudents(false);
        });
    }
    fetchlist();
  }, [number1, switchComp == false, searchapiGroup, clearGroup, sortGroup]);

  useEffect(() => {
    socket.on("getUsers", users => {});

    socket.on("getMessage", data => {
      let abc = { ...data, poll: null };

      if (data.brodcastchat) {
        setArrivalMessage(abc);
      } else {
        setArrivalMessage(abc);
      }
    });
    socket.on("msgSeen", data1 => {});
  }, []);
  useEffect(() => {
    setMessages(prev => [...prev, arrivalMessage]);
  }, [particularStudent.studentId && arrivalMessage]);
  useEffect(() => {
    if (arrivalMessageFile.length > 0) {
      setMessages(prevMessages => prevMessages.concat(arrivalMessageFile));
      setArrivalMessageFile([]);
    }
  }, [particularStudent?.studentId && arrivalMessageFile]);

  useEffect(() => {
    const getconversations = async () => {
      try {
        if (user._id && particularStudent?.studentId !== undefined) {
          setStopScrollMsg(true);

          socket.emit(
            "isOnline",
            {
              senderId: user._id,
              role: user.role1,
              receiverId: particularStudent.studentId,
              device: "web",
            },
            function callback(msg) {
              //(msg);
              setOnline(msg);
            }
          );
          //(groupCode, "code");
          let device = Cookies.get("device", { domain: ".qspiders.com" });
          socket.emit("chatwith", {
            senderId: user._id,
            role: user.role1,
            receiverId: particularStudent.studentId,
            deviceId: device,
            code: groupCode,
            device: "web",
          });

          const res = await AxiosInstance.post(
            `/chat/msg/new/allmsg?scroll=${msgScrollMsgGroup}`,
            {
              senderId: user?._id,
              receiverId: particularStudent?.studentId,
              userId: user?._id,
              studentId: particularStudent?.studentId,
              code: groupCode,
            },
            {
              headers: {
                Authorization: `Bearer ${user?.TOKEN}`,
              },
            }
          );
          res?.data?.msgs?.reverse();
          setMessageCount(res?.data?.msgscount);
          setLengthMsg(res?.data?.msgs?.length);
          if (res?.data?.msgs?.length > 0 && msgScrollMsgGroup > 0) {
            let mess = res?.data?.msgs;
            setMessages([...mess, ...messages]);
            setLoading(false);
            // setMessageRead(res?.data?.notification);
          } else if (res?.data?.msgs?.length !== 0 && msgScrollMsgGroup <= 0) {
            socket.on("msgSeen", data1 => {
              setMsgSeen(data1);
              let a = res.data.msgs.map(x => {
                return { ...x, tick: true };
              });
            });
            // if (res?.data?.msgs) {
            for (let index = 0; index < res.data.msgs.length; index++) {
              const element = res.data.msgs[index];
              if (!element.hasOwnProperty("poll")) {
                element.poll = null; // or set it to an initial value as needed
              }
            }
            // }
            for (
              let index = 1;
              index <= res?.data?.notification?.studentNotification;
              index++
            ) {
              const element =
                index > res?.data?.msgs?.length
                  ? res?.data?.msgs[res?.data?.msgs?.length - 1]
                  : res?.data?.msgs[res?.data?.msgs?.length - index];
              element.tick = false;
            }
            setMessages(res?.data?.msgs);
            setLoading(false);
          } else if (res?.data?.msgs?.length <= 0) {
            setStopScrollMsg(false);
            setLoading(false);
            setMessages(res?.data?.msgs);
          }
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          sessionStorage.removeItem("device");
          Cookies.remove("name", { path: "", domain: ".qspiders.com" });

          navigate("/");
          Cookies.remove("student", { path: "", domain: ".qspiders.com" });
          Cookies.remove("visitedHomePage", {
            path: "",
            domain: ".qspiders.com",
          });
          socket.disconnect();
        }
      }
    };
    getconversations();
  }, [particularStudent.studentId, msgseen, msgScrollMsgGroup]);

  // scroll for msg
  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom(); // Call the function when messages change
  }, [messages]);
  // const handlePaste = e => {
  //   e.preventDefault(); // Prevent the default paste behavior

  //   const clipboardData = e.clipboardData || window.clipboardData;
  //   const pastedText = clipboardData.getData("text"); // Get the text from the clipboard
  //   if (pastedText) {
  //     setNewMessage(pastedText);
  //   }

  //   // Handle image paste
  //   const items = clipboardData.items;
  //   for (let i = 0; i < items.length; i++) {
  //     const item = items[i];
  //     if (item.type.indexOf("image") !== -1) {
  //       setOpen(true);
  //       const file = item.getAsFile();
  //       const imageUrl = URL.createObjectURL(file);

  //       setPastedImage(imageUrl); // Store pasted image
  //       break;
  //     }
  //   }
  // };

  const handlePaste = e => {
    e.preventDefault(); // Prevent the default paste behavior

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text"); // Get the text from the clipboard

    if (pastedText) {
      // Append the new pasted text to the existing message
      setNewMessage(prevMessage => prevMessage + pastedText);
    }

    // Handle image paste
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf("image") !== -1) {
        setOpen(true);
        const file = item.getAsFile();
        const imageUrl = URL.createObjectURL(file);

        setPastedImage(imageUrl); // Store pasted image
        break;
      }
    }
  };
  let handelSubmit = async e => {
    e?.preventDefault();
    if (newMessage.trim() !== "") {
      setLoading(true);
      {
        if (reply) {
          const messageText = selectedMessages[0]?.messages?.text;
          const htmlContent = selectedMessages[0]?.html === true;
          const replyBack = {
            message: messageText
              ? htmlContent
                ? await RemoveHtmlTag(messageText)
                : messageText
              : "",
            file: selectedMessages[0]?.messages?.files || "",
          };
          socket?.emit(
            "sendMessage",
            {
              senderId: user._id,
              receiverId: particularStudent.studentId,
              text: newMessage,
              role: user?.role1,
              code: groupName,
              type: "group",
              device: "web",
              poll: null,
              // replayback: {
              //   message: selectedMessages[0]?.messages?.text
              //     ? selectedMessages[0]?.messages?.text
              //     : "",
              //   file: selectedMessages[0]?.messages?.files
              //     ? selectedMessages[0]?.messages?.files
              //     : "",
              // },
              replayback: replyBack,
            },
            function callback(msg) {
              setReply(false);
              setSelectedMessages([]);

              if (msg.read === false) {
                msg.tick = false;
                setMessages([...messages, msg]);
                setLoading(false);
              } else {
                setMessages([...messages, msg]);
                setLoading(false);
              }
            }
          );
        } else {
          socket?.emit(
            "sendMessage",
            {
              senderId: user._id,
              receiverId: particularStudent.studentId,
              text: newMessage,
              role: user?.role1,
              code: groupName,
              type: "group",
              device: "web",
              poll: null,
            },
            function callback(msg) {
              if (msg.read === false) {
                msg.tick = false;
                setMessages([...messages, msg]);
                setLoading(false);
              } else {
                setMessages([...messages, msg]);
                setLoading(false);
              }
            }
          );
        }
      }
    } else {
      //("type message");
    }

    try {
      setNewMessage("");
    } catch (error) {
      //(error);
    }
  };

  let handleEditorSend = e => {
    const contentState = editorState.getCurrentContent();
    // Check if the content is empty
    const isEmpty =
      !contentState.hasText() || contentState.getPlainText().trim() === "";
    if (!isEmpty) {
      const contentState = editorState.getCurrentContent();

      const rawContentState = convertToRaw(contentState);

      let content = draftToHtml(rawContentState);
      setNewMessage(content);
      socket?.emit(
        "sendMessage",
        {
          senderId: user._id,
          receiverId: particularStudent.studentId,
          text: content,
          html: true,
          role: user?.role1,
          code: groupName,
          type: "group",
          device: "web",
          poll: null,
        },
        function callback(msg) {
          if (msg.read === false) {
            msg.tick = false;
            setMessages([...messages, msg]);
            setLoading(false);
          } else {
            setMessages([...messages, msg]);
            setLoading(false);
          }
        }
      );
      setEditorState(EditorState.createEmpty());
      setOpenEditor(false);
      setShowEditor(false);
    }
    setNewMessage("");
  };

  let handelSubmitButton = e => {
    handelSubmit();
  };
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      handelSubmit();
    }
  };
  let forwardmsg = [];

  let selectedmsg = data => {
    let aaa = forwardmsg?.map(x => x.index);
    const index = data.index;
    if (aaa.includes(index)) {
      for (let i = 0; i < forwardmsg.length; i++) {
        if (forwardmsg[i].index === index) {
          forwardmsg.splice(i, 1);
          i--; // decrement i to account for removed element
        }
      }
    } else {
      if (forwardmsg.length == 5 || forwardmsg.length > 5) {
        alert("you can only send 5 messages at a time");
      } else {
        forwardmsg.push(data);
      }
    }
  };
  const disablePastDate = date => {
    const today = new Date();
    // Set the time to midnight for comparison
    today.setHours(0, 0, 0, 0);

    return date < today;
  };
  let HandleLoadMessages = () => {
    setMsgScrollMsgGroup(msgScrollMsgGroup + lengthmsg);
  };
  let CancelReply = () => {
    setReply(false);
    setForward(false);
    setSelectedMessages([]);
  };
  let CancelForward = () => {
    setForward(false);
    setSelectedMessages([]);
  };

  const handleMessageClick = (clickedMessage, index, isMessageSelected) => {
    const existingIndex = selectedMessages.findIndex(
      msg =>
        (msg.messages.text === clickedMessage.messages.text &&
          msg.index === clickedMessage.index) ||
        (msg.messages.flies === clickedMessage?.messages?.flies &&
          msg.index === clickedMessage.index)
    );

    const isMessageAlreadySelected = existingIndex !== -1;
    const hasReachedMaxSelection = selectedMessages.length === 5;

    if (!isMessageAlreadySelected && selectedMessages.length < 5) {
      setSelectedMessages([...selectedMessages, clickedMessage]);
    } else if (isMessageAlreadySelected) {
      const updatedMessages = selectedMessages.filter(
        (_, index) => index !== existingIndex
      );
      setSelectedMessages(updatedMessages);
    } else if (selectedMessages.length === 5) {
      // Alert message when the selection limit is reached or duplicate is clicked
      alert(`You can select only 5 unique messages.`);
    }
  };
  return (
    <>
      {/* <Modal
        backdrop="static"
        // keyboard={false}
        role="alertdialog"
        open={openEditor}
        onClose={handleCloseEditor}
      >
        <div className={Styles.HeaderPoll}>
          <span onClick={handleCloseEditor}>
            <ImCross />
          </span>
          <h4>Text Editor</h4>
        </div>

        <Modal.Body>
          <Editor
            editorState={editorState}
            wrapperClassName="chatEditorWrapper"
            editorClassName="chatEditor"
            onEditorStateChange={handleEditorChange}
            placeholder="Write something..."
            toolbar={{
              options: [
                "inline",
                // "blockType",
                // "fontSize",
                // "list",
                // "textAlign",
                "colorPicker",
                // "link",
                // "embedded",
                "emoji",
                // "image",
                // "remove",
                "history",
              ],
              inline: {
                options: ["bold", "italic", "underline", "strikethrough"],
              },
              colorPicker: {
                colors: [
                  "#000000",
                  "#ffffff",
                  "#ff0000",
                  "#00ff00",
                  "#0000ff",
                  "#FFFF00",
                ],
              },
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          {" "}
          <div
            style={{ display: "flex", gap: "1%", justifyContent: "flex-end" }}
          >
            <Button
              type="button"
              variant="contained"
              color="error"
              onClick={handleCloseEditor}
            >
              cancel
            </Button>
            <Button
              type="button"
              variant="contained"
              color="warning"
              onClick={handleEditorSend}
            >
              Send
            </Button>
            <button
              type="submit"
              style={{ display: "none" }}
              className="chatSubmitButton"
              onClick={handleEditorSend}
            >
              Send
            </button>
          </div>
        </Modal.Footer>
      </Modal> */}
      <ModalMui open={openEditor}>
        <Box sx={styleModal1} position="relative">
          <h4 style={{ textAlign: "center", color: "#f16f1d" }}>Text Editor</h4>
          <Divider />
          <br />
          <button
            style={{
              border: "none",
              background: "none",
              position: "absolute",
              right: "5px",
              top: "5px",
            }}
            onClick={handleCloseEditor}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <Box id="modal-description" sx={{ mt: 2 }}>
            <Editor
              editorState={editorState}
              wrapperClassName="chatEditorWrapper"
              editorClassName="chatEditor"
              onEditorStateChange={handleEditorChange}
              placeholder="Write something..."
              toolbar={{
                options: ["inline", "colorPicker", "emoji", "history"],
                inline: {
                  options: ["bold", "italic", "underline", "strikethrough"],
                },
                colorPicker: {
                  colors: [
                    "#000000",
                    "#ffffff",
                    "#ff0000",
                    "#00ff00",
                    "#0000ff",
                    "#FFFF00",
                  ],
                },
              }}
            />
          </Box>

          <Box
            sx={{ display: "flex", gap: 1, justifyContent: "flex-end", mt: 2 }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseEditor}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={handleEditorSend}
            >
              Send
            </Button>
          </Box>
        </Box>
      </ModalMui>
      <section id={Styles.chatBoxSection}>
        <article
          className={`${Styles.parent} ${
            isBackActive ? Styles.backActive : ""
          }`}
          style={{ position: "relative" }}
        >
          {isBackActive && (
            <div className={Styles.BackBtn} onClick={handleBackClickBack}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
              >
                <g fill="none">
                  <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                  <path
                    fill="#555"
                    d="M3.283 10.94a1.5 1.5 0 0 0 0 2.12l5.656 5.658a1.5 1.5 0 1 0 2.122-2.122L7.965 13.5H19.5a1.5 1.5 0 0 0 0-3H7.965l3.096-3.096a1.5 1.5 0 1 0-2.122-2.121z"
                  />
                </g>
              </svg>
            </div>
          )}
          <aside className={Styles.chatMenu}>
            <Conversation
              batchStdent={batchStdentData}
              setParticularStudent={setParticularStudent}
              trainername={selctBatchStud}
              // setIsOpen={setIsOpen}
              groupName={groupName}
              messages={messages}
              SetNumber1={SetNumber1}
              number1={number1}
              groupCode={groupCode}
              setBatchStdentData={setBatchStdentData}
              groupStudents={groupStudents}
              particularStudent={particularStudent}
              setStop={setStop}
              setIsPlaying={setIsPlaying}
              setRecordedAudio={setRecordedAudio}
              setIsPlaybackActive={setIsPlaybackActive}
              setIsAudioVisible={setIsAudioVisible}
              setRecordedChunks={setRecordedChunks}
              HandleSearchGroup={HandleSearchGroup}
              HandleClearGroup={HandleClearGroup}
              searchgroup={searchgroup}
              setSearchGroup={setSearchGroup}
              setIsRecording={setIsRecording}
              sortGroup={sortGroup}
              setSortGroup={setSortGroup}
              setMsgScrollMsgGroup={setMsgScrollMsgGroup}
              setMessages={setMessages}
              lengthStudentsGroup={lengthStudentsGroup}
              loadingGroupStudents={loadingGroupStudents}
              setSelectedMessages={setSelectedMessages}
              setReply={setReply}
              setForward={setForward}
              handleBackClick={handleBackClick}
            />
          </aside>
          <aside
            className={Styles.chatBox}
            style={{
              backgroundImage: `url('https://img.freepik.com/free-vector/white-monochrome-background_23-2148999837.jpg?size=626&ext=jpg&ga=GA1.1.1318602343.1709182378&semt=ais')`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              border: "0.3px ridge #999",
            }}
          >
            <div className={Styles.chatBoxWrapper}>
              {particularStudent.studentId ? (
                <>
                  <div className={Styles.chatBoxTop}>
                    <UserNameData name={particularStudent} online={online} />
                    <div
                      className={Styles.chatBoxStore}
                      id="scrollableDiv"
                      ref={scrollRef}
                      style={{
                        height: "78vh",
                        overflow: "auto",
                      }}
                    >
                      {messageCount != msgScrollMsgGroup + lengthmsg && (
                        <div className={Styles.SpinnerBlock}>
                          {" "}
                          <button
                            onClick={HandleLoadMessages}
                            className={Styles.Spinner}
                          >
                            <ImSpinner11 />
                          </button>
                        </div>
                      )}
                      {messages.length > 0 ? (
                        messages.map((m, ind) => (
                          <Fragment key={ind}>
                            <Message
                              particularStudent={particularStudent}
                              message={m}
                              own={m?.sender === user?._id}
                              index={ind}
                              selectedmsg={selectedmsg}
                              setSelected={setSelected}
                              selected={selected}
                              setReply={setReply}
                              reply={reply}
                              setForward={setForward}
                              forward={forward}
                              onClick={handleMessageClick}
                              selectedMessages={selectedMessages}
                              setSelectedMessages={setSelectedMessages}
                            />
                          </Fragment>
                        ))
                      ) : (
                        <span className={Styles.noConversation}>
                          <p style={{ color: "#f16f1d" }}>No Messages</p>
                        </span>
                      )}
                    </div>
                  </div>

                  {!forward ? (
                    <form onSubmit={handelSubmit} className={Styles.FormBlock}>
                      {reply && (
                        <div className={Styles.replyBlock}>
                          <div className={Styles.StatusText1}>
                            {" "}
                            {selectedMessages && (
                              <>
                                {/* <div>Reply</div> */}
                                <div className={Styles.copiedText}>
                                  {selectedMessages[0]?.messages?.text
                                    ? selectedMessages[0]?.messages?.text?.slice(
                                        0,
                                        180
                                      )
                                    : selectedMessages[0]?.messages?.files[0]
                                        ?.filename}
                                </div>
                                <div
                                  className={Styles.CrossMark}
                                  onClick={() => CancelReply()}
                                >
                                  X
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}

                      <div className={Styles.chatBoxBottom}>
                        {/* {!showEditor ? ( */}
                        <input
                          name="message"
                          id="message"
                          required
                          autoFocus
                          className="chatMessageInput"
                          placeholder="write something..."
                          onChange={e => {
                            setNewMessage(e.target.value);
                          }}
                          value={newMessage}
                          onKeyPress={handleKeyPress}
                          onPaste={handlePaste}
                        />
                        <aside
                          style={{
                            position: "relative",
                            margin: "1%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          // className={Styles.UploadfileContainer}
                        >
                          <div onClick={handleOpenEditor}>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 32 32"
                              >
                                <path
                                  fill="#475569"
                                  d="M9.5 2c.502 0 .956.3 1.152.763l5.072 11.989l-1.908 1.907L12.48 13.5H6.52l-1.37 3.237a1.25 1.25 0 0 1-2.302-.974l5.5-13A1.25 1.25 0 0 1 9.5 2m1.922 9L9.5 6.458L7.58 11zm9.838.328a4.536 4.536 0 0 1 6.414 6.415l-9.418 9.417a4 4 0 0 1-1.73 1.018l-6.253 1.787a1 1 0 0 1-1.236-1.236l1.785-6.251a4 4 0 0 1 1.018-1.73z"
                                ></path>
                              </svg>
                            </span>
                          </div>
                        </aside>
                        <aside
                          style={{ position: "relative" }}
                          className={Styles.UploadfileContainer}
                        >
                          <>
                            {isRecording ? (
                              ""
                            ) : stop ? (
                              ""
                            ) : (
                              <>
                                <ButtonToolbar ref={iconRef}>
                                  <FaPlus onClick={toggleOptions} />
                                </ButtonToolbar>
                                {areOptionsVisible && (
                                  <div className={Styles.ropdowncontent}>
                                    {/* Add your dropdown content here */}
                                    <ul>
                                      <li
                                        className={Styles.FileShare}
                                        onClick={handleOpen}
                                      >
                                        <ImAttachment></ImAttachment>
                                        files
                                      </li>
                                      <li
                                        className={Styles.FileShare}
                                        onClick={handleOpenPoll}
                                      >
                                        <BsFillBarChartFill></BsFillBarChartFill>{" "}
                                        Poll
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                <Modal
                                  backdrop="static"
                                  // keyboard={false}
                                  role="alertdialog"
                                  open={openPoll}
                                  onClose={handleClosePoll}
                                >
                                  <div className={Styles.HeaderPoll}>
                                    <span onClick={handleClosePoll}>
                                      <ImCross />
                                    </span>
                                    <h4>Create Poll</h4>
                                  </div>

                                  <Modal.Body>
                                    {poll?.map((qa, index) => (
                                      <div
                                        className={Styles.modalcontent}
                                        key={index}
                                      >
                                        <div>
                                          <div className={Styles.QuestionBlock}>
                                            <h5>Question:</h5>

                                            <span
                                              className={Styles.deleteicon}
                                              onClick={() => deleteDiv(index)}
                                            >
                                              <FaMinus />
                                            </span>
                                          </div>
                                          <input
                                            className={Styles.InputQue}
                                            type="text"
                                            id="question"
                                            placeholder="Ask question"
                                            value={qa?.question}
                                            onChange={e => {
                                              handleQuestionChange(
                                                index,
                                                e.target.value
                                              );
                                              const updatedErrors = {
                                                ...errors,
                                              };
                                              delete updatedErrors[
                                                `question_${index}`
                                              ];
                                              setErrors(updatedErrors);
                                            }}
                                          />
                                          {errors[`question_${index}`] && (
                                            <p className={Styles.errormessage}>
                                              {errors[`question_${index}`]}
                                            </p>
                                          )}
                                        </div>
                                        <h5>Options:</h5>
                                        {qa?.options.map((option, ind) => (
                                          <div
                                            key={ind}
                                            className={Styles.OptionBlock}
                                          >
                                            <div className={Styles.InputOption}>
                                              {" "}
                                              <input
                                                className={Styles.InputQue}
                                                type="text"
                                                placeholder="Add"
                                                value={option}
                                                onChange={e => {
                                                  handleOptionChange(
                                                    index,
                                                    ind,
                                                    e.target.value
                                                  );
                                                  const updatedErrors = {
                                                    ...errors,
                                                  };
                                                  delete updatedErrors[
                                                    `options_${index}`
                                                  ];
                                                  setErrors(updatedErrors);
                                                  // Add validation for empty options
                                                }}
                                              />
                                              <button
                                                className={Styles.ClearOption}
                                                onClick={() =>
                                                  handleClearOption(index, ind)
                                                }
                                              >
                                                <ImCross />
                                              </button>
                                            </div>
                                            {errors[`options_${index}`] && (
                                              <p
                                                className={Styles.errormessage}
                                              >
                                                {errors[`options_${index}`]}
                                              </p>
                                            )}
                                          </div>
                                        ))}
                                        <button
                                          onClick={() => handleAddOption(index)}
                                          className={Styles.AddOptions}
                                        >
                                          <FaPlus />
                                        </button>
                                        <div className={Styles.datePicker}>
                                          {" "}
                                          <DatePicker
                                            format="yyyy-MM-dd HH:mm"
                                            calendarDefaultDate={new Date()}
                                            disabledDate={disablePastDate}
                                            onChange={date => {
                                              handleDateChange(index, date);
                                              const updatedErrors = {
                                                ...errors,
                                              };
                                              delete updatedErrors[
                                                `date_${index}`
                                              ];
                                              setErrors(updatedErrors);
                                            }}
                                            ranges={[
                                              {
                                                label: "Now",
                                                value: new Date(),
                                              },
                                            ]}
                                            style={{ width: 260 }}
                                          />
                                          {errors[`date_${index}`] && (
                                            <p className={Styles.errormessage}>
                                              {errors[`date_${index}`]}
                                            </p>
                                          )}
                                        </div>
                                        <div className={Styles.Allow}>
                                          <h6>Allow multiple answers</h6>{" "}
                                          <span>
                                            {" "}
                                            <Toggle
                                              checked={qa?.toggleValue}
                                              onChange={() =>
                                                handleToggleChange(index)
                                              }
                                            />
                                          </span>
                                        </div>
                                        <div className={Styles.Allow}>
                                          <h6>Allow to View votes</h6>{" "}
                                          <span>
                                            {" "}
                                            <Toggle
                                              checked={qa?.toggleValue1}
                                              onChange={() =>
                                                handleToggleChange1(index)
                                              }
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                    <button
                                      onClick={addDiv}
                                      className={Styles.AddPoll}
                                    >
                                      <BiPlusMedical />
                                    </button>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    {" "}
                                    <button
                                      onClick={handleSubmit}
                                      className={Styles.SubmitPoll}
                                    >
                                      <IoSendSharp />
                                    </button>
                                  </Modal.Footer>
                                </Modal>
                              </>
                            )}

                            <Modal
                              keyboard={false}
                              open={open}
                              onClose={handleClose}
                            >
                              <Modal.Header>
                                <Modal.Title>Send File</Modal.Title>
                              </Modal.Header>

                              <Modal.Body>
                                <FileUpload
                                  PStudent={particularStudent}
                                  setMessages={setMessages}
                                  messages={messages}
                                  setOpen={setOpen}
                                  groupName={groupName}
                                  setArrivalMessage={setArrivalMessage}
                                  setArrivalMessageFile={setArrivalMessageFile}
                                  reply={reply}
                                  selectedMessages={selectedMessages}
                                  setReply={setReply}
                                  setSelectedMessages={setSelectedMessages}
                                  pastedImage={pastedImage}
                                  setPastedImage={setPastedImage}
                                />
                              </Modal.Body>
                              <Modal.Footer></Modal.Footer>
                            </Modal>
                          </>
                        </aside>
                        <aside className={Styles.AudioBlock}>
                          <div>
                            {!stop && !isRecording && !isPlaying && (
                              <Whisper
                                followCursor
                                speaker={<Tooltip>{"Start"}</Tooltip>}
                              >
                                <main
                                  className={Styles.StartAudio}
                                  onClick={handleStartRecording}
                                  disabled={isRecording}
                                >
                                  <span>
                                    {/* start */}
                                    <BsFillMicFill />
                                  </span>
                                </main>
                              </Whisper>
                            )}
                            <div
                              className={`${Styles.recordingAnimation} ${
                                isRecording ? Styles.active : ""
                              }`}
                            ></div>
                            {isRecording && (
                              <Whisper
                                followCursor
                                speaker={<Tooltip>{"Stop"}</Tooltip>}
                              >
                                <main
                                  className={Styles.StopAudio}
                                  onClick={handleStopRecording}
                                  disabled={!isRecording}
                                >
                                  <span>
                                    {/* stop */}
                                    <BsFillMicMuteFill />
                                  </span>
                                </main>
                              </Whisper>
                            )}
                            {stop && (
                              <Whisper
                                followCursor
                                speaker={<Tooltip>{"Play"}</Tooltip>}
                              >
                                <main
                                  className={Styles.PlayAudio}
                                  onClick={handlePlayButtonClick}
                                  disabled={isRecording}
                                >
                                  <span>
                                    {/* play */}
                                    <BsFillPlayCircleFill />
                                  </span>
                                </main>
                              </Whisper>
                            )}
                            {/* {stop &&<p>{recordingStatus}</p>} */}
                            {audioPlayer}
                          </div>
                        </aside>
                        {stop && (
                          <div className={Styles.SendAudioBlock}>
                            <Whisper
                              followCursor
                              speaker={<Tooltip>{"Delete"}</Tooltip>}
                            >
                              <span
                                onClick={handleDeleteButtonClick}
                                className={Styles.DeleteAudio}
                              >
                                <AiFillDelete />
                              </span>
                            </Whisper>
                            <span
                              onClick={HandleAudio}
                              className={Styles.SendAudio}
                            >
                              send
                              {/* <AiOutlineSend /> */}
                            </span>
                          </div>
                        )}
                        {!stop && (
                          <div>
                            {loading || isRecording ? (
                              <button
                                className="chatSubmitButton"
                                disabled
                                onClick={handelSubmitButton}
                              >
                                <div className={Styles.loader}>
                                  <RotatingLines
                                    strokeColor="#f16f1d"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="20"
                                    visible={true}
                                  />
                                </div>
                              </button>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  className="chatSubmitButton"
                                  onClick={handelSubmitButton}
                                >
                                  Send
                                </button>
                                <button
                                  type="submit"
                                  style={{ display: "none" }}
                                  className="chatSubmitButton"
                                  onClick={handelSubmitButton}
                                >
                                  Send
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </form>
                  ) : (
                    <div className={Styles.ForwardPanel}>
                      {" "}
                      <>
                        <div>
                          {" "}
                          <div>Forward Message</div>
                          {/* <div>
                            <IoArrowRedoSharp />
                          </div> */}
                        </div>
                        <div
                          className={Styles.CrossMarkCancel}
                          onClick={() => CancelForward()}
                        >
                          X
                        </div>
                      </>
                    </div>
                  )}
                </>
              ) : (
                <span className={Styles.noConversationText}>
                  <p style={{ color: "#f16f1d" }}>
                    Open a conversation to start a chat.
                  </p>
                </span>
              )}
            </div>
          </aside>
        </article>
      </section>
    </>
  );
};

export default UserGroupComp;
