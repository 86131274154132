import React, { useContext, useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { globalContext } from "../../Users/UsersContext";
import Styles from "./_chat.module.css";
import { useSelector } from "react-redux";
import { SiGoogleclassroom } from "react-icons/si";
import Select from "react-select";
const Conversation = ({
  batchStdent,
  setParticularStudent,
  allUsers,
  batchname,
  setBatchTrainerData,
}) => {
  let { userAdmin } = useSelector(state => state?.auth);
  let [search, setSearch] = useState("");
  let [usernames, setUserNames] = useState([]);

  let { switchComp, setSwitchComp } = useContext(globalContext);

  useEffect(() => {
    let aaa = allUsers?.map(function (ele) {
      return { value: ele?._id, label: ele?.username + "-" + ele?.role };
    });

    setUserNames(aaa);
  }, [userAdmin._id, batchname, allUsers]);


  let onChangeselect = e => {
    if (e?.value) {
      setBatchTrainerData(e?.value);
      setParticularStudent(e?.value);
    } else {
    }
  };

  return (
    <>
      <div className={Styles.Head}>
        <div className={Styles.drop}>
          <Select
            // placeholder={trainername}
            escapeClearsValue={true}
            labelKey="name"
            className={Styles.option}
            options={usernames}
            onChange={onChangeselect}
          />
        </div>
        {/* <h1>Trainer:{trainername?.trainer}</h1> */}
        {switchComp ? (
          ""
        ) : (
          <button
            onClick={() => setSwitchComp(true)}
            className={Styles.Broadcast}
          >
            <span>
              <SiGoogleclassroom></SiGoogleclassroom>
            </span>{" "}
            studentlist
          </button>
        )}
      </div>
      <div>
        <div className={Styles.chatMenuWrapper}>
          <span>
            <input
              type="text"
              name="search"
              id="search"
              placeholder="search student"
              onChange={e => setSearch(e.target.value)}
            />
          </span>
          <span className={Styles.searchIcon}>
            <FiSearch />
          </span>
          <div className={Styles.ConversationBlock}>
            <>
              {batchStdent.length > 0 &&
                batchStdent
                  ?.filter(students =>
                    students?.username?.toLowerCase()?.includes(search)
                  )
                  .map((students, ind) => {
                    return (
                      <div className={Styles.listUser} key={ind}>
                        <div
                          className={Styles.conversation}
                          // onClick={() => {
                          //   setParticularStudent({
                          //     studentId: students._id,
                          //     studentName: students.username,
                          //   });
                          // }}
                        >
                          <span>{students.username}</span>
                        </div>
                      </div>
                    );
                  })}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default Conversation;
