import React, { useEffect, useRef, useState } from "react";
import styles from "../../Branding/brand.module.css";
import Styles from "../../Student/_UserToUser.module.css";
import useDownloader from "react-use-downloader";
import { FaSearchMinus, FaSearchPlus } from "react-icons/fa";
import { Button, Modal } from "rsuite";
import AxiosInstance from "../../../api/AxiosInstance";
import Moment from "react-moment";
import { Box, Grid, Modal as MUIModal } from "@mui/material";
import fetchImageUrl from "../../functions/FetchFileWithToken";
import { useSelector } from "react-redux";
import { IoMdEye } from "react-icons/io";

const styleModal1 = {
  position: "absolute",
  top: "50%",
  left: "58%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  minWidth: "30%",
  minHeight: "250px",
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
  maxHeight: "95vh",
};

const ViewuserChat = ({ contact, selectedBranch }) => {
  let { userAdmin } = useSelector(state => state?.auth);
  const { download } = useDownloader();
  const imgRef = useRef(null);
  let scrollRef = useRef();

  const [zoomLevel, setZoomLevel] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseLeave = () => {
    setDragging(false);
  };

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };
  const handleMouseDown = e => {
    if (zoomLevel > 1) {
      setDragging(true);
      setStartX(e.pageX - offsetX);
      setStartY(e.pageY - offsetY);
    }
  };

  const handleMouseMove = e => {
    if (dragging) {
      e.preventDefault();
      const x = e.pageX - startX;
      const y = e.pageY - startY;
      setOffsetX(x);
      setOffsetY(y);
    }
  };

  const [messages, setMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewed, setViewed] = useState([]);
  // modal
  const [openView, setOpenView] = React.useState(false);
  const handleOpenView = () => setOpenView(true);
  const handleCloseView = () => {
    setOpenView(false);
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  let fetchViewChat = async e => {
    try {
      const { data } = await AxiosInstance.get(
        `/chat/getView?id=${e}&role=${contact}&branch=${selectedBranch.value}`,
        {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }
      );

      setViewed(data.view);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const [openImg, setOpenImg] = React.useState(false);
  const [previewImage, setPreviewImage] = useState("");

  let handleImage = e => {
    setOpenImg(true);
    openImagePreview(e);
  };

  const openImagePreview = imageSrc => {
    setPreviewImage(imageSrc);
  };

  useEffect(() => {
    if (contact.length > 0) {
      const fetchMessage = async () => {
        setLoading(true);

        const filter =
          selectedBranch === null ||
          selectedBranch === "" ||
          selectedBranch === undefined
            ? ""
            : `&branch=${selectedBranch.value}`;
        try {
          const { data } = await AxiosInstance.get(
            `/chat/getbroadcastmessage?role=${contact}${filter}`,
            {
              headers: {
                Authorization: `Bearer ${userAdmin.TOKEN}`,
              },
            }
          );
          setLoading(false);

          const reversedMsg = data.broadcastmessage.reverse();

          setMessage(reversedMsg);
        } catch (error) {
          console.error(error);
          if (error?.response?.data?.message === "message not found") {
            setLoading(false);
          } else {
            setLoading(null);
          }
        }
      };
      fetchMessage();
    }
  }, [contact, selectedBranch]);

  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    const fetchUrls = async () => {
      const newFileUrls = {};

      await Promise.all(
        messages.map(async message => {
          if (message?.message.file) {
            const fileData = await fetchImageUrl(
              message?.message?.file?.path,
              userAdmin?.TOKEN
            );
            if (fileData) {
              newFileUrls[message?.message?.file?.path] = fileData;
            }
          }
        })
      );

      setImageUrls(prevUrls => ({ ...prevUrls, ...newFileUrls }));
    };

    fetchUrls();
  }, [messages]);
  // play video part
  const [videoURL, setVideoURL] = useState(null);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const handleOpenVideoModal = url => {
    setVideoURL(url);
    setOpenVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setVideoURL(null);
    setOpenVideoModal(false);
  };

  return (
    <div
      className={styles.chatContainer}
      style={{ height: "85vh" }}
      ref={scrollRef}
      id="scrollableDiv"
    >
      {/* modal for video play */}
      <MUIModal open={openVideoModal} onClose={handleCloseVideoModal}>
        <Box sx={styleModal1} position="relative">
          <button
            style={{
              border: "none",
              background: "none",
              position: "absolute",
              right: "5px",
              top: "5px",
              zIndex: "999",
            }}
            onClick={handleCloseVideoModal}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {videoURL && (
                <video
                  src={videoURL}
                  controls
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "5px",
                  }}
                ></video>
              )}
            </Grid>
          </Grid>
        </Box>
      </MUIModal>

      <Modal onClose={() => setOpenImg(false)} open={openImg} size="full">
        <Modal.Body>
          <div
            style={{
              overflow: "hidden",
              cursor: zoomLevel > 1 ? "grab" : "auto",
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
          >
            <img
              ref={imgRef}
              src={previewImage}
              style={{
                transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
                transition: "transform 0.3s ease",
                maxWidth: "700px",
                maxHeight: "80%",
                width: "80%",
                margin: "auto",
                display: "block",

                // objectFit: "cover",
              }}
              alt="Preview"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleZoomOut}>
            <FaSearchMinus />
          </Button>
          <Button variant="secondary" onClick={handleZoomIn}>
            <FaSearchPlus />
          </Button>
          <Button variant="primary" onClick={() => setOpenImg(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Viewed user list */}
      <Modal
        onClose={handleCloseView}
        open={openView}
        size="sm"
        style={
          {
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // width: "550px",
          }
        }
      >
        <Modal.Header>
          <h6
            style={{
              color: "rgb(241, 111, 29)",
              borderBottom: "1px solid rgb(241, 111, 29)",
            }}
          >
            Viewed User
          </h6>
        </Modal.Header>
        <Modal.Body
          style={{
            overflow: "unset",
            maxheight: "none",
          }}
        >
          <div className={Styles.ViewPollBlock}>
            {viewed && viewed.length > 0 ? (
              viewed?.map((ele, ind) => {
                return (
                  <aside className={Styles.options}>
                    <div className={Styles.Voters}>
                      <h6 className={Styles.TextAlign}>{ele?.username}</h6>
                      <Moment format="MMM DD - h:mm a">{ele?.createdAt}</Moment>
                    </div>
                  </aside>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  // height: "80vh",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  // color: "#f16f1d",
                  fontSize: "large",
                }}
              >
                No Viewed User
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {loading === null ? (
        <div
          style={{
            display: "flex",
            height: "80vh",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            color: "#f16f1d",
            fontSize: "x-large",
          }}
        >
          something went wrong
        </div>
      ) : loading ? (
        <div
          style={{
            display: "flex",
            height: "80vh",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            color: "#f16f1d",
            fontSize: "x-large",
          }}
        >
          Loading...
        </div>
      ) : (
        <>
          {messages.length < 1 ? (
            <div
              style={{
                display: "flex",
                height: "80vh",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                color: "#f16f1d",
                fontSize: "x-large",
              }}
            >
              No Message
            </div>
          ) : (
            messages.map((message, index) => (
              <div
                className={styles.chatCard}
                style={{
                  borderRadius: "0px 15px 15px 15px",
                }}
                key={index}
              >
                {message?.message?.text && (
                  <pre
                    className={styles.chatText}
                    style={{ color: "#111", fontFamily: "sans-serif" }}
                  >
                    {message?.message?.text}
                  </pre>
                )}
                {message?.message?.file && (
                  <div>
                    {(() => {
                      switch (message?.message?.file?.mimetype) {
                        case "image/jpg":
                        case "image/jpeg":
                        case "image/avif":
                        case "image/png":
                        case "image/webp":
                        case "image/tiff":
                        case "image/bmp":
                          return (
                            <>
                              <img
                                src={
                                  imageUrls[message?.message?.file?.path]?.url
                                }
                                alt=""
                                style={{
                                  height: "150px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleImage(
                                    imageUrls[message?.message?.file?.path]?.url
                                  )
                                }
                              />
                              <button
                                className={Styles.btndownload}
                                onClick={() =>
                                  download(
                                    imageUrls[message?.message?.file?.path].url,
                                    message?.message?.file?.originalname,
                                    message?.message?.file?.filename
                                  )
                                }
                              >
                                Download
                              </button>
                            </>
                          );
                        case "audio/mpeg":
                        case "audio/mp3":
                        case "audio/webm":
                        case "application/wav":
                        case "video/webm":
                        case "audio/wav":
                          return (
                            <audio
                              src={imageUrls[message?.message?.file?.path]?.url}
                              controls
                              style={{ width: "250px", height: "40px" }}
                            />
                          );
                        case "video/mp4":
                          // case "video/x-matroska":
                          // case "video/ogg":
                          // case "video/avi":
                          // case "video/mpeg":
                          // case "video/quicktime":
                          // case "video/x-msvideo":
                          return (
                            <div className={styles.videoContainer}>
                              <video
                                src={
                                  imageUrls[message?.message?.file?.path]?.url
                                }
                                style={{
                                  maxWidth: "400px",
                                  height: "auto",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleOpenVideoModal(
                                    imageUrls[message?.message?.file?.path]?.url
                                  )
                                }
                              />
                              <button
                                title="Play Video"
                                onClick={() =>
                                  handleOpenVideoModal(
                                    imageUrls[message?.message?.file?.path]?.url
                                  )
                                }
                              >
                                <svg
                                  width={40}
                                  height={40}
                                  viewBox="0 0 256 256"
                                >
                                  <path
                                    fill="#f16f1d"
                                    d="M240 128a15.74 15.74 0 0 1-7.6 13.51L88.32 229.65a16 16 0 0 1-16.2.3A15.86 15.86 0 0 1 64 216.13V39.87a15.86 15.86 0 0 1 8.12-13.82a16 16 0 0 1 16.2.3l144.08 88.14A15.74 15.74 0 0 1 240 128"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          );
                        case "application/docx":
                        case "application/doc":
                        case "application/msword":
                        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                        case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
                        case "application/vnd.ms-word.document.macroEnabled.12":
                        case "application/vnd.ms-excel":
                        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                        case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
                        case "application/vnd.ms-excel.sheet.macroEnabled.12":
                        case "application/vnd.ms-powerpoint":
                        case "application/xlsx":
                        case "application/pptx":
                        case "application/octet-stream":
                        case "text/plain":
                        case "text/html":
                        case "application/apk":
                        case "application/pdf":
                        case "application/x-pdf":
                        case "application/x-gzpdf":
                        case "applications/vnd.pdf":
                        case "application/acrobat":
                        case "application/x-google-chrome-pdf":
                        case "text/pdf":
                        case "text/x-pdf":
                        case "text/csv":
                        case "application/zip":
                        case "application/x-zip":
                        case "application/octet-stream":
                        case "application/x-zip-compressed":
                        case "application/json":
                          return (
                            <>
                              {/* <iframe
                                  src={
                                    imageUrls[message?.messages?.file?.path]
                                      ?.url
                                  }
                                  title="Document Preview"
                                  width="100%"
                                  height="300px"
                                  style={{ border: "none" }}
                                /> */}
                              <button
                                className={Styles.btndownload1}
                                onClick={() =>
                                  download(
                                    imageUrls[message?.message?.file?.path]
                                      ?.url,
                                    message?.message?.file?.originalname,
                                    message?.message?.file?.filename
                                  )
                                }
                              >
                                {message?.message?.file?.originalname}
                                <span className={Styles.Download}></span>
                                <span className={Styles.linkBlock}>
                                  Click to download
                                </span>
                              </button>
                            </>
                          );
                        default:
                          return (
                            <button
                              className={Styles.btndownload1}
                              onClick={() =>
                                download(
                                  imageUrls[message?.message?.file?.path]?.url,
                                  message?.message?.file?.originalname,
                                  message?.message?.file?.filename
                                )
                              }
                            >
                              {message?.message?.file?.originalname}
                              <span className={Styles.Download}></span>
                              <span className={Styles.linkBlock}>
                                Click to download
                              </span>
                            </button>
                          );
                      }
                    })()}
                  </div>
                )}

                <div className={styles.ViewChatIcon}>
                  {message.senderName && (
                    <p className={styles.senderId}>
                      Sender: {message.senderName}
                    </p>
                  )}
                  <div
                    onClick={() => {
                      fetchViewChat(message.messageId);
                      handleOpenView(true);
                    }}
                    title="Viewed Users"
                  >
                    <IoMdEye />
                  </div>{" "}
                </div>

                <small style={{ color: "gray" }}>
                  {Date.now ? (
                    <Moment format="MMM DD - h:mm a">
                      {message?.createdAt}
                    </Moment>
                  ) : (
                    <Moment format="MMM DD YYYY h A">
                      {message?.createdAt}
                    </Moment>
                  )}
                </small>
              </div>
            ))
          )}
        </>
      )}
    </div>
  );
};

export default ViewuserChat;

// import React from "react";

// const ViewuserChat = () => {
//   return <div>ViewuserChat</div>;
// };

// export default ViewuserChat;
