import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "../../../api/AxiosInstance";
import Styles from "./_chat.module.css";
import { ImAttachment, ImCross, ImSpinner11 } from "react-icons/im";
import { SocketContext } from "../../../ioContext/socketIo";
import Conversation from "./Conversation";
import Message from "./Message";
import UserNameData from "./UserNameData";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import wavEncoder from "wav-encoder";
import {
  Modal,
  ButtonToolbar,
  Tooltip,
  Whisper,
  Toggle,
  DatePicker,
} from "rsuite";
import FileUpload from "./FileUpload";

import { AiFillDelete } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";
import { IoSendSharp } from "react-icons/io5";
import {
  BsFillBarChartFill,
  BsFillMicFill,
  BsFillMicMuteFill,
  BsFillPlayCircleFill,
} from "react-icons/bs";
import Cookies from "js-cookie";
import { BiPlusMedical } from "react-icons/bi";
import handleError from "../ErrorHandler";
// text editor
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

import { Box, Button, Divider, Modal as ModalMui } from "@mui/material";
import RemoveHtmlTag from "../../functions/RemoveHtmlTag";
const styleModal1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
};
const AllChatComp = () => {
  let navigate = useNavigate();

  const [arrivalMessageFile, setArrivalMessageFile] = useState([]);

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const socket = useContext(SocketContext);
  let scrollRef = useRef();
  let [loading, setLoading] = useState(false);
  let [loadingmsg, setLoadingMsg] = useState(false);
  let [loadingStudents, setLoadingStudents] = useState(true);
  const [stopscrollMsg, setStopScrollMsg] = useState(true);

  let [batchName, setBatchName] = useState("");
  let [batchStdentData, setBatchStdentData] = useState([]);
  let [particularStudent, setParticularStudent] = useState({});
  let [defaultStudents, setDefaultStudents] = useState([]);
  const [countedData, setCountedData] = useState(0);

  const [pastedImage, setPastedImage] = useState(null);

  // messages
  let [messageread, setMessageRead] = useState([]);
  let [messages, setMessages] = useState([]);
  // let [norecentchat, setNorevcentChat] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState([]);

  const [latestMsg, setLatestMsg] = useState([]);
  const [msgseen, setMsgSeen] = useState("");

  const [number, SetNumber] = useState(0);

  let [search, setSearch] = useState("");
  let [searchapi, setSearchApi] = useState("");
  let [clear, setClear] = useState(false);
  let [sort, setSort] = useState(false);

  let [msgScroll, setMsgScroll] = useState(0);

  // MODAL
  // const [isOpen, setIsOpen] = useState(false);

  const [open, setOpen] = React.useState(false);
  // const [backdrop, setBackdrop] = React.useState("static");

  //Online offline
  let [online, setOnline] = useState();

  // let [permenentBlock, setPermanentBlock] = useState(false);
  let [batchBlock, setBatchBlock] = useState(false);
  let [data, setSData] = useState("");

  // voice recoder//

  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isPlaybackActive, setIsPlaybackActive] = useState(false);
  // const [audioURL, setAudioURL] = useState("");
  const [recordedAudio, setRecordedAudio] = useState(null);
  // const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [stop, setStop] = useState(false);
  const [isAudioVisible, setIsAudioVisible] = useState(false);

  let [messageCount, setMessageCount] = useState(0);
  let [lengthmsg, setLengthMsg] = useState(0);

  //poll
  // const [polldata, setPollData] = useState([]);
  const [openPoll, setOpenPoll] = React.useState(false);
  const [areOptionsVisible, setAreOptionsVisible] = useState(false);

  const [stopscroll, setStopScroll] = useState(true);
  const [reply, setReply] = useState(false);
  const [forward, setForward] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const iconRef = useRef(null);
  const [poll, setPoll] = useState([
    {
      question: "",
      options: [""],
      toggleValue: false,
      toggleValue1: false,
      selectedDate: null,
    },
  ]);
  const [errors, setErrors] = useState({});

  const addDiv = () => {
    setPoll([
      ...poll,
      {
        question: "",
        options: [""],
        toggleValue: false,
        toggleValue1: false,
        selectedDate: null,
      },
    ]);
  };
  const deleteDiv = index => {
    const newQuestionAnswers = [...poll];
    newQuestionAnswers.splice(index, 1);
    setPoll(newQuestionAnswers);
  };

  const handleDateChange = (index, newDate) => {
    const updatedPoll = [...poll];
    // Convert the newDate to ISO string format
    updatedPoll[index].selectedDate = newDate?.toISOString();
    setPoll(updatedPoll);
  };

  const handleToggleChange = pollIndex => {
    const updatedPoll = [...poll];
    updatedPoll[pollIndex].toggleValue = !updatedPoll[pollIndex].toggleValue;
    setPoll(updatedPoll);
  };

  const handleToggleChange1 = index => {
    const updatedPoll = [...poll];
    updatedPoll[index].toggleValue1 = !updatedPoll[index].toggleValue1;
    setPoll(updatedPoll);
  };
  const handleOptionChange = (index, ind, newOption) => {
    const updatedPoll = [...poll];
    updatedPoll[index].options[ind] = newOption;
    setPoll(updatedPoll);
  };

  const handleAddOption = pollIndex => {
    // //(pollIndex);
    const updatedPoll = [...poll];
    updatedPoll[pollIndex].options = [...updatedPoll[pollIndex].options, ""];
    // //(updatedPoll);
    setPoll(updatedPoll);
  };

  const handleClearOption = (index, ind) => {
    const updatedPoll = [...poll];
    // //(updatedPoll);
    updatedPoll[index].options.splice(ind, 1);
    setPoll(updatedPoll);
  };
  const handleQuestionChange = (pollIndex, newQuestion) => {
    const updatedPoll = [...poll];
    updatedPoll[pollIndex].question = newQuestion;
    setPoll(updatedPoll);
  };
  const handleOpen = () => {
    setOpen(true);
    setAreOptionsVisible(!areOptionsVisible);
  };
  const handleClose = () => {
    // setImagePreview(null);
    setOpen(false);
  };
  const handleOpenPoll = () => {
    setOpenPoll(true);
    setAreOptionsVisible(!areOptionsVisible);
  };
  const handleClosePoll = () => {
    setOpenPoll(false);
    setPoll([
      {
        question: "",
        options: [""],
        toggleValue: false,
        toggleValue1: false,
        selectedDate: null,
      },
    ]);
    setErrors({});
  };

  const toggleOptions = () => {
    setAreOptionsVisible(!areOptionsVisible);
  };

  const isFormValid = () => {
    const newErrors = {};

    for (let i = 0; i < poll?.length; i++) {
      if (!poll[i]?.question) {
        newErrors[`question_${i}`] = "Question is required";
      }
      // if (poll[i].options.some(option => !option)) {
      //   newErrors[`options_${i}`] = "Add options ";
      // }
      const emptyOptions = poll[i]?.options?.filter(option => !option.trim());

      if (emptyOptions?.length > 0) {
        newErrors[`options_${i}`] = "All options must be filled";
      }
      if (poll[i]?.options?.length == 1) {
        newErrors[`options_${i}`] = "Add  atleast 2 options ";
      }
      if (!poll[i]?.selectedDate) {
        newErrors[`date_${i}`] = "Date is required";
      }
    }

    // Clear errors for fields that are now valid
    for (let i = 0; i < poll?.length; i++) {
      if (poll[i]?.question) {
        delete newErrors[`question_${i}`];
      }
      // if (!poll[i].options.some(option => !option)) {
      //   delete newErrors[`options_${i}`];
      // }
      // if (!poll[i].options.length == 1) {
      //   delete newErrors[`options_${i}`];
      // }
      if (poll[i]?.selectedDate) {
        delete newErrors[`date_${i}`];
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // text Editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showEditor, setShowEditor] = useState(false);
  const handleEditorChange = state => {
    setEditorState(state);
  };

  const handleAddIconClick = () => {
    setShowEditor(!showEditor); // Toggle editor visibility
  };
  // text Editor modal
  const [openEditor, setOpenEditor] = React.useState(false);

  const handleOpenEditor = () => {
    const contentState = ContentState.createFromText(newMessage);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
    setOpenEditor(true);
    setShowEditor(!showEditor);
  };
  const handleCloseEditor = () => {
    setOpenEditor(false);
    setShowEditor(false);
    setEditorState(EditorState.createEmpty());
  };

  const handleSubmit = () => {
    if (!isFormValid()) {
      const newErrors = {};

      for (let i = 0; i < poll?.length; i++) {
        if (!poll[i]?.question) {
          newErrors[`question_${i}`] = "Question is required";
        }
        if (poll[i]?.options?.some(option => !option)) {
          newErrors[`options_${i}`] = "Add options ";
        }
        if (poll[i]?.options?.length == 1) {
          newErrors[`options_${i}`] = "Add  atleast 2 options ";
        }
        if (!poll[i]?.selectedDate) {
          newErrors[`date_${i}`] = "Date is required";
        }
      }

      setErrors(newErrors);
    } else {
      const currentDate = new Date();
      const currentDateTime = currentDate.toISOString();
      // //("From Submit");
      let payload = poll?.map((ele, ind) => {
        return {
          pollcreaterid: user?._id,
          poll_question: ele?.question,
          options: ele?.options,
          startTime: currentDateTime,
          endTime: ele?.selectedDate,
          stu_view_vote: ele?.toggleValue1,
          vote_type: ele?.toggleValue,
        };
      });
      async function students() {
        await AxiosInstance.post(`/employee/users_pollcreate`, payload, {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        })
          .then(x => {
            console?.log(x);
            let data = x?.data;
            // //(data);
            let poll1 = data?.map((ele, ind) => {
              const date = new Date(ele?.endTime);

              // Extract date and time components
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to month because it's 0-based
              const day = String(date.getDate()).padStart(2, "0");
              const hours = String(date.getHours()).padStart(2, "0");
              const minutes = String(date.getMinutes()).padStart(2, "0");

              // Create the desired format
              const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
              let time = formattedDate;
              // //(time);
              return {
                _id: ele._id,
                poll_question: ele.poll_question,
                endTime: ele.endTime,
                stu_view_vote: ele.stu_view_vote,
                user_id: user._id,
                time: time,
              };
            });
            // //(poll1);

            let aaa = poll1.map(x => {
              return reply
                ? socket.emit(
                    "sendMessage",
                    {
                      files: [x],
                      senderId: user._id,
                      receiverId: particularStudent?.studentId,
                      role: user?.role1,
                      code: batchName,

                      type: "batch",
                      device: "web",
                      replayback: {
                        message: selectedMessages[0]?.messages?.text
                          ? selectedMessages[0]?.messages?.text
                          : "",
                        file: selectedMessages[0]?.messages?.files
                          ? selectedMessages[0]?.messages?.files
                          : "",
                      },
                    },
                    function callback(msg) {
                      setLoading(false);
                      setReply(false);
                      setSelectedMessages([]);

                      msg.tick = false;
                      setArrivalMessage(msg);
                    }
                  )
                : new Promise((resolve, reject) => {
                    socket?.emit(
                      "sendMessage",
                      {
                        senderId: user._id,
                        receiverId: particularStudent.studentId,
                        text: x.poll_question,
                        role: user?.role1,
                        code: batchName,
                        type: "batch",
                        device: "web",
                        poll: x,
                      },
                      function callback(msg) {
                        // //(msg, "jjjjj");
                        setLoading(false);
                        resolve(msg);
                        msg.tick = false;
                        // setMessages([...messages, msg]);
                      }
                    );
                  });
            });
            Promise.all(aaa)
              .then(message => {
                // //(message, "promis");
                message.forEach(msg => {
                  setMessages(prevMessages => prevMessages.concat(msg));
                });
              })
              .catch(error => {
                console.error("Error:", error);

                // Handle any errors that may occur during the socket.emit calls
              });
            // //(aa);
          })
          .catch(x => {
            console?.log(x);
            handleClosePoll();
            if (x?.response?.status === 403) {
              handleError(navigate, socket);
            }
          })
          .finally(() => {
            handleClosePoll();
          });
      }
      students();
    }
  };

  const disablePastDate = date => {
    const today = new Date();
    // Set the time to midnight for comparison
    today.setHours(0, 0, 0, 0);

    return date < today;
  };
  const handleClickOutside = event => {
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setAreOptionsVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    let audioStream;
    let recorder;

    const startRecording = async () => {
      try {
        audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        recorder = new MediaRecorder(audioStream);
        recorder.addEventListener("dataavailable", handleDataAvailable);
        recorder.start();
        setMediaRecorder(recorder);
        setIsRecording(true);
        // setRecordingStatus("Recording...");
      } catch (error) {
        alert("Error accessing microphone");
        // setStop(true)
        setIsRecording(false);

        console.error("Error accessing microphone:", error);
      }
    };

    const stopRecording = () => {
      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
        mediaRecorder.removeEventListener("dataavailable", handleDataAvailable);
        setIsRecording(false);
        // setRecordingStatus("Recording stopped.");
      }
      if (audioStream) {
        audioStream.getTracks().forEach(track => track.stop());
      }
    };

    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
      setRecordedChunks([]); // Clear recorded
    }

    return () => {
      stopRecording();
    };
  }, [isRecording]);

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleDataAvailable = event => {
    if (event.data.size > 0) {
      const audioData = event.data;
      //(event.data);
      //(audioData);
      setRecordedAudio(audioData);
      setRecordedChunks(prevChunks => [...prevChunks, event.data]);
    }
  };
  //(recordedAudio);
  const handleStopRecording = () => {
    setStop(true);
    setIsRecording(false);
    //(recordedAudio);
    if (recordedAudio) {
      // storeAudioLocally(recordedAudio);
    }
  };

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
    setIsPlaybackActive(prevPlaybackState => !prevPlaybackState);
    // Clear recorded chunks when playback is triggered
    // setRecordedChunks([]);
  };

  useEffect(() => {
    if (recordedAudio) {
      storeAudioLocally(recordedAudio);
    }
  }, [recordedAudio]);
  //("object");
  const getAudioURL = () => {
    if (recordedChunks.length === 0) {
      console.error("No audio recorded");
      return null;
    }
    const audioBlob = new Blob(recordedChunks, { type: "audio/webm" });
    //(audioBlob);

    return URL.createObjectURL(audioBlob);
  };
  //(isPlaybackActive);

  const audioPlayer = isPlaybackActive ? (
    <audio
      controls
      autoPlay
      key={isPlaybackActive ? "audio-playback" : "audio-empty"}
      // className={!stop ? `${Styles.AudioNot}` : `${Styles.Audio}`}
    >
      <source src={getAudioURL()} type="audio/webm" />
      Your browser does not support the audio element.
    </audio>
  ) : null;

  const storeAudioLocally = audioBlob => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        localStorage.setItem("audioBlob", base64String);
        //("Audio stored locally");
      };
      reader.readAsDataURL(audioBlob);
    } catch (error) {
      console.error("Error storing audio locally:", error);
    }
  };

  let HandleAudio = async e => {
    e.preventDefault();
    setStop(false);
    setIsPlaying(false);
    const base64String = localStorage.getItem("audioBlob");
    const blobToArrayBuffer = blob => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    };

    const decodeAudioData = arrayBuffer => {
      return new Promise((resolve, reject) => {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        audioContext.decodeAudioData(arrayBuffer, resolve, reject);
      });
    };

    if (base64String) {
      const arrayBuffer = await blobToArrayBuffer(recordedAudio);
      const audioBuffer = await decodeAudioData(arrayBuffer);

      const wavArrayBuffer = await wavEncoder.encode({
        sampleRate: audioBuffer.sampleRate,
        channelData: [audioBuffer.getChannelData(0)], // Assuming mono audio
      });

      const wavBlob = new Blob([wavArrayBuffer], { type: "audio/wav" });
      const wavFile = new File(
        [wavBlob],
        `audio${Math.round(Math.random() * 100)}.wav`,
        {
          type: "audio/wav",
        }
      );

      if (wavFile !== "") {
        try {
          let payload = {
            wavFile,
            sender: user._id,
          };

          const options = {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
              "Content-Type": "multipart/form-data",
            },
          };
          const res = await AxiosInstance.post(
            "/chat/newmsgfiles",
            payload,
            options
          );
          let filesData = res.data.files;
          //("object");

          socket.emit(
            "sendMessage",
            {
              files: filesData,
              senderId: user._id,
              receiverId: particularStudent?.studentId,
              role: user?.role1,
              code: null,
              type: "batch",
              device: "web",
              poll: null,
            },
            function callback(msg) {
              //(msg);
              msg.tick = false;
              setMessages([...messages, msg]);
              setIsPlaybackActive(false);
              setIsAudioVisible(false); // Hide the audio tag
              setRecordedChunks([]); // Clear the recorded audio chunks
              //("end");
              setIsPlaying(false);
            }
          );
          setIsAudioVisible(false); // Hide the audio tag
          setRecordedChunks([]); // Clear the recorded audio chunks
          //("asdasdasda");
          setIsPlaying(false);
        } catch (error) {
          if (error?.response?.status === 403) {
            handleError(navigate, socket);
          }
          //(error);
        }
      } else {
        //("type msg")
      }
      // return file;
    }
    return null;
  };
  let handleDeleteButtonClick = () => {
    localStorage.removeItem("audioBlob");
    setStop(false);
    setIsPlaying(false);
    setRecordedAudio(null);
    setIsPlaybackActive(false);
    setIsAudioVisible(false); // Hide the audio tag
    setRecordedChunks([]); // Clear the recorded audio chunks
    setIsPlaying(false);
  };

  useEffect(() => {
    socket.on("batchclose_open", data => {
      //(data);
      setBatchBlock(true);
      setSData(data.result.res);
    });
  }, []);
  if (batchBlock === true) {
    toast.error(data, { position: "top-right" });
    function greeting() {
      // window.location.reload()
      navigate("/user-dashboard");
    }
    setTimeout(greeting, 3000);
    setBatchBlock(false);
    setSData("");
  }

  let HandleSearch = e => {
    //("first");
    //(e);
    setSearchApi(e);
    // //(search)
  };
  let HandleClear = e => {
    //(search);
    //("second");
    setClear(true);
    setSearchApi("");
    setSearch("");
  };

  useEffect(() => {
    async function fetchlist() {
      setLoadingStudents(true);
      const searchValue = search ? `&search=${search}` : "";
      AxiosInstance.get(
        `/users/recentChatStudents?scroll=${number}&sortby=${sort}${searchValue}`,
        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      )
        .then(data => {
          let studentData = data?.data?.students;

          if (studentData?.length === 0) {
            setStopScroll(false);
            setBatchStdentData([]);
          } else if (search.length > 0 && search !== "") {
            setBatchStdentData(studentData);
            setDefaultStudents(studentData);
          } else if (number > 0) {
            let mmm = [...batchStdentData, ...studentData];
            setBatchStdentData(mmm);
            setDefaultStudents(mmm);
          } else if (number > 0 && sort === true) {
            SetNumber(0);
            setBatchStdentData(studentData);
            setDefaultStudents(studentData);
          } else {
            setBatchStdentData(studentData);
            setDefaultStudents(studentData);
          }
          let batchname = data?.data?.batchData?.batchCode;
          let lastmsg = data?.data?.usersdata;

          setBatchName(batchname);
          setLatestMsg(lastmsg);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        })
        .finally(() => {
          setLoadingStudents(false);
        });
    }
    fetchlist();
  }, [sort]);
  const handleReset = () => {
    setBatchStdentData(defaultStudents);
    // setFiltered({ x: false, term: "" });
    setSearch("");
  };
  let handleSearchConvo = () => {
    async function fetchlist() {
      setLoadingStudents(true);
      // const searchValue = search ? `&search=${search}` : "";
      // Trim the search term only if it exists
      const trimmedSearch = search ? search.trim() : "";
      const searchValue = trimmedSearch ? `&search=${trimmedSearch}` : "";
      AxiosInstance.get(
        `/users/recentChatStudents?sortby=${sort}${searchValue}`,
        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      )
        .then(data => {
          let studentData = data?.data?.students;

          let batchname = data?.data?.batchData?.batchCode;
          let lastmsg = data?.data?.usersdata;
          setBatchStdentData(studentData);
          setBatchName(batchname);
          setLatestMsg(lastmsg);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        })
        .finally(() => {
          setLoadingStudents(false);
        });
    }
    fetchlist();
  };

  // scroll loadmore
  let handleLoadMore = async e => {
    let scroolVal = e;

    async function fetchlist() {
      setLoadingStudents(true);

      AxiosInstance.get(
        `/users/recentChatStudents?sortby=${sort}&scroll=${scroolVal}`,
        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      )
        .then(data => {
          let studentData = data?.data?.students;

          let batchname = data?.data?.batchData?.batchCode;
          let lastmsg = data?.data?.usersdata;
          if (studentData.length > 0) {
            let mmm = [...batchStdentData, ...studentData];
            setBatchStdentData(mmm);
            setDefaultStudents(mmm);
          } else if (studentData.length <= 0) {
            setStopScroll(false);
          }

          setBatchName(batchname);
          setLatestMsg(lastmsg);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        })
        .finally(() => {
          setLoadingStudents(false);
        });
    }
    fetchlist();
  };

  useEffect(() => {
    socket.on("getUsers", users => {});

    socket.on("online_offline", data => {});

    socket.on("getMessage", data => {
      let abc = { ...data, poll: null };
      if (data.brodcastchat) {
        setArrivalMessage(abc);
      } else {
        setArrivalMessage(abc);
      }
    });
  }, []);
  useEffect(() => {
    setMessages(prev => [...prev, arrivalMessage]);
  }, [particularStudent?.studentId && arrivalMessage]);

  useEffect(() => {
    const getconversations = async () => {
      try {
        if (user._id && particularStudent?.studentId !== undefined) {
          setLoadingMsg(true);
          setStopScrollMsg(true);

          // socket.emit("isOnline", particularStudent.studentId);
          socket.emit(
            "isOnline",
            {
              senderId: user?._id,
              role: user?.role1,
              receiverId: particularStudent?.studentId,
              device: "web",
            },
            function callback(msg) {
              setOnline(msg);
            }
          );

          let device = Cookies.get("device", { domain: ".qspiders.com" });
          socket.emit("chatwith", {
            senderId: user?._id,
            role: user?.role1,
            receiverId: particularStudent?.studentId,
            deviceId: device,
            code: null,
            device: "web",
          });

          const res = await AxiosInstance.post(
            `/chat/msg/new/allmsg?scroll=${msgScroll}`,
            {
              senderId: user?._id,
              receiverId: particularStudent.studentId,
              userId: user?._id,
              studentId: particularStudent?.studentId,
              code: null,
            },
            {
              headers: {
                Authorization: `Bearer ${user?.TOKEN}`,
              },
            }
          );

          res?.data?.msgs?.reverse();
          setMessageCount(res?.data?.msgscount);
          setLengthMsg(res?.data?.msgs?.length);
          if (res?.data?.msgs?.length > 0 && msgScroll > 0) {
            let mess = res?.data?.msgs;
            setMessages([...mess, ...messages]);
            setLoadingMsg(false);
            setMessageRead(res?.data?.notification);
          } else if (res?.data?.msgs?.length > 0 && msgScroll <= 0) {
            socket.on("msgSeen", data1 => {
              setMsgSeen(data1);
              res.data.msgs.map(x => {
                return { ...x, tick: true };
              });
            });
            if (res?.data?.msgs) {
              for (let index = 0; index < res.data.msgs.length; index++) {
                const element = res.data.msgs[index];
                if (!element.hasOwnProperty("poll")) {
                  element.poll = null; // or set it to an initial value as needed
                }
              }
            }
            for (
              let index = 1;
              index <= res?.data?.notification?.studentNotification;
              index++
            ) {
              const element =
                index > res?.data?.msgs?.length
                  ? res?.data?.msgs[res?.data?.msgs?.length - 1]
                  : res?.data?.msgs[res?.data?.msgs?.length - index];
              element.tick = false;
            }

            setMessageRead(res?.data?.notification);
            setMessages(res?.data?.msgs);
            setLoadingMsg(false);
          } else if (res?.data?.msgs?.length <= 0) {
            //("end message");
            setStopScrollMsg(false);
            setLoadingMsg(false);
            setMessages(res?.data?.msgs);
          }

          // }
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          handleError(navigate, socket);
        }
      }
    };
    getconversations();
  }, [particularStudent?.studentId, msgseen, msgScroll]);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom(); // Call the function when messages change
  }, [messages]);

  let handelSubmit = async e => {
    e?.preventDefault();

    if (newMessage.trim() !== "") {
      setLoading(true);
      if (reply) {
        const messageText = selectedMessages[0]?.messages?.text;
        const htmlContent = selectedMessages[0]?.html === true;
        const replyBack = {
          message: messageText
            ? htmlContent
              ? await RemoveHtmlTag(messageText)
              : messageText
            : "",
          file: selectedMessages[0]?.messages?.files || "",
        };
        socket?.emit(
          "sendMessage",
          {
            senderId: user._id,
            receiverId: particularStudent.studentId,
            text: newMessage,
            role: user?.role1,
            code: batchName,
            type: "batch",
            device: "web",
            poll: null,
            replayback: replyBack,
          },
          function callback(msg) {
            setReply(false);
            setSelectedMessages([]);

            if (msg.read === false) {
              msg.tick = false;
              setMessages([...messages, msg]);
              setLoading(false);
            } else {
              setMessages([...messages, msg]);
              setLoading(false);
            }
          }
        );
      } else {
        socket?.emit(
          "sendMessage",
          {
            senderId: user._id,
            receiverId: particularStudent.studentId,
            text: newMessage,
            role: user?.role1,
            code: batchName,
            type: "batch",
            device: "web",
            poll: null,
          },
          function callback(msg) {
            if (msg.read === false) {
              msg.tick = false;
              setMessages([...messages, msg]);
              setLoading(false);
            } else {
              setMessages([...messages, msg]);
              setLoading(false);
            }
          }
        );
      }

      // reply
      //   ?
      //   :
    } else {
    }
    try {
      setNewMessage("");
    } catch (error) {
      console.log(error);
    }
  };
  // const handlePaste = e => {
  //   e.preventDefault(); // Prevent the default paste behavior

  //   const clipboardData = e.clipboardData || window.clipboardData;
  //   const pastedText = clipboardData.getData("text"); // Get the text from the clipboard

  //   if (pastedText) {
  //     setNewMessage(pastedText);
  //   }

  //   // Handle image paste
  //   const items = clipboardData.items;
  //   for (let i = 0; i < items.length; i++) {
  //     const item = items[i];
  //     if (item.type.indexOf("image") !== -1) {
  //       setOpen(true);
  //       const file = item.getAsFile();
  //       const imageUrl = URL.createObjectURL(file);

  //       setPastedImage(imageUrl); // Store pasted image
  //       break;
  //     }
  //   }
  // };

  const handlePaste = e => {
    e.preventDefault(); // Prevent the default paste behavior

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text"); // Get the text from the clipboard

    if (pastedText) {
      // Append the new pasted text to the existing message
      setNewMessage(prevMessage => prevMessage + pastedText);
    }

    // Handle image paste
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf("image") !== -1) {
        setOpen(true);
        const file = item.getAsFile();
        const imageUrl = URL.createObjectURL(file);

        setPastedImage(imageUrl); // Store pasted image
        break;
      }
    }
  };

  // text edit send function
  let handleEditorSend = e => {
    const contentState = editorState.getCurrentContent();
    // Check if the content is empty
    const isEmpty =
      !contentState.hasText() || contentState.getPlainText().trim() === "";
    if (!isEmpty) {
      const contentState = editorState.getCurrentContent();

      const rawContentState = convertToRaw(contentState);

      let content = draftToHtml(rawContentState);
      setNewMessage(content);
      socket?.emit(
        "sendMessage",
        {
          senderId: user._id,
          receiverId: particularStudent.studentId,
          text: content,
          html: true,
          role: user?.role1,
          code: batchName,
          type: "batch",
          device: "web",
          poll: null,
        },
        function callback(msg) {
          if (msg.read === false) {
            msg.tick = false;
            setMessages([...messages, msg]);
            setLoading(false);
          } else {
            setMessages([...messages, msg]);
            setLoading(false);
          }
        }
      );
      setEditorState(EditorState.createEmpty());
      setOpenEditor(false);
      setShowEditor(false);
    }
    setNewMessage("");
  };

  let handelSubmitButton = e => {
    handelSubmit();
  };
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      handelSubmit();
    }
  };

  let forwardmsg = [];

  let selectedmsg = data => {
    let aaa = forwardmsg?.map(x => x.index);
    const index = data.index;

    if (aaa.includes(index)) {
      for (let i = 0; i < forwardmsg.length; i++) {
        if (forwardmsg[i].index === index) {
          forwardmsg.splice(i, 1);
          i--; // decrement i to account for removed element
        }
      }
    } else {
      //("else");
      if (forwardmsg.length === 5 || forwardmsg.length > 5) {
        //("5 limit");
        alert("you can only send 5 messages at a time");
      } else {
        //("else");
        forwardmsg.push(data);
      }
    }
    //(forwardmsg);
  };
  useEffect(() => {
    if (arrivalMessageFile?.length > 0) {
      setMessages(prevMessages => prevMessages.concat(arrivalMessageFile));
      setArrivalMessageFile([]);
    }
  }, [arrivalMessageFile?.length > 0]);

  let HandleLoadMessages = () => {
    setMsgScroll(msgScroll + lengthmsg);
  };

  let CancelReply = () => {
    setReply(false);
    setForward(false);
    setSelectedMessages([]);
  };
  let CancelForward = () => {
    setForward(false);
    setSelectedMessages([]);
  };

  const handleMessageClick = (clickedMessage, index, isMessageSelected) => {
    const existingIndex = selectedMessages.findIndex(
      msg =>
        (msg.messages.text === clickedMessage.messages.text &&
          msg.index === clickedMessage.index) ||
        (msg.messages.flies === clickedMessage?.messages?.flies &&
          msg.index === clickedMessage.index)
    );

    const isMessageAlreadySelected = existingIndex !== -1;
    const hasReachedMaxSelection = selectedMessages.length === 5;

    if (!isMessageAlreadySelected && selectedMessages.length < 5) {
      setSelectedMessages([...selectedMessages, clickedMessage]);
    } else if (isMessageAlreadySelected) {
      const updatedMessages = selectedMessages.filter(
        (_, index) => index !== existingIndex
      );
      setSelectedMessages(updatedMessages);
    } else if (selectedMessages.length === 5) {
      // Alert message when the selection limit is reached or duplicate is clicked
      alert(`You can select only 5 unique messages.`);
    }
  };
  return (
    <>
      <ModalMui open={openEditor}>
        <Box sx={styleModal1} position="relative">
          <h4 style={{ textAlign: "center", color: "#f16f1d" }}>Text Editor</h4>
          <Divider />
          <br />
          <button
            style={{
              border: "none",
              background: "none",
              position: "absolute",
              right: "5px",
              top: "5px",
            }}
            onClick={handleCloseEditor}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <Box id="modal-description" sx={{ mt: 2 }}>
            <Editor
              editorState={editorState}
              wrapperClassName="chatEditorWrapper"
              editorClassName="chatEditor"
              onEditorStateChange={handleEditorChange}
              placeholder="Write something..."
              toolbar={{
                options: ["inline", "colorPicker", "emoji", "history"],
                inline: {
                  options: ["bold", "italic", "underline", "strikethrough"],
                },
                colorPicker: {
                  colors: [
                    "#000000",
                    "#ffffff",
                    "#ff0000",
                    "#00ff00",
                    "#0000ff",
                    "#FFFF00",
                  ],
                },
              }}
            />
          </Box>

          <Box
            sx={{ display: "flex", gap: 1, justifyContent: "flex-end", mt: 2 }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseEditor}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={handleEditorSend}
            >
              Send
            </Button>
          </Box>
        </Box>
      </ModalMui>
      <section id={Styles.chatBoxSection}>
        <article>
          <aside className={Styles.chatMenu}>
            <Conversation
              batchStdentData={batchStdentData}
              setParticularStudent={setParticularStudent}
              particularStudent={particularStudent}
              latestMsg={latestMsg}
              messages={messages}
              SetNumber={SetNumber}
              number={number}
              setBatchStdentData={setBatchStdentData}
              messageread={messageread}
              setStop={setStop}
              setIsPlaying={setIsPlaying}
              setRecordedAudio={setRecordedAudio}
              setIsPlaybackActive={setIsPlaybackActive}
              setIsAudioVisible={setIsAudioVisible}
              setRecordedChunks={setRecordedChunks}
              HandleSearch={HandleSearch}
              search={search}
              setSearch={setSearch}
              HandleClear={HandleClear}
              loadingStudents={loadingStudents}
              setIsRecording={setIsRecording}
              sort={sort}
              setSort={setSort}
              stopscroll={stopscroll}
              setMsgScroll={setMsgScroll}
              setMessages={setMessages}
              setSelectedMessages={setSelectedMessages}
              setReply={setReply}
              setForward={setForward}
              handleSearchConvo={handleSearchConvo}
              handleReset={handleReset}
              handleLoadMore={handleLoadMore}
              setCountedData={setCountedData}
              countedData={countedData}
            />
          </aside>
          <aside
            className={Styles.chatBox}
            style={{
              backgroundImage: `url('https://img.freepik.com/free-vector/white-abstract-background_23-2148882948.jpg?t=st=1709615958~exp=1709619558~hmac=805f2415c67a823895b2b767c317f7689991cfe794cb46f602dc88f9dc57b4d0&w=1380')`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className={Styles.chatBoxWrapper}>
              {particularStudent?.studentId ? (
                <>
                  <div className={Styles.chatBoxTop}>
                    <UserNameData name={particularStudent} online={online} />
                    <div
                      className={Styles.chatBoxStore}
                      id="scrollableDiv"
                      ref={scrollRef}
                      style={{
                        height: "78vh",
                        overflow: "auto",
                      }}
                    >
                      {messageCount != msgScroll + lengthmsg && (
                        <div className={Styles.SpinnerBlock}>
                          {" "}
                          <button
                            onClick={HandleLoadMessages}
                            className={Styles.Spinner}
                          >
                            <ImSpinner11 />
                          </button>
                        </div>
                      )}
                      {messages?.length > 0 ? (
                        messages?.map((m, ind) => (
                          <Fragment key={ind}>
                            <Message
                              particularStudent={particularStudent}
                              message={m}
                              own={m?.sender === user?._id}
                              index={ind}
                              selectedmsg={selectedmsg}
                              forwardmsg={forwardmsg}
                              loadingmsg={loadingmsg}
                              setReply={setReply}
                              reply={reply}
                              setForward={setForward}
                              forward={forward}
                              onClick={handleMessageClick}
                              selectedMessages={selectedMessages}
                              setSelectedMessages={setSelectedMessages}
                            />
                          </Fragment>
                        ))
                      ) : (
                        <span className={Styles.noConversation}>
                          <p style={{ color: "#f16f1d" }}>No Messages</p>
                        </span>
                      )}
                    </div>
                  </div>

                  {!forward ? (
                    <form onSubmit={handelSubmit} className={Styles.FormBlock}>
                      {reply && (
                        <div className={Styles.replyBlock}>
                          <div className={Styles.StatusText1}>
                            {" "}
                            {selectedMessages && (
                              <>
                                {/* <div>Reply</div> */}
                                <div className={Styles.copiedText}>
                                  {selectedMessages[0]?.messages?.text
                                    ? selectedMessages[0]?.messages?.text?.slice(
                                        0,
                                        180
                                      )
                                    : selectedMessages[0]?.messages?.files[0]
                                        ?.filename}
                                </div>
                                <div
                                  className={Styles.CrossMark}
                                  onClick={() => CancelReply()}
                                >
                                  X
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {/* {forward === false ? ( */}
                      <div className={Styles.chatBoxBottom}>
                        <input
                          required
                          autoFocus
                          className="chatMessageInput"
                          placeholder="write something..."
                          onChange={e => {
                            setNewMessage(e.target.value);
                          }}
                          value={newMessage}
                          onKeyPress={handleKeyPress}
                          onPaste={handlePaste}
                        />
                        <aside
                          style={{
                            position: "relative",
                            margin: "1%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          // className={Styles.UploadfileContainer}
                        >
                          <div onClick={handleOpenEditor}>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 32 32"
                              >
                                <path
                                  fill="#475569"
                                  d="M9.5 2c.502 0 .956.3 1.152.763l5.072 11.989l-1.908 1.907L12.48 13.5H6.52l-1.37 3.237a1.25 1.25 0 0 1-2.302-.974l5.5-13A1.25 1.25 0 0 1 9.5 2m1.922 9L9.5 6.458L7.58 11zm9.838.328a4.536 4.536 0 0 1 6.414 6.415l-9.418 9.417a4 4 0 0 1-1.73 1.018l-6.253 1.787a1 1 0 0 1-1.236-1.236l1.785-6.251a4 4 0 0 1 1.018-1.73z"
                                ></path>
                              </svg>
                            </span>
                          </div>
                        </aside>
                        <aside
                          style={{ position: "relative" }}
                          className={Styles.UploadfileContainer}
                        >
                          <>
                            {isRecording ? (
                              ""
                            ) : stop ? (
                              ""
                            ) : (
                              <>
                                <ButtonToolbar ref={iconRef}>
                                  <FaPlus onClick={toggleOptions} />
                                </ButtonToolbar>
                                {areOptionsVisible && (
                                  <div className={Styles.ropdowncontent}>
                                    {/* Add your dropdown content here */}
                                    <ul>
                                      <li
                                        className={Styles.FileShare}
                                        onClick={handleOpen}
                                      >
                                        <ImAttachment></ImAttachment>
                                        files
                                      </li>
                                      <li
                                        className={Styles.FileShare}
                                        onClick={handleOpenPoll}
                                      >
                                        <BsFillBarChartFill></BsFillBarChartFill>{" "}
                                        Poll
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                <Modal
                                  backdrop="static"
                                  role="alertdialog"
                                  open={openPoll}
                                  onClose={handleClosePoll}
                                >
                                  <div className={Styles.HeaderPoll}>
                                    <span onClick={handleClosePoll}>
                                      <ImCross />
                                    </span>
                                    <h4>Create Poll</h4>
                                  </div>

                                  <Modal.Body>
                                    {poll?.map((qa, index) => (
                                      <div
                                        className={Styles.modalcontent}
                                        key={index}
                                      >
                                        <div>
                                          <div className={Styles.QuestionBlock}>
                                            <h5>Question:</h5>

                                            <span
                                              className={Styles.deleteicon}
                                              onClick={() => deleteDiv(index)}
                                            >
                                              <FaMinus />
                                            </span>
                                          </div>
                                          <input
                                            className={Styles.InputQue}
                                            type="text"
                                            id="question"
                                            placeholder="Ask question"
                                            value={qa?.question}
                                            onChange={e => {
                                              handleQuestionChange(
                                                index,
                                                e.target.value
                                              );
                                              const updatedErrors = {
                                                ...errors,
                                              };
                                              delete updatedErrors[
                                                `question_${index}`
                                              ];
                                              setErrors(updatedErrors);
                                            }}
                                          />
                                          {errors[`question_${index}`] && (
                                            <p className={Styles.errormessage}>
                                              {errors[`question_${index}`]}
                                            </p>
                                          )}
                                        </div>
                                        <h5>Options:</h5>
                                        {qa?.options.map((option, ind) => (
                                          <div
                                            key={ind}
                                            className={Styles.OptionBlock}
                                          >
                                            <div className={Styles.InputOption}>
                                              {" "}
                                              <input
                                                className={Styles.InputQue}
                                                type="text"
                                                placeholder="Add"
                                                value={option}
                                                onChange={e => {
                                                  handleOptionChange(
                                                    index,
                                                    ind,
                                                    e.target.value
                                                  );
                                                  const updatedErrors = {
                                                    ...errors,
                                                  };
                                                  delete updatedErrors[
                                                    `options_${index}`
                                                  ];
                                                  setErrors(updatedErrors);
                                                  // Add validation for empty options
                                                }}
                                              />
                                              <button
                                                className={Styles.ClearOption}
                                                onClick={() =>
                                                  handleClearOption(index, ind)
                                                }
                                              >
                                                <ImCross />
                                              </button>
                                            </div>
                                            {errors[`options_${index}`] && (
                                              <p
                                                className={Styles.errormessage}
                                              >
                                                {errors[`options_${index}`]}
                                              </p>
                                            )}
                                          </div>
                                        ))}
                                        <button
                                          onClick={() => handleAddOption(index)}
                                          className={Styles.AddOptions}
                                        >
                                          <FaPlus />
                                        </button>
                                        <div className={Styles.datePicker}>
                                          {" "}
                                          <DatePicker
                                            format="yyyy-MM-dd HH:mm"
                                            calendarDefaultDate={new Date()}
                                            disabledDate={disablePastDate}
                                            onChange={date => {
                                              handleDateChange(index, date);
                                              const updatedErrors = {
                                                ...errors,
                                              };
                                              delete updatedErrors[
                                                `date_${index}`
                                              ];
                                              setErrors(updatedErrors);
                                            }}
                                            ranges={[
                                              {
                                                label: "Now",
                                                value: new Date(),
                                              },
                                            ]}
                                            style={{ width: 260 }}
                                          />
                                          {errors[`date_${index}`] && (
                                            <p className={Styles.errormessage}>
                                              {errors[`date_${index}`]}
                                            </p>
                                          )}
                                        </div>
                                        <div className={Styles.Allow}>
                                          <h6>Allow multiple answers</h6>{" "}
                                          <span>
                                            {" "}
                                            <Toggle
                                              checked={qa?.toggleValue}
                                              onChange={() =>
                                                handleToggleChange(index)
                                              }
                                            />
                                          </span>
                                        </div>
                                        <div className={Styles.Allow}>
                                          <h6>Allow to View votes</h6>{" "}
                                          <span>
                                            {" "}
                                            <Toggle
                                              checked={qa?.toggleValue1}
                                              onChange={() =>
                                                handleToggleChange1(index)
                                              }
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                    <button
                                      onClick={addDiv}
                                      className={Styles.AddPoll}
                                    >
                                      <BiPlusMedical />
                                    </button>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    {" "}
                                    <button
                                      onClick={handleSubmit}
                                      className={Styles.SubmitPoll}
                                    >
                                      <IoSendSharp />
                                    </button>
                                  </Modal.Footer>
                                </Modal>
                              </>
                            )}

                            <Modal
                              keyboard={false}
                              open={open}
                              onClose={handleClose}
                            >
                              <Modal.Header>
                                <Modal.Title>Send File</Modal.Title>
                              </Modal.Header>

                              <Modal.Body>
                                <FileUpload
                                  PStudent={particularStudent}
                                  setMessages={setMessages}
                                  messages={messages}
                                  setOpen={setOpen}
                                  batchName={batchName}
                                  setArrivalMessage={setArrivalMessage}
                                  setArrivalMessageFile={setArrivalMessageFile}
                                  reply={reply}
                                  selectedMessages={selectedMessages}
                                  setReply={setReply}
                                  pastedImage={pastedImage}
                                  setPastedImage={setPastedImage}
                                  setSelectedMessages={setSelectedMessages}
                                />
                              </Modal.Body>
                              <Modal.Footer></Modal.Footer>
                            </Modal>
                          </>
                        </aside>
                        <aside className={Styles.AudioBlock}>
                          <div>
                            {!stop && !isRecording && !isPlaying && (
                              <Whisper
                                followCursor
                                speaker={<Tooltip>{"Start"}</Tooltip>}
                              >
                                <main
                                  className={Styles.StartAudio}
                                  onClick={handleStartRecording}
                                  disabled={isRecording}
                                >
                                  <span>
                                    {/* start */}
                                    <BsFillMicFill />
                                  </span>
                                </main>
                              </Whisper>
                            )}
                            <div
                              className={`${Styles.recordingAnimation} ${
                                isRecording ? Styles.active : ""
                              }`}
                            ></div>
                            {isRecording && (
                              <Whisper
                                followCursor
                                speaker={<Tooltip>{"Stop"}</Tooltip>}
                              >
                                <main
                                  className={Styles.StopAudio}
                                  onClick={handleStopRecording}
                                  disabled={!isRecording}
                                >
                                  <span>
                                    {/* stop */}
                                    <BsFillMicMuteFill />
                                  </span>
                                </main>
                              </Whisper>
                            )}
                            {stop && (
                              <Whisper
                                followCursor
                                speaker={<Tooltip>{"Play"}</Tooltip>}
                              >
                                <main
                                  className={Styles.PlayAudio}
                                  onClick={handlePlayButtonClick}
                                  disabled={isRecording}
                                >
                                  <span>
                                    {/* play */}
                                    <BsFillPlayCircleFill />
                                  </span>
                                </main>
                              </Whisper>
                            )}
                            {/* {stop &&<p>{recordingStatus}</p>} */}
                            {audioPlayer}
                          </div>
                        </aside>
                        {stop && (
                          <div className={Styles.SendAudioBlock}>
                            <Whisper
                              followCursor
                              speaker={<Tooltip>{"Delete"}</Tooltip>}
                            >
                              <span
                                onClick={handleDeleteButtonClick}
                                className={Styles.DeleteAudio}
                              >
                                <AiFillDelete />
                              </span>
                            </Whisper>
                            <span
                              onClick={HandleAudio}
                              className={Styles.SendAudio}
                            >
                              send
                              {/* <AiOutlineSend /> */}
                            </span>
                          </div>
                        )}
                        {!stop && (
                          <div>
                            {loading || isRecording ? (
                              <button
                                className="chatSubmitButton"
                                disabled
                                onClick={handelSubmitButton}
                              >
                                <div className={Styles.loader}>
                                  <RotatingLines
                                    strokeColor="#f16f1d"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="20"
                                    visible={true}
                                  />
                                </div>
                              </button>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  className="chatSubmitButton"
                                  onClick={handelSubmitButton}
                                >
                                  Send
                                </button>
                                <button
                                  type="submit"
                                  style={{ display: "none" }}
                                  className="chatSubmitButton"
                                  onClick={handelSubmitButton}
                                >
                                  Send
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </form>
                  ) : (
                    <div className={Styles.ForwardPanel}>
                      {" "}
                      <>
                        <div>
                          {" "}
                          <div>Forward Message</div>
                          {/* <div>
                            <IoArrowRedoSharp />
                          </div> */}
                        </div>
                        <div
                          className={Styles.CrossMarkCancel}
                          onClick={() => CancelForward()}
                        >
                          X
                        </div>
                      </>
                    </div>
                  )}
                </>
              ) : (
                <span className={Styles.noConversationText}>
                  <p style={{ color: "#f16f1d" }}>
                    Open a conversation to start a chat.
                  </p>
                </span>
              )}
            </div>
          </aside>
        </article>
      </section>
    </>
  );
};

export default AllChatComp;
