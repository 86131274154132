import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "../Branding/brand.module.css";
import Styles from "./_UserToUser.module.css";
import Style from "./Chat/Modal.module.css";
import { FaSearchMinus, FaSearchPlus } from "react-icons/fa";
import { Button, Modal } from "rsuite";
import AxiosInstance from "../../api/AxiosInstance";
import { SocketContext, socket } from "../../ioContext/socketIo";
import handleError from "../Users/ErrorHandler";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";

import { toast } from "react-toastify";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Modal as MUIModal,
  TextField,
  Button as MUIButton,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import NewRequirementDetail from "./NewRequirementDetail";
import fetchImageUrl from "../functions/FetchFileWithToken";
import useDownloader from "react-use-downloader";

const styleModal1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95vw",
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
  maxHeight: "95vh",
  paddingBottom: "5px",
};

const styleModal2 = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  minHeight: "400px",
  maxHeight: "85vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
};

const styleModal3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  minHeight: "40vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
  maxHeight: "95vh",
  overFlow: "auto",
};

const styleModal4 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
};

const BrandingMessage = () => {
  const { download } = useDownloader();

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const navigate = useNavigate();

  const socket1 = useContext(SocketContext);

  const [selectedFilter, setSelectedFilter] = useState("");

  const handleChangeFilter = event => {
    setSelectedFilter(event.target.value);
  };

  const imgRef = useRef(null);
  let scrollRef = useRef();

  const [zoomLevel, setZoomLevel] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseLeave = () => {
    setDragging(false);
  };

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };
  const handleMouseDown = e => {
    if (zoomLevel > 1) {
      setDragging(true);
      setStartX(e.pageX - offsetX);
      setStartY(e.pageY - offsetY);
    }
  };

  const handleMouseMove = e => {
    if (dragging) {
      e.preventDefault();
      const x = e.pageX - startX;
      const y = e.pageY - startY;
      setOffsetX(x);
      setOffsetY(y);
    }
  };

  const [messages, setMessage] = useState([]);
  const [loading, setLoading] = useState(false);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    socket1.on("brandmsg", data => {
      setMessage(prevMessages => [...prevMessages, data]);
    });
  }, [socket1]);
  let [noti, setNoti] = useState(false);
  useEffect(() => {
    socket1.on("requirement", data => {
      if (data) {
        setNoti(true);
      }
      setMessage(prevMessages => [...prevMessages, data]);
    });
  }, [socket1]);

  useEffect(() => {
    socket1.emit("chatwithbrand", {
      senderId: user?._id,
      role: "student",
      receiverId: "branding",
    });
  }, []);

  const [openImg, setOpenImg] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  let handleImage = e => {
    setOpenImg(true);
    openImagePreview(e);
  };

  const openImagePreview = imageSrc => {
    setPreviewImage(imageSrc);
  };

  // requirement part
  const [reqSelected, setReqSelected] = useState(null);
  const [openReqModal, setOpenReqModal] = useState(false);
  const handleOpenReqModal = () => setOpenReqModal(true);
  const handleCloseReqModal = () => {
    setOpenReqModal(false);
    setReqSelected(null);
  };

  useEffect(() => {
    const fetchAndDecryptData = async () => {
      setLoading(true);
      const filter = selectedFilter === "" ? "" : `?filter=${selectedFilter}`;
      try {
        const response = await AxiosInstance.get(
          `/brand/getstudentmsgBrand${filter}`,
          {
            headers: {
              Authorization: `Bearer ${user?.TOKEN}`,
            },
          }
        );
        setLoading(false);

        const { brandmsg } = response.data.student;

        const reversedMessage = brandmsg.reverse();
        setMessage(reversedMessage);
      } catch (error) {
        if (error?.response?.status === 403) {
          handleError(navigate, socket);
        }
        setLoading(false);
        console.error("Error fetching and decrypting data:", error);
      }
    };

    fetchAndDecryptData();
  }, [selectedFilter, noti, openReqModal]);

  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    const fetchUrls = async () => {
      const newFileUrls = {};

      await Promise.all(
        messages.map(async message => {
          if (message?.messages.file) {
            const fileData = await fetchImageUrl(
              message?.messages?.file?.path,
              user?.TOKEN
            );
            if (fileData) {
              newFileUrls[message?.messages?.file?.path] = fileData;
            }
          }
        })
      );

      setImageUrls(prevUrls => ({ ...prevUrls, ...newFileUrls }));
    };

    fetchUrls();
  }, [messages]);

  //? contact person changes and new feature starts here for message forwarding
  const btnColor = [
    "#9FE2BF",
    "#FF9F9F",
    "#FFD39F",
    "#9F9BFF",
    "#008080",
    "#008000",
  ];

  const [fetchUsersList, setFetchUsersList] = useState([]);

  const fetchUsers = async ele => {
    setFetchUsersList([]);
    setLoading1(true);

    try {
      const data = await AxiosInstance.get(`brand/getUsers/${ele}`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      });
      setLoading1(false);

      if (data?.status === 204) {
        toast.warn("No user Found");
      } else if (data?.status === 200) {
        setFetchUsersList(data.data.allUsers);
      }
    } catch (error) {
      setLoading1(false);

      if (error?.response?.status === 403) {
        handleError(navigate, socket);
      }
    }
  };

  // autocomplete for get users
  const [open1, setOpen1] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [inputValue1, setInputValue1] = useState("");
  const [selectedId1, setSelectedId1] = useState([]);

  const fetchOptions1 = async searchTerm => {
    try {
      setLoading1(true);
      const response = await AxiosInstance.get(
        `/education/collegemini/?name=${searchTerm}`,
        {
          headers: {
            Authorization: `Token ${user?.TOKEN}`,
          },
        }
      );
      const data = response.data.results;

      // Ensure data is an array
      if (Array.isArray(data)) {
        setFetchUsersList(data);
      } else {
        setFetchUsersList([]);
      }

      setLoading1(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange1 = (event, newInputValue) => {
    setInputValue1(newInputValue);
  };

  const handleOptionSelect1 = (_, newValue) => {
    setSelectedId1(newValue);
  };

  const [selectedMessage, setSelectedMessage] = useState(null);

  const handleClickButtonContact = (ele, message) => {
    setSelectedMessage(message);

    fetchUsers(ele);
    setTimeout(() => {
      handleOpenModalForward();
    }, 100);
  };

  const [loadButton, setLoadButton] = useState(false);

  const [newMessage, setNewMessage] = useState("");
  const [file, setFile] = useState(null);

  const [imagePreview, setImagePreview] = useState(null);

  const handlePreview = e => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile && selectedFile.type.startsWith("image")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setImagePreview(null);
    }
  };

  const handleDelete = () => {
    setFile(null);
    setImagePreview(null);
  };

  const handleForwardSubmit = async () => {
    setLoadButton(true);

    try {
      let payload = {
        file,
        sender: user._id,
      };
      let filesData = null;
      const options = {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
          "Content-Type": "multipart/form-data",
        },
      };
      if (file !== null) {
        const res = await AxiosInstance.post(
          "/chat/newmsgfiles",
          payload,
          options
        );
        filesData = res.data.files;
      }

      if (socket1) {
        selectedId1.forEach(val => {
          socket1.emit(
            "sendMessage",
            {
              senderId: user._id,
              receiverId: val?._id,
              files: filesData ? filesData : null,
              text: newMessage,
              role: user?.role1,
              code: "",
              type: "batch",
              device: "web",
              poll: null,
              replayback: {
                message: selectedMessage?.messages?.text
                  ? selectedMessage?.messages?.text
                  : "",
                file: selectedMessage?.messages?.file
                  ? [selectedMessage?.messages?.file]
                  : "",
              },
            },
            function callback(msg) {
              setSelectedMessage(null);
            }
          );
        });
        toast.success("message sent successfully");

        setLoadButton(false);
        handleCloseModalForward();
        setNewMessage("");
        setSelectedId1([]);
        setImagePreview(null);
      }
    } catch (error) {
      console.log(error);
      setLoadButton(false);
      if (error?.response?.status === 403) {
        handleError(navigate, socket);
      }
    }
  };

  // play video part
  const [videoURL, setVideoURL] = useState(null);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const handleOpenVideoModal = url => {
    setVideoURL(url);
    setOpenVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setVideoURL(null);
    setOpenVideoModal(false);
  };

  // modal part for message forwarding
  const [openModalForward, setOpenModalForward] = useState(false);
  const handleOpenModalForward = () => setOpenModalForward(true);
  const handleCloseModalForward = () => {
    setNewMessage("");
    setSelectedId1([]);
    setOpenModalForward(false);
  };

  // modal part for Rejected requirement message
  const [openModalReject, setOpenModalReject] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const handleOpenModalReject = () => setOpenModalReject(true);
  const handleCloseModalReject = () => {
    setOpenModalReject(false);
  };
  // requirement error message
  const [openReqErrorModal, setOpenReqErrorModal] = useState(false);

  const [disableAcceptBtn, setDisableAcceptBtn] = useState([]);

  const handleDisplayAcceptBtn = id => {
    setDisableAcceptBtn(prev => {
      if (!prev.includes(id)) {
        return [...prev, id];
      }
      return prev;
    });
  };

  return (
    <div className={styles.chatContainer} style={{ height: "92vh" }}>
      <div className={styles.spideyHead}>
        <h5 className={styles.spideyHeading}>Spidey Aura</h5>
        <div className={styles.filterBlock}>
          <h5 className={styles.filterLine}>Filter :</h5>{" "}
          <RadioGroup row value={selectedFilter} onChange={handleChangeFilter}>
            <FormControlLabel
              value=""
              control={<Radio size="small" color="warning" />}
              label="All"
            />
            <FormControlLabel
              value="branding"
              control={<Radio size="small" color="warning" />}
              label="Spidey"
            />
            <FormControlLabel
              value="requirement"
              control={<Radio size="small" color="warning" />}
              label="Requirement"
            />
          </RadioGroup>
        </div>
      </div>
      <Divider />
      {/* <button style={{ position: "absolute", bottom: "1%", right: "1%" }}>
        <svg width={35} height={35} viewBox="0 0 48 48">
          <path fill="#f57c00" d="M29 23H19L7 9h34z"></path>
          <path
            fill="#ff9800"
            d="m29 38l-10 6V23h10zM41.5 9h-35C5.7 9 5 8.3 5 7.5S5.7 6 6.5 6h35c.8 0 1.5.7 1.5 1.5S42.3 9 41.5 9"
          ></path>
        </svg>
      </button> */}
      {/* modal for message forwarding */}
      <MUIModal open={openModalForward} onClose={handleCloseModalForward}>
        <Box sx={styleModal2} position="relative">
          <button
            style={{
              border: "none",
              background: "none",
              position: "absolute",
              right: "5px",
              top: "5px",
              zIndex: "999",
            }}
            onClick={handleCloseModalForward}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <h5 style={{ textAlign: "center", color: "#ff7c2a" }}>
            Select Users to Forward Message
          </h5>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <MUIButton
              type="button"
              variant="contained"
              size="small"
              color="info"
              onClick={() => {
                setSelectedId1([]);
                setNewMessage("");
                handleCloseModalForward();
              }}
              disabled={loadButton}
            >
              Cancel
            </MUIButton>
            <MUIButton
              type="button"
              variant="contained"
              size="small"
              color="warning"
              disabled={loadButton}
              onClick={() => {
                if (newMessage.trim() === "" && file === null) {
                  toast.warn("Please type the message or select the file");
                } else if (selectedId1.length < 1) {
                  toast.warn(
                    "Please select atleast one user to forward message"
                  );
                } else {
                  handleForwardSubmit();
                }
              }}
            >
              Forward
            </MUIButton>
          </div>
          <br />
          <Grid
            spacing={2}
            container
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item sm={9}>
              <TextField
                name="message"
                label="Message"
                variant="outlined"
                size="small"
                multiline
                rows={2}
                fullWidth
                color="warning"
                placeholder="Type your Message here...."
                value={newMessage}
                onChange={e => {
                  setNewMessage(e.target.value);
                }}
              />
            </Grid>
            {/* <Grid
              item
              sm={9}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <MUIButton
                size="small"
                variant="outlined"
                component="label"
                className={Style.fileUpload}
                color="success"
              >
                Choose File
                <input
                  type="file"
                  name="file"
                  onChange={handlePreview}
                  hidden
                />
              </MUIButton>
            </Grid> */}
            {file && (
              <Grid item sm={12} className={Style.previewContainer}>
                {imagePreview ? (
                  <div className={Style.imagePreviewContainer}>
                    <img
                      src={imagePreview}
                      alt="Preview"
                      className={Style.imagePreview}
                      width={100}
                      height={100}
                    />
                    <span
                      className={Style.deleteButton}
                      onClick={handleDelete}
                      style={{ color: "crimson", fontSize: "medium" }}
                    >
                      &#10006;
                    </span>
                  </div>
                ) : (
                  <div
                    className={Style.filePreview}
                    style={{ position: "relative" }}
                  >
                    <div className={Style.fileIcon}>📄</div>
                    <div className={Style.fileName}>{file.name}</div>
                    <span
                      className={Style.deleteButton}
                      onClick={handleDelete}
                      style={{ color: "crimson", fontSize: "medium" }}
                    >
                      &#10006;
                    </span>
                  </div>
                )}
              </Grid>
            )}
            <Grid item sm={9}>
              <Autocomplete
                fullWidth
                size="small"
                open={open1}
                onOpen={() => {
                  setOpen1(true);
                }}
                onClose={() => {
                  setOpen1(false);
                }}
                color="warning"
                multiple
                options={fetchUsersList}
                loading={loading1}
                inputValue={inputValue1}
                value={selectedId1}
                limitTags={4}
                getOptionLabel={option => {
                  const branches = option?.branch?.join(", ") || "";
                  return `${option.username} - ${option?.role[0]} - ${branches}`;
                }}
                onChange={handleOptionSelect1}
                onInputChange={handleInputChange1}
                renderInput={params => (
                  <TextField
                    {...params}
                    color="warning"
                    label="Select Users"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading1 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      borderBottom: "1px solid #ccc",
                      padding: "8px 16px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          color: "#f57c00",
                        }}
                      >
                        {option.username} {" - "}
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "small",
                            color: "gray",
                          }}
                        >
                          {option?.role[0]}
                        </span>
                      </span>

                      {/* <span style={{ fontSize: "small" }}>
                        {option?.branch?.join(", ")}
                      </span> */}
                    </div>
                  </li>
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </MUIModal>

      {/* modal for video play */}
      <MUIModal open={openVideoModal}>
        <Box sx={styleModal1} position="relative">
          <button
            style={{
              border: "none",
              background: "none",
              position: "absolute",
              right: "5px",
              top: "5px",
              zIndex: "999",
            }}
            onClick={handleCloseVideoModal}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {videoURL && (
                <video
                  src={videoURL}
                  controls
                  style={{
                    width: "100%",
                    // height: "auto",
                    maxHeight: "93vh",
                    borderRadius: "5px",
                  }}
                ></video>
              )}
            </Grid>
          </Grid>
        </Box>
      </MUIModal>

      {/* Modal for HR Rejected requirement */}
      <MUIModal open={openModalReject}>
        <Box sx={{ ...styleModal3, width: "60%" }} position="relative">
          <button
            style={{
              border: "none",
              background: "none",
              position: "absolute",
              right: "2px",
              top: "2px",
              zIndex: "999",
            }}
            onClick={handleCloseModalReject}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <section>
            <h4
              style={{
                textAlign: "center",
                borderBottom: "2px solid",
                color: "crimson",
              }}
            >
              Reason for hr Rejection
            </h4>
            <ul>
              {reqSelected &&
                reqSelected?.hr_reject_status !== null &&
                reqSelected?.hr_reject_status?.name && (
                  <li style={{ marginLeft: "20px" }}>
                    {" "}
                    <h5
                      style={{
                        // color: "crimson",
                        marginTop: "20px",
                      }}
                    >
                      {reqSelected?.hr_reject_status?.name}
                    </h5>
                  </li>
                )}
              {reqSelected &&
                reqSelected?.hr_reject_status !== null &&
                reqSelected?.hr_reject_status?.comment &&
                reqSelected?.hr_reject_status?.comment !== "" && (
                  <li style={{ marginLeft: "20px" }}>
                    {" "}
                    <h5>{reqSelected?.hr_reject_status?.comment}</h5>
                  </li>
                )}
            </ul>
          </section>
        </Box>
      </MUIModal>
      {/* Modal for requirement */}
      <MUIModal open={openReqModal}>
        <Box sx={styleModal3} position="relative">
          <button
            style={{
              border: "none",
              background: "none",
              position: "absolute",
              right: "2px",
              top: "2px",
              zIndex: "999",
            }}
            onClick={handleCloseReqModal}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          {reqSelected === null ? (
            "Loading..."
          ) : (
            <NewRequirementDetail
              reqSelected={reqSelected}
              disableAcceptBtn={disableAcceptBtn}
              handleDisplayAcceptBtn={handleDisplayAcceptBtn}
              handleCloseReqModal={handleCloseReqModal}
              setOpenReqModal={setOpenReqModal}
            />
          )}
        </Box>
      </MUIModal>

      <MUIModal
        open={openReqErrorModal}
        onClose={() => setOpenReqErrorModal(false)}
      >
        <Box sx={styleModal4} position="relative">
          <button
            style={{
              border: "none",
              background: "none",
              position: "absolute",
              right: "2px",
              top: "2px",
              zIndex: "999",
            }}
            onClick={() => setOpenReqErrorModal(false)}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <br />
          <p
            style={{
              textAlign: "center",
              color: "crimson",
              padding: "3px",
              fontWeight: "bold",
              fontSize: "large",
            }}
          >
            You have rejected the requirement, So you can't able to view the
            requirement details
          </p>
        </Box>
      </MUIModal>

      <Modal onClose={() => setOpenImg(false)} open={openImg} size="full">
        <Modal.Body>
          <div
            style={{
              overflow: "hidden",
              cursor: zoomLevel > 1 ? "grab" : "auto",
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
          >
            <img
              ref={imgRef}
              src={previewImage}
              style={{
                transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
                transition: "transform 0.3s ease",
                maxWidth: "700px",
                maxHeight: "80%",
                width: "80%",
                margin: "auto",
                display: "block",

                // objectFit: "cover",
              }}
              alt="Preview"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleZoomOut}>
            <FaSearchMinus />
          </Button>
          <Button variant="secondary" onClick={handleZoomIn}>
            <FaSearchPlus />
          </Button>
          <Button variant="primary" onClick={() => setOpenImg(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <aside
        ref={scrollRef}
        id="scrollableDiv"
        style={{
          width: "100%",
          height: "94%",
          overflow: "auto",
          transition: " all 0.2s ease-in-out",
        }}
      >
        {loading === null ? (
          <div
            style={{
              display: "flex",
              height: "70vh",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              color: "#f16f1d",
              fontSize: "x-large",
            }}
          >
            something went wrong
          </div>
        ) : loading ? (
          <div
            style={{
              display: "flex",
              height: "70vh",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              color: "#f16f1d",
              fontSize: "x-large",
            }}
          >
            Loading...
          </div>
        ) : (
          <>
            {messages.length < 1 ? (
              <div
                style={{
                  display: "flex",
                  height: "70vh",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#f16f1d",
                  fontSize: "x-large",
                }}
              >
                No Message
              </div>
            ) : (
              messages.map((message, index) => {
                const contact = message?.contactPerson
                  ? message?.contactPerson.split(",")
                  : [];
                return (
                  <div
                    className={styles.chatCard}
                    style={{
                      borderRadius: "0px 15px 15px 15px",
                      border: message.requirement ? "2px dashed #008080" : "",
                      float: message.requirement ? "right" : "",
                      cursor:
                        message.requirement &&
                        message.req_accept_status === false
                          ? "pointer"
                          : "",
                    }}
                    key={index}
                    onClick={() => {
                      if (
                        message.requirement &&
                        message.req_accept_status === false
                      ) {
                        setOpenReqErrorModal(true);
                      }
                    }}
                  >
                    {message?.messages?.file && (
                      <div style={{ paddingBottom: "3px" }}>
                        {(() => {
                          switch (message?.messages?.file?.mimetype) {
                            case "image/jpg":
                            case "image/jpeg":
                            case "image/avif":
                            case "image/png":
                            case "image/webp":
                            case "image/tiff":
                            case "image/bmp":
                              return (
                                <>
                                  <img
                                    src={
                                      imageUrls[message?.messages?.file?.path]
                                        ?.url
                                    }
                                    alt=""
                                    style={{
                                      height: "150px",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleImage(
                                        imageUrls[message?.messages?.file?.path]
                                          ?.url
                                      )
                                    }
                                  />
                              
                                </>
                              );
                            case "audio/mpeg":
                            case "audio/mp3":
                            case "audio/webm":
                            case "application/wav":
                            case "video/webm":
                            case "audio/wav":
                              return (
                                <audio
                                  src={
                                    imageUrls[message?.messages?.file?.path]
                                      ?.url
                                  }
                                  controls
                                  style={{ width: "250px", height: "40px" }}
                                />
                              );
                            case "video/mp4":
                            case "video/x-matroska":
                            case "video/ogg":
                            case "video/avi":
                            case "video/mpeg":
                            case "video/quicktime":
                            case "video/x-msvideo":
                              return (
                                <div className={styles.videoContainer}>
                                  <video
                                    src={
                                      imageUrls[message?.messages?.file?.path]
                                        ?.url
                                    }
                                    style={{
                                      maxWidth: "400px",
                                      height: "auto",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleOpenVideoModal(
                                        imageUrls[message?.messages?.file?.path]
                                          ?.url
                                      )
                                    }
                                  />
                                  <button
                                    title="Play Video"
                                    onClick={() =>
                                      handleOpenVideoModal(
                                        imageUrls[message?.messages?.file?.path]
                                          ?.url
                                      )
                                    }
                                  >
                                    <svg
                                      width={40}
                                      height={40}
                                      viewBox="0 0 256 256"
                                    >
                                      <path
                                        fill="#f16f1d"
                                        d="M240 128a15.74 15.74 0 0 1-7.6 13.51L88.32 229.65a16 16 0 0 1-16.2.3A15.86 15.86 0 0 1 64 216.13V39.87a15.86 15.86 0 0 1 8.12-13.82a16 16 0 0 1 16.2.3l144.08 88.14A15.74 15.74 0 0 1 240 128"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              );
                            case "application/docx":
                            case "application/doc":
                            case "application/msword":
                            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                            case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
                            case "application/vnd.ms-word.document.macroEnabled.12":
                            case "application/vnd.ms-excel":
                            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                            case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
                            case "application/vnd.ms-excel.sheet.macroEnabled.12":
                            case "application/vnd.ms-powerpoint":
                            case "application/xlsx":
                            case "application/pptx":
                            case "application/octet-stream":
                            case "text/plain":
                            case "text/html":
                            case "application/apk":
                            case "application/pdf":
                            case "application/x-pdf":
                            case "application/x-gzpdf":
                            case "applications/vnd.pdf":
                            case "application/acrobat":
                            case "application/x-google-chrome-pdf":
                            case "text/pdf":
                            case "text/x-pdf":
                            case "text/csv":
                            case "application/zip":
                            case "application/x-zip":
                            case "application/octet-stream":
                            case "application/x-zip-compressed":
                            case "application/json":
                              return (
                                <>
                                  {/* <iframe
                                  src={
                                    imageUrls[message?.messages?.file?.path]
                                      ?.url
                                  }
                                  title="Document Preview"
                                  width="100%"
                                  height="300px"
                                  style={{ border: "none" }}
                                /> */}
                                  <button
                                    className={Styles.btndownload1}
                                    onClick={() =>
                                      download(
                                        imageUrls[message?.messages?.file?.path]
                                          ?.url,
                                        message?.messages?.file?.originalname,
                                        message?.messages?.file?.filename
                                      )
                                    }
                                  >
                                    {message?.messages?.file?.originalname}
                                    <span className={Styles.Download}></span>
                                    <span className={Styles.linkBlock}>
                                      Click to download
                                    </span>
                                  </button>
                                </>
                              );
                            default:
                              return (
                                <button className={Styles.btndownload1}>
                                  {message?.messages.file?.originalname}
                                  <span className={Styles.Download}></span>
                                </button>
                              );
                          }
                        })()}
                      </div>
                    )}
                    {message.messages?.text && (
                      <pre
                        className={styles.chatText}
                        style={{
                          color: "#111",
                          fontFamily: "sans-serif",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {message?.messages?.text}
                      </pre>
                    )}
                    {/* {message.senderName && (
                      <p className={styles.senderId}>
                        Sender: {message.senderName}
                      </p>
                    )} */}
                    {contact.length === 0 ? null : (
                      <div className={styles.roleBtnContainer}>
                        Contact :
                        {contact.map((ele, ind) => {
                          return (
                            <button
                              onClick={() =>
                                handleClickButtonContact(ele, message)
                              }
                              style={{ background: `${btnColor[ind]}` }}
                              key={ind}
                            >
                              {ele}
                            </button>
                          );
                        })}
                      </div>
                    )}
                    {message.requirement &&
                    message.req_accept_status &&
                    message.hr_reject_status === null ? (
                      <button
                        style={{
                          float: "right",
                          background: "#008080",
                          color: "#fff",
                        }}
                        className={styles.viewReqBtn}
                        onClick={() => {
                          setReqSelected(message);
                          setTimeout(() => {
                            handleOpenReqModal();
                          }, 50);
                        }}
                      >
                        View Requirement
                      </button>
                    ) : message.requirement &&
                      message.hr_reject_status &&
                      message.hr_reject_status !== null ? (
                      <button
                        style={{
                          float: "right",
                          background: "#008080",
                          color: "#fff",
                        }}
                        className={styles.viewReqBtn}
                        onClick={e => {
                          handleOpenModalReject();
                          setSelectedData(e);
                          setReqSelected(message);
                        }}
                      >
                        Rejected
                      </button>
                    ) : null}
                    <small style={{ color: "gray" }}>
                      {Date.now ? (
                        <Moment format="MMM DD - h:mm a">
                          {message?.createdAt}
                        </Moment>
                      ) : (
                        <Moment format="MMM DD YYYY h A">
                          {message?.createdAt}
                        </Moment>
                      )}
                    </small>
                  </div>
                );
              })
            )}
          </>
        )}
      </aside>
    </div>
  );
};

export default BrandingMessage;
