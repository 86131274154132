import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Styles from "./table.module.css";
import styles from "../auth/_auth.module.css";
import ReactPaginate from "react-paginate";
import AxiosInstance from "../../api/AxiosInstance";
import { FiSearch } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import { Modal } from "rsuite";
import Select from "react-select";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
// import { Button, ButtonToolbar } from "rsuite";
// let PageSize = 10;

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#D35400",
    fontWeight: "bold",
    fontSize: window.innerWidth > 1600 ? 16 : 14,
    backgroundColor: "#bbbaba",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: window.innerWidth > 1600 ? 14 : 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

const BlockedStudent = () => {
  const [page, setPage] = useState(1);
  let [itmes, setItems] = useState([]);
  // let [filter, setFilter] = useState([]);
  let [pag, setPag] = useState("");
  let [username, setUsername] = useState("");
  let [userName, setUserName] = useState("");

  let [status, setStatus] = useState([]);
  let [msg, setMsg] = useState("");
  let [loading, setLoading] = useState(false);
  const [openCourse, setopenCourse] = useState(false);
  let [reasons, setReasons] = useState("");

  let [reasonsList, setReasonsList] = useState([]);
  // let [reasonsListUnblock, setReasonsListUnblock] = useState([]);
  const [errors, setErrors] = useState({});

  let [selectedId, setSelectedId] = useState("");
  let { userAdmin } = useSelector(state => state.auth);
  let [searchTrue, setSearchTrue] = useState(false);
  //validation
  const validate = () => {
    let errors = {};
    if (!reasons) {
      errors.reasons = "Reason field is required";
    }
    return errors;
  };
  let handleUnBlockreasons = () => {
    async function fetchData(e) {
      let resondata = await AxiosInstance.get(
        `auth/getdata?data=unblock_reasons
    `,
        {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }
      );
      // //(Coursedata);
      // //(resondata);
      let Coursedataaa = resondata?.data[0].unblock_reasons;
      //(Coursedataaa);
      // //(Coursedataaa);
      let eee = Coursedataaa.map(function (ele) {
        return { value: ele, label: ele };
      });
      setReasonsList(eee);
    }
    fetchData();
  };

  function closeCourse() {
    setopenCourse(false);
  }

  let handleReason = e => {
    if (e?.value) {
      setReasons(e.value);
    } else {
      setReasons("");
    }
  };

  useEffect(() => {
    async function fetchlist() {
      setLoading(true);
      await AxiosInstance.get(
        `/admin/get_blockedstudents?page=${+page}&search=${username}`,
        {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }
      )
        .then(data => {
          //(data);
          setLoading(false);
          //(data);
          let pagedata = data?.data?.result;
          let dataList = data?.data?.result?.results;
          let error = data.response?.status;
          setStatus(error);
          setItems(dataList);
          // setFilter(dataList);
          setPag(pagedata);
          // setName1(data?.data?.result?.results?.username);
        })
        .catch(x => {
          setLoading(false);
          let error = x?.response?.status;
          let message = x.response?.data?.result?.results;
          setStatus(error);
          setMsg(message);
        });
    }
    fetchlist();
  }, [page, setPage, username]);

  let handleSearch = e => {
    setUserName(e.target.value);
  };
  // //(username)
  const handlePageClick = event => {
    setPage(Number(event.selected + 1));
  };
  let PermanentBlock = async e => {
    //(e);
    //(e);
    const options = {
      headers: {
        Authorization: `Bearer ${userAdmin.TOKEN}`,
      },
    };
    let payload = { id: e, reasons };
    //(payload);
    if (reasons === "") {
      setErrors(validate(reasons));
    } else {
      await AxiosInstance.post(`/auth/userblock`, payload, options)
        .then(x => {
          //(x);
          //(x);
          toast.success(x.data.message, {
            position: "top-right",
          });
          window.location.assign("/admin-dashboard/admin/allstudents");
        })
        .catch(x => {
          //(x?.response?.data?.message);
          toast.error(x?.response?.data?.message, {
            position: "top-right",
          });
        });
    }
  };

  let UnBlockPermanentBlock = async e => {
    //(e);

    const options = {
      headers: {
        Authorization: `Bearer ${userAdmin.TOKEN}`,
      },
    };
    if (reasons === "") {
      setErrors(validate(reasons));
    } else {
      await AxiosInstance.post(`/auth/userunblock`, { id: e }, options)
        .then(x => {
          //(x);
          //(x);
          toast.success(x.data.message, {
            position: "top-right",
          });
          window.location.assign("/admin-dashboard/admin/BlockedStudent");
        })
        .catch(x => {
          //(x?.response?.data?.message);
          toast.error(x?.response?.data?.message, {
            position: "top-right",
          });
        });
    }
  };
  let HandleClickSearch = () => {
    if (userName.length > 0) {
      setSearchTrue(true);
      setUsername(userName);
    }
  };
  let HandleClearSearch = () => {
    setSearchTrue(false);
    setUsername("");
    setUserName("");
  };
  const handleKeyPressUser = e => {
    if (e.key === "Enter") {
      if (userName.trim() !== "") {
        // Trigger search if input is not empty
        HandleClickSearch();
      }
    }
  };
  const handleBackspaceUser = event => {
    if (event.key === "Backspace" && userName.length === 1) {
      setSearchTrue(false);
      setUsername("");
      setUserName("");
    }
  };
  return (
    <section>
      <Paper
        elevation={12}
        sx={{
          width: "98%",
          overflow: "hidden",
          margin: "0 auto",
          position: "relative",
          marginTop: "20px",
        }}
        className={Styles.studentListTable}
      >
        <Grid
          container
          spacing={1}
          className={Styles.filterBlock}
          alignItems="center"
        >
          {" "}
          <Grid item md={5}>
            <h4 style={{ color: "#fff", textAlign: "center" }}>
              Blocked Student List
            </h4>
          </Grid>
          <Grid item md={3.5}></Grid>
          <Grid item md={2} position="relative">
            <span className={Styles.searchIcon}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="#fff"
                stroke="#F8931F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </span>
            <input
              type="text"
              id="inputName"
              className={Styles.studentNameList}
              value={userName}
              placeholder="search student"
              name="userName"
              onChange={e => handleSearch(e)}
              onKeyPress={handleKeyPressUser}
              onKeyDown={handleBackspaceUser}
              style={{ fontWeight: "bold" }}
            />
          </Grid>
          <Grid item md={1}>
            {searchTrue === false ? (
              <Button
                size="small"
                type="button"
                color="warning"
                sx={{
                  background: "#F87000",
                  color: "#111",
                  fontWeight: "bold",
                  "&:hover": {
                    background: "#F87000",
                    color: "#444",
                  },
                }}
                variant="contained"
                onClick={HandleClickSearch}
              >
                Search
              </Button>
            ) : (
              <Button
                sx={{ fontWeight: "bold" }}
                size="small"
                type="button"
                color="error"
                variant="contained"
                onClick={HandleClearSearch}
              >
                Reset
              </Button>
            )}
          </Grid>
        </Grid>
        {/* table */}

        <TableContainer
          component={Paper}
          sx={{ height: "75vh", borderRadius: "6px" }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ borderCollapse: "collapse" }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell size="small">Id</StyledTableCell>
                <StyledTableCell align="center" size="small">
                  User Name
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Email ID
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Number
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Batches
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Groups
                </StyledTableCell>
                {userAdmin.role[0] === "corporate" ? (
                  <StyledTableCell className={Styles.Tableidr} size="small">
                    Permanent Block
                  </StyledTableCell>
                ) : null}
              </TableRow>
            </TableHead>
            {loading === true ? (
              <div className={Styles.loader}>
                <RotatingLines
                  strokeColor="#f16f1d"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true}
                />
              </div>
            ) : (
              <TableBody>
                {" "}
                {itmes
                  ?.sort((a, b) => a.permanentBlock - b.permanentBlock)
                  .map((ele, ind) => {
                    return (
                      <>
                        <StyledTableRow key={ind}>
                          <StyledTableCell align="center" size="small">
                            {" "}
                            {ele.dbbatchuserid}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {ele?.username}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {ele?.email[0]} <br />
                            {ele?.email[1]}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {ele?.number[0]} <br /> {ele?.number[1]}
                          </StyledTableCell>

                          <StyledTableCell align="center" size="small">
                            {ele?.batchCode?.length}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {ele?.groupCode?.length}
                          </StyledTableCell>
                          {userAdmin.role[0] === "corporate" ? (
                            <StyledTableCell align="center" size="small">
                              {ele.permanentBlock === true ? (
                                <button
                                  className={Styles.permanentUnBlock}
                                  onClick={e => {
                                    setSelectedId(ele);
                                    setopenCourse(true);
                                    handleUnBlockreasons();
                                  }}
                                >
                                  UnBlock
                                </button>
                              ) : (
                                <button
                                  className={Styles.permanentBlock}
                                  // onClick={OpenCourse}
                                  onClick={e => {
                                    // OpenCourse()
                                    setSelectedId(ele);
                                    setopenCourse(true);
                                  }}
                                >
                                  Block
                                </button>
                              )}
                              <Modal
                                size="lg"
                                // style={{ height: `100vh` }}
                                keyboard={false}
                                open={openCourse}
                                onClose={closeCourse}
                              >
                                {selectedId.permanentBlock === true ? (
                                  <>
                                    <Modal.Header>
                                      <Modal.Title>
                                        Add reason To UnBlock
                                      </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body
                                      style={{
                                        overflow: "unset",
                                        maxheight: "none",
                                      }}
                                    >
                                      {/* {selectedId.permanentBlock === true ? ( */}
                                      <>
                                        <div className={Styles.reasons}>
                                          <label htmlFor="reasons">
                                            {selectedId.username}
                                          </label>
                                          <div
                                            className={styles.dropdownforreason}
                                          >
                                            <Select
                                              name="reasons"
                                              options={reasonsList}
                                              onChange={handleReason}
                                            />
                                          </div>
                                          <p
                                            className={
                                              errors.reasons
                                                ? `${Styles.errrr}`
                                                : `${Styles.gapbtw}`
                                            }
                                          ></p>
                                          {errors.reasons && (
                                            <p className={Styles.errors}>
                                              {errors.reasons}
                                            </p>
                                          )}
                                        </div>
                                      </>
                                    </Modal.Body>
                                    <Modal.Footer>
                                      {" "}
                                      <button
                                        className={Styles.permanentBlock}
                                        onClick={e => {
                                          UnBlockPermanentBlock(selectedId._id);
                                        }}
                                      >
                                        UnBlock
                                      </button>
                                    </Modal.Footer>
                                  </>
                                ) : (
                                  <>
                                    <Modal.Header>
                                      <Modal.Title>
                                        Add reason to Permanent Block
                                      </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body
                                      style={{
                                        overflow: "unset",
                                        maxheight: "none",
                                      }}
                                    >
                                      <>
                                        <div className={Styles.reasons}>
                                          <label htmlFor="reasons">
                                            Name - {selectedId.username}
                                          </label>
                                          <div
                                            className={styles.dropdownforreason}
                                          >
                                            <Select
                                              // options={reasonsListUnblock}
                                              name="reasons"
                                              onChange={handleReason}
                                            />
                                          </div>
                                          <p
                                            className={
                                              errors.reasons
                                                ? `${Styles.errrr}`
                                                : `${Styles.gapbtw}`
                                            }
                                          ></p>
                                          {errors.reasons && (
                                            <p className={Styles.errors}>
                                              {errors.reasons}
                                            </p>
                                          )}
                                        </div>
                                      </>
                                    </Modal.Body>
                                    <Modal.Footer>
                                      {" "}
                                      <button
                                        className={Styles.permanentBlock}
                                        onClick={e => {
                                          PermanentBlock(selectedId._id);
                                        }}
                                      >
                                        Block
                                      </button>{" "}
                                    </Modal.Footer>
                                  </>
                                )}
                              </Modal>
                            </StyledTableCell>
                          ) : (
                            ""
                          )}
                        </StyledTableRow>
                      </>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Grid
          style={{
            width: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            margin: "0 auto",
            marginTop: "1%",
            marginBottom: "1%",
          }}
        >
          <div id={Styles.reactpaginate}>
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              pageRangeDisplayed={3}
              previousLabel="< previous"
              pageCount={pag?.pages}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </Grid>
      </Paper>
    </section>
  );
};

export default BlockedStudent;

// export default BlockedStudent
