import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Button as MUIButton,
  Modal as MUIModal,
} from "@mui/material";
import Styles from "../Student/_user.module.css";
import Select from "react-select";
import { IoMdAddCircle } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { BddtAxios, BddtFile } from "../../api/AxiosInstance";
import { socket } from "../../ioContext/socketIo";
import handleError from "../Users/ErrorHandler";
import { toast } from "react-toastify";
// style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  height: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: "6px",
  p: 4,
};
const styleModal2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 600,
  width: "90%", // Use percentage to make it responsive
  maxWidth: "600px", // Add max-width for large screens
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "2px solid #f57c00",
  maxheight: "85vh",
  overflow: "auto",
};

const AddInterviewQuestion = () => {
  const userToken = sessionStorage.getItem("token");
  const navigate = useNavigate();

  Cookies.set("hrst", userToken, { domain: ".qspiders.com" });
  //add interview Question modal
  const [isOpenModal, setIsOpenModal] = React.useState(true);

  const [questionAnswers, setQuestionAnswers] = useState([
    { question: "", answer: "", subject: "", comment: "", rounds: [] },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  let [subjects, setSubjects] = useState([]);
  let [companyList, setCompanyList] = useState([]);

  let [company, setCompany] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  let [page, setPage] = useState(1);
  const [disableBtn, setDisableBtn] = useState(false);
  const [file, setFile] = useState("");
  let [attendrounds, setAttendRounds] = useState([]);
  const [searchQueryCompany, setSearchQueryCompany] = useState("");
  let [stopLoadComp, setStopLoadComp] = useState(false);
  const [openAddQuestions, setOpenAddQuestions] = useState(false);

  // multiple add Question logic

  //! image Preview
  let [openModel, setOpenModel] = useState(false);
  let [imageAddress, setImageAddress] = useState("");

  // total rounds and attended rounds
  let [attend, setAttend] = useState([]);
  let [totalRound, setTotalRound] = useState([]);
  let [inteviewRound, setInterviewRound] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  let [extCompany, setExtCompany] = useState("");

  let handleMenuOpenRound = () => {
    async function fetchlist() {
      BddtAxios.get(`/familiar/interview-rounds/`)
        .then(x => {
          let dat = x?.data?.results;
          let eee = dat.map(function (ele) {
            return {
              value: ele.id,
              label: ele.name,
            };
          });
          setAttend(eee);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchlist();
  };

  const handleChangeBranch = e => {
    //(e);
    setInterviewRound(Array.isArray(e) ? e.map(option => option.value) : []);
    setSelectedOption(e);
  };

  const handleChangeTotalRounds = e => {
    setTotalRound(Array.isArray(e) ? e.map(option => option.value) : []);
    setSelectedOption(e);
  };

  const handleOpen = () => {
    setOpenModel(true);
  };

  let handleMouseEnter = async e => {
    e.preventDefault();
    // setImageAddress(currentQuestion[0].fields.questions[0].image);
  };

  const handleClose = () => {
    setOpenModel(false);
    setImageAddress("");
  };
  let handleRemoveImage = e => {
    setFile("");
  };

  const addDiv = () => {
    setQuestionAnswers([
      ...questionAnswers,
      { question: "", answer: "", subject: "", comment: "", rounds: [] },
    ]);
  };

  const deleteDiv = index => {
    const newQuestionAnswers = [...questionAnswers];
    newQuestionAnswers.splice(index, 1);
    setQuestionAnswers(newQuestionAnswers);
  };

  let HandleCancelForm = () => {
    setOpenAddQuestions(false);
    setIsOpenModal(false);
    setQuestionAnswers([
      { question: "", answer: "", subject: "", comment: "", rounds: [] },
    ]);
    setFile("");
    setIsOpenModal(false);
    navigate(-1);
  };
  let fetchIterviewRounds = async () => {
    async function fetchlist() {
      BddtAxios.get(`/familiar/interview-rounds/`)
        .then(x => {
          let dat = x?.data?.results;
          let eee = dat.map(function (ele) {
            return {
              value: ele.id,
              label: ele.name,
            };
          });
          setAttendRounds(eee);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchlist();
  };

  let handleFile = event => {
    const file = event.target.files[0];

    setFile(file);
  };

  const handleChangeQuestion = (e, index) => {
    const newQuestionAnswers = [...questionAnswers];
    newQuestionAnswers[index].question = e.target.value;
    setQuestionAnswers(newQuestionAnswers);
  };

  const handleChangeAnswer = (e, index) => {
    const newQuestionAnswers = [...questionAnswers];
    newQuestionAnswers[index].answer = e.target.value;
    setQuestionAnswers(newQuestionAnswers);
  };

  const handleChangeComment = (e, index) => {
    const newQuestionAnswers = [...questionAnswers];
    newQuestionAnswers[index].comment = e.target.value;
    setQuestionAnswers(newQuestionAnswers);
  };

  const handleChangeRounds = (e, index) => {
    const newQuestionAnswers = [...questionAnswers];
    newQuestionAnswers[index].rounds = Array.isArray(e)
      ? e.map(x => x.value)
      : [];

    setQuestionAnswers(newQuestionAnswers);
  };

  let onChangeselect = (e, index) => {
    const newQuestionAnswers = [...questionAnswers];
    newQuestionAnswers[index].subject = Array.isArray(e)
      ? e.map(x => x.value)
      : [];

    setQuestionAnswers(newQuestionAnswers);
  };

  let LoadSubject = () => {
    async function fetchData() {
      await BddtAxios.get(
        `/courses/subject/?token=${userToken}&name=${searchQuery}&page=${page}`
      )
        .then(x => {
          let Subjectdataaa = x?.data?.results;

          let ddd = Subjectdataaa.map(function (ele) {
            return { value: ele?.id, label: ele?.name };
          });
          setSubjects(ddd);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        })
        .finally(z => {
          setIsLoading(false);
        });
    }
    fetchData();
  };
  const handleInputChangeCompany = value => {
    setSearchQueryCompany(value);
  };
  useEffect(() => {
    if (searchQueryCompany?.length > 3) {
      LoadCompany();
    }
  }, [searchQueryCompany?.length > 3]);

  let onChangeselectCompany = e => {
    if (e) {
      setCompany(e.value);
    } else {
      setCompany(null);
    }
  };
  const handleMenuOpen = () => {
    if (subjects.length === 0 && !isLoading) {
      setIsLoading(true);
      LoadSubject();
    }
  };
  const handleInputChange = value => {
    setSearchQuery(value);
  };
  useEffect(() => {
    if (searchQuery?.length > 3) {
      LoadSubject();
    }
  }, [searchQuery?.length > 3]);

  let handleMenuScrollToBottom = () => {
    setPage(prevPage => prevPage + 1);

    if (stopLoadComp) {
    } else {
      LoadCompany();
    }
  };

  let LoadCompany = () => {
    async function fetchData() {
      await BddtAxios.get(
        `/familiar/companymin/?token=${userToken}&name=${searchQueryCompany}&page=${page}`
      )
        .then(x => {
          let Subjectdataaa = x?.data?.results;

          let ddd = Subjectdataaa.map(function (ele) {
            return { value: ele?.id, label: ele?.name };
          });
          if (ddd.length < 25) {
            setStopLoadComp(true);
          }
          if (page > 1) {
            let data = [...companyList, ...ddd];

            setCompanyList(data);
          } else {
            setCompanyList(ddd);
          }
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        })
        .finally(z => {
          setIsLoading(false);
        });
    }
    fetchData();
  };

  const handleMenuOpenCompany = () => {
    if (subjects.length === 0 && !isLoading) {
      setIsLoading(true);
      LoadCompany();
    }
  };

  //submit function
  let HandleAttended = async e => {
    e.preventDefault();

    const emptyQuestionExists = questionAnswers.some(
      qa => qa.question.trim() === ""
    );

    if (file === "" && company === null && extCompany === "") {
      toast.error("Add Company or external company");
    } else if (file && company === null && extCompany === "") {
      toast.error("Add Company or external company");
    } else if (file === "" && emptyQuestionExists) {
      toast.error("Add Question");
    } else if (file && emptyQuestionExists) {
      toast.error("Add Question or Title in Question Field");
    } else {
      setDisableBtn(true);
      const formData = new FormData();
      if (file !== "") {
        formData.append("interview_file", file);
      }
      formData.append("questions", JSON.stringify(questionAnswers));
      // formData.append("sid", selectedData.id);
      if (company !== null) {
        formData.append("cid", company);
      } else {
        formData.append("ext_company", extCompany);
      }

      formData.append("token", userToken);
      formData.append("total_rounds", totalRound);
      formData.append(
        "rounds_attend",
        inteviewRound ? inteviewRound : selectedOption
      );

      await BddtFile.post(`/hr/student-interview-ques/`, formData)
        .then(x => {
          toast.success("Successfully added Interview question");
          setFile("");
          setQuestionAnswers([
            {
              question: "",
              answer: "",
              subject: "",
              comment: "",
              rounds: [],
            },
          ]);

          setOpenAddQuestions(false);
          setDisableBtn(false);
          setIsOpenModal(false);
          navigate(-1);
        })
        .catch(x => {
          setDisableBtn(false);
          toast.error(
            x?.response?.data?.message
              ? x?.response?.data?.message
              : "Something went wrong"
          );
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
          navigate(-1);

          // toast.error("Not added");
        });
    }
  };
  let HandleCloseModal = () => {
    setIsOpenModal(false);
    navigate(-1);
  };
  return (
    <>
      {/* modal for image preview */}
      <MUIModal open={openModel} onClose={handleClose}>
        <Box sx={style}>
          {file === "" ? (
            ""
          ) : (
            <img
              loading="lazy"
              src={URL.createObjectURL(file)}
              alt=""
              height="100%"
              width="100%"
            />
          )}
        </Box>
      </MUIModal>
      {/* modal for image preview */}
      <MUIModal
        open={isOpenModal}
        // onClose={() => setIsOpenModal(false)}
        onClose={HandleCloseModal}
        closeAfterTransition
      >
        <Box sx={styleModal2}>
          <aside
            className={Styles.Heading}
            style={{
              color: "#f57c00",
              fontWeight: "bold",
              textAlign: "center",
              position: "relative",
              marginBottom: "5px",
            }}
          >
            Add Interview Questions
            <button
              style={{
                position: "absolute",
                right: "-10px",
                background: "transparent",
                top: "-10px",
              }}
              // onClick={() => }
              onClick={() => {
                setIsOpenModal(false);

                navigate(-1);
              }}
              title="Close"
            >
              <svg width={18.54} height={20} viewBox="0 0 1216 1312">
                <path
                  fill="crimson"
                  d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                ></path>
              </svg>
            </button>
          </aside>
          <aside style={{ maxHeight: "60vh", overflow: "auto" }}>
            <div className={Styles.inputBlock}>
              <header>
                {" "}
                <div className={Styles.trainer12}>
                  <h6 htmlFor="Selectattended">Interview Rounds</h6>
                  <aside>
                    <Select
                      id="Selectattended"
                      name="Selectattended"
                      onMenuOpen={handleMenuOpenRound}
                      options={attend}
                      // value={selectedOption}
                      isMulti
                      onChange={handleChangeTotalRounds}
                    />
                  </aside>
                </div>
                <div className={Styles.trainer12}>
                  <h6 htmlFor="Selectattended">Select Attended Round</h6>
                  <aside>
                    <Select
                      id="Selectattended"
                      name="Selectattended"
                      onMenuOpen={handleMenuOpenRound}
                      options={attend}
                      // value={selectedOption}
                      isMulti
                      onChange={handleChangeBranch}
                    />
                  </aside>
                </div>
                <aside>
                  <h6 htmlFor="rounds">Select Company</h6>
                  <Select
                    id="company"
                    onMenuOpen={handleMenuOpenCompany}
                    placeholder="Search Company"
                    isClearable
                    isSearchable
                    escapeClearsValue={true}
                    className={Styles.option}
                    options={companyList}
                    onInputChange={handleInputChangeCompany}
                    onChange={onChangeselectCompany}
                    onMenuScrollToBottom={handleMenuScrollToBottom}
                  />
                </aside>
                <div>
                  <h5
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    OR
                  </h5>
                </div>
                <aside id={Styles.externalComp}>
                  <h6 htmlFor="rounds">External Company</h6>
                  <input
                    type="text"
                    id="Extque"
                    style={{ padding: "9px", borderRadius: "5px" }}
                    placeholder="Enter external company"
                    // name={`input_${index}`}
                    value={extCompany}
                    onChange={e => setExtCompany(e.target.value)}
                  />
                </aside>
              </header>
            </div>
            <div>
              {" "}
              <h5>Questions</h5>
            </div>

            {questionAnswers?.map((qa, index) => (
              <div className={Styles.inputBlock} key={index}>
                <header>
                  <aside>
                    <h6 htmlFor="rounds">Select Rounds</h6>
                    <Select
                      onMenuOpen={fetchIterviewRounds}
                      placeholder="Select rounds"
                      id="rounds"
                      isMulti
                      className={Styles.option}
                      options={attendrounds}
                      onChange={e => handleChangeRounds(e, index)}
                    />
                  </aside>
                  <aside>
                    <h6 htmlFor="rounds">Select Subject</h6>
                    <Select
                      placeholder="Select Subject"
                      id="rounds"
                      onMenuOpen={handleMenuOpen}
                      isMulti
                      isLoading={isLoading}
                      isClearable
                      isSearchable
                      escapeClearsValue={true}
                      labelKey="name"
                      className={Styles.option}
                      options={subjects}
                      onInputChange={handleInputChange}
                      onChange={e => onChangeselect(e, index)}
                      onMenuScrollToBottom={handleMenuScrollToBottom}
                    />
                  </aside>
                  <label htmlFor="Quee">
                    <h6>Question</h6>
                  </label>
                  <textarea
                    id="Quee"
                    type="text"
                    placeholder="Question"
                    name={`input_${index}`}
                    value={qa?.question}
                    onChange={e => handleChangeQuestion(e, index)}
                  />
                  {questionAnswers.length > 1 && (
                    <span
                      className={Styles.deleteicon}
                      onClick={() => deleteDiv(index)}
                    >
                      <IoMdClose />
                    </span>
                  )}
                </header>
                <footer>
                  <aside htmlFor="Que">
                    <h6>Answer</h6>
                  </aside>
                  <textarea
                    name="Ques"
                    id="answer"
                    type="text"
                    placeholder="Answer"
                    value={qa?.answer}
                    onChange={e => handleChangeAnswer(e, index)}
                  />
                </footer>

                <div className={Styles.Comment}>
                  <h6 htmlFor="comment">Comment</h6>
                  <textarea
                    name="comment"
                    id="comment"
                    value={qa.comment}
                    placeholder="Comment"
                    onChange={e => handleChangeComment(e, index)}
                  ></textarea>
                </div>
              </div>
            ))}

            <span className={Styles.AddButton} onClick={addDiv}>
              <IoMdAddCircle />
            </span>
          </aside>
          <Grid item md={4} style={{ display: "flex" }}>
            {file === "" ? (
              <div style={{ marginTop: "8px" }}>
                <IconButton
                  color="white"
                  title="Upload File"
                  aria-label="upload picture"
                  component="label"
                  style={{
                    border: "2px solid",
                    borderRadius: "5px",
                    fontSize: "16px",
                    background: "rgb(245, 124, 0)",
                    fontWeight: "bold",
                    color: "#fff",
                  }}
                >
                  Upload File
                  <input
                    type="file"
                    onChange={handleFile}
                    // style={{ display: "none" }}
                    hidden
                  />
                </IconButton>
              </div>
            ) : (
              <Button
                title="Delete file"
                color="error"
                onClick={handleRemoveImage}
                style={{ fontSize: "25px" }}
              >
                <svg fill="crimson" height="1em" viewBox="0 0 448 512">
                  <path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
                </svg>
              </Button>
            )}

            {(file !== "" && file[0]?.mimetype === "image/jpeg") ||
              file[0]?.mimetype === "image/jpg" ||
              file[0]?.mimetype === "image/avif" ||
              (file[0]?.mimetype === "image/png" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    style={{
                      color: "#2677b0",
                      fontWeight: "bold",
                      marginLeft: "3%",
                    }}
                    onClick={handleOpen}
                    onMouseEnter={handleMouseEnter}
                    title="Preview File"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="#4394ea"
                        d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71l-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0a1.5 1.5 0 0 0 3 0"
                      />
                    </svg>{" "}
                    &nbsp;
                  </Button>
                  <br />
                </div>
              ))}
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {file && file.name.slice(file.name.length - 35, file.name.length)}
            </p>
          </Grid>
          <div
            style={{
              width: "80%",
              margin: "8px auto",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <MUIButton
              size="small"
              variant="contained"
              color="error"
              onClick={() => HandleCancelForm()}
            >
              Cancel
            </MUIButton>
            <MUIButton
              size="small"
              variant="contained"
              color="success"
              onClick={HandleAttended}
              disabled={disableBtn}
            >
              {disableBtn ? "In Progress" : "Submit"}
            </MUIButton>
          </div>
          <Divider />
        </Box>
      </MUIModal>
    </>
  );
};

export default AddInterviewQuestion;
