import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { store } from "./redux/store";
import "./global.css";
import { Provider } from "react-redux";
import { SocketContext, socket } from "./ioContext/socketIo";
import UsersContext from "./components/Users/UsersContext";
import { SocketProvider } from "./ioContext/SocketProvider";

createRoot(document.querySelector("#root")).render(
  <Provider store={store}>
    {/* <SocketContext.Provider value={socket}> */}
    <SocketProvider>
      <UsersContext>
        <App />
      </UsersContext>
    </SocketProvider>
    {/* </SocketContext.Provider> */}
  </Provider>
);
