import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  TablePagination,
  Pagination,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import fetchImageUrl from "../functions/FetchFileWithToken";
import { Modal } from "rsuite";
import Styles from "./_user.module.css";
import linkifyHtml from "linkify-html";
import AxiosInstance from "../../api/AxiosInstance";

const SharedMessage = () => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  //pagination
  const [page, setPage] = useState(1);
  const [scroll, setScroll] = useState(0);
  const rowsPerPage = 10;
  const [likeShareList, setLikeShareList] = useState([]);
  const [totalList, setTotalList] = useState(0);
  const handleChangePage = (event, value) => {
    setPage(value);
    const newScrollValue = (value - 1) * rowsPerPage;
    setScroll(newScrollValue);
    // handleScrollUpdate(newScrollValue);
  };

  // Calculate the total number of pages
  const pageCount = Math.ceil(totalList / rowsPerPage);

  let Token = sessionStorage.getItem("token");
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const handleOpenModal = type => {
    setSelected(type);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelected([]);
  };

  useEffect(() => {
    async function fetchData() {
      await AxiosInstance.get(
        `users/share_like_user_side?userid=${user?._id}&scroll=${scroll}`
      )
        .then(x => {
          setLikeShareList(x?.data?.posted_data);
          setTotalList(x?.data?.msgcount);
        })
        .catch(y => {
          console.log(y);
        });
    }
    fetchData();
  }, [page]);

  //Like-------------------------------
  const [openModalLike, setOpenModalLike] = useState(false);

  const handleOpenModalLike = type => {
    let data = type.student_action.filter(ele => {
      return ele.like > 0;
    });

    setSelected(data);
    setOpenModalLike(true);
  };
  const handleCloseModalLike = () => {
    setOpenModalLike(false);
    setSelected([]);
  };
  //Dislike-------------------------------
  const [openModalDislike, setOpenModalDislike] = useState(false);

  const handleOpenModalDislike = type => {
    let data = type.student_action.filter(ele => {
      return ele.dislike > 0;
    });

    setSelected(data);
    setOpenModalDislike(true);
  };
  const handleCloseModalDislike = () => {
    setOpenModalDislike(false);
    setSelected([]);
  };
  function linkifyHtmlContent(text) {
    // Process the text to convert URLs into clickable links
    const linkifyOptions = {
      defaultProtocol: "https",
      target: "_blank", // Open links in a new tab
      rel: "noopener noreferrer", // For security
    };

    return linkifyHtml(text, linkifyOptions);
  }

  //Share-------------------------------

  const [openModalShare, setOpenModalShare] = useState(false);

  const handleOpenModalShare = type => {
    let data = type.student_action.filter(ele => {
      return ele.share > 0;
    });

    setSelected(data);
    setOpenModalShare(true);
  };
  const handleCloseModalShare = () => {
    setOpenModalShare(false);
    setSelected([]);
  };
  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    const fetchUrls = async () => {
      console.log("kkkkkkkkk");
      const newFileUrls = {};

      await Promise.all(
        likeShareList &&
          likeShareList?.map(async message => {
            if (message?.message?.files) {
              const fileData = await fetchImageUrl(
                message?.message?.files[0]?.path,
                Token
              );
              if (fileData) {
                newFileUrls[message?.message?.files[0]?.path] = fileData;
              }
            }
          })
      );

      setImageUrls(prevUrls => ({ ...prevUrls, ...newFileUrls }));
    };

    fetchUrls();
  }, [likeShareList]);

  return (
    <div>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>
            <h6 className={Styles.AllBatchHead}> Total Number of Students</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selected?.student_action?.length > 0 ? (
            selected?.student_action?.map((name, index) => (
              <Typography key={name.username} variant="body2" gutterBottom>
                {name.username}
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No names available.
            </Typography>
          )}
        </Modal.Body>
      </Modal>
      {/* Modal for Likes displaying names */}
      <Modal open={openModalLike} onClose={handleCloseModalLike}>
        <Modal.Header>
          <Modal.Title>
            <h6 className={Styles.AllBatchHead}>Students liked message</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selected?.length > 0 ? (
            selected?.map((name, index) => (
              <Typography key={name.username} variant="body2" gutterBottom>
                {name.username}
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No names available.
            </Typography>
          )}
        </Modal.Body>
      </Modal>
      {/* Modal for Dislikes displaying names */}
      <Modal open={openModalDislike} onClose={handleCloseModalDislike}>
        <Modal.Header>
          <Modal.Title>
            <h6 className={Styles.AllBatchHead}>Students disliked message</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selected?.length > 0 ? (
            selected?.map((name, index) => (
              <Typography key={name.username} variant="body2" gutterBottom>
                {name.username}
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No names available.
            </Typography>
          )}
        </Modal.Body>
      </Modal>
      {/* Modal for shared displaying names */}
      <Modal open={openModalShare} onClose={handleCloseModalShare}>
        <Modal.Header>
          <Modal.Title>
            <h6 className={Styles.AllBatchHead}>Shared message student list</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {selected?.length > 0 ? (
            selected?.map((name, index) => (
              <Typography key={name.username} variant="body2" gutterBottom>
                {name.username} - <span>{name.share}</span>
              </Typography>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No names available.
            </Typography>
          )} */}
          <TableContainer
            component={Paper}
            sx={{ maxWidth: "400px", margin: "0 auto" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: "white", padding: "8px" }}>
                    Username
                  </TableCell>
                  <TableCell sx={{ color: "white", padding: "8px" }}>
                    Share
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selected?.length > 0 ? (
                  selected?.map((name, index) => (
                    <TableRow key={name.username}>
                      <TableCell>
                        <Typography variant="body2">{name.username}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{name.share}</Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      <Typography variant="body2" color="text.secondary">
                        No names available.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Modal.Body>
      </Modal>{" "}
      <Box>
        <Typography
          variant="h6"
          sx={{
            color: "#333",
            // mb: 2,
            display: "flex",
            justifyContent: "center",
            color: "rgb(245, 124, 0)",
          }}
        >
          Shared Message List
        </Typography>
      </Box>
      <TableContainer component={Paper} sx={{ maxHeight: "590px" }}>
        <Table stickyHeader aria-label="shared messages table">
          <TableHead>
            <TableRow sx={{ background: "gray" }}>
              <TableCell
                sx={{
                  color: "#D35400",
                  background: "#bbbaba",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                Message
              </TableCell>
              <TableCell
                sx={{
                  color: "#D35400",
                  background: "#bbbaba",
                  // color: "white",
                  // background: "#2d2d2d",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
              >
                Total Students
              </TableCell>
              <TableCell
                sx={{
                  color: "#D35400",
                  background: "#bbbaba",
                  // color: "white",
                  // background: "#2d2d2d",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
              >
                Like
              </TableCell>
              <TableCell
                sx={{
                  color: "#D35400",
                  background: "#bbbaba",
                  // color: "white",
                  // background: "#2d2d2d",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
              >
                Dislike
              </TableCell>
              <TableCell
                sx={{
                  color: "#D35400",
                  background: "#bbbaba",
                  // color: "white",
                  // background: "#2d2d2d",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
              >
                Share
              </TableCell>
            </TableRow>
          </TableHead>
          {console.log(likeShareList)}
          <TableBody>
            {likeShareList?.map((ele, ind) => (
              <TableRow key={ind}>
                <TableCell
                  style={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    maxWidth: "300px",
                    overflowX: "auto",
                  }}
                >
                  <Typography variant="body1">
                    {ele?.message?.files ? (
                      [
                        "image/jpg",
                        "image/avif",
                        "image/png",
                        "image/webp",
                        "image/tiff",
                        "image/bmp",
                        "image/jpeg",
                      ].includes(ele?.message?.files[0]?.mimetype) ? (
                        <>
                          <img
                            src={imageUrls[ele?.message?.files[0]?.path]?.url}
                            alt=""
                            style={{
                              height: "150px",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            // onClick={() =>
                            //   handleImage(
                            //     imageUrls[ele?.message?.files[0]?.path]?.url
                            //   )
                            // }
                          />
                        </>
                      ) : (
                        ele?.message?.files[0].filename
                      )
                    ) : ele?.message?.text ? (
                      ele?.message?.text && ele?.html ? (
                        <pre
                          dangerouslySetInnerHTML={{
                            __html: linkifyHtmlContent(
                              ele?.message?.text || ""
                            ),
                          }}
                          className={Styles.textEditStyle}
                        />
                      ) : (
                        ele?.message?.text?.slice(0, 85)
                      )
                    ) : (
                      ""
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="body2"
                    sx={{ color: "#333" }}
                    // onClick={() => handleOpenModal(ele)}
                  >
                    {ele?.total_students}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="body2"
                    sx={{ color: "#333", cursor: "pointer" }}
                    onClick={() => handleOpenModalLike(ele)}
                  >
                    {ele?.like}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="body2"
                    sx={{ color: "#333", cursor: "pointer" }}
                    onClick={() => handleOpenModalDislike(ele)}
                  >
                    {ele?.dislike}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="body2"
                    sx={{ color: "#333", cursor: "pointer" }}
                    onClick={() => handleOpenModalShare(ele)}
                  >
                    {ele?.share}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={pageCount} // Total number of pages
          page={page} // Current page
          onChange={handleChangePage} // Handle page change
          color="primary"
          shape="rounded"
        />
      </Box>
    </div>
  );
};

export default SharedMessage;
