import React, { useContext, useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { globalContext } from "../../Users/UsersContext";
import Styles from "./_chat.module.css";
import { BsBroadcast } from "react-icons/bs";
import { useSelector } from "react-redux";
import Select from "react-select";
import AxiosInstance from "../../../api/AxiosInstance";

const Conversation = ({
  batchStdent,
  setParticularStudent,

  allUsers,
  setBatchTrainerData,
  batchname,
  groupname,
  defaultvalueuser,
  SetNumber,
  number,
  SetNumber1,
  number1,
  batchCode,
  setBatchStdentData,
  groupCode,
}) => {
  let { userAdmin } = useSelector(state => state?.auth);
  let [search, setSearch] = useState("");
  let [usernames, setUserNames] = useState([]);
  // let [selecteduser, setSelectedUser] = useState("");
  // let [loadmore,SetLoadMore]= useState(false)
  let { switchComp, setSwitchComp } = useContext(globalContext);
  // //(
  //   batchStdent.filter((ele) => ele.username.toLowerCase().includes("v"))
  // );
  // let nametrainer = { value: defaultvalueuser, label: defaultvalueuser };
  //(batchStdent);
  // //(defaultvalueuser);

  // setDefaultvalueuser(allUsers?._id[0])
  // //(defaultvalueuser)
  useEffect(() => {
    async function fetchlist() {
      // setLoading(true);
      if (batchCode) {
        await AxiosInstance.get(
          `admin/batchList/${batchCode}/?search=${search}`,
          {
            headers: {
              Authorization: `Bearer ${userAdmin.TOKEN}`,
            },
          }
        )
          .then(data => {
            let student = data?.data?.studentData;

            // //(data?.data?.studentData
            //   )
            setBatchStdentData(student);
            // setLoading(false);
          })
          .catch(x => {
            // //(x)
          });
      } else {
        await AxiosInstance.get(
          `admin/groupList/${encodeURIComponent(groupCode)}/?search=${search}`,
          {
            headers: {
              Authorization: `Bearer ${userAdmin.TOKEN}`,
            },
          }
        )
          .then(data => {
            let student = data?.data?.studentData;

            //(student)
            // //(data?.data?.groupData)
            setBatchStdentData(student);
            // setLoading(false);
          })
          .catch(x => {});
      }
    }
    fetchlist();
  }, [search, userAdmin]);
  useEffect(() => {
    let aaa = allUsers.map(function (ele) {
      return { value: ele?._id, label: ele?.username + "-" + ele?.role };
    });
    setUserNames(aaa);
  }, [userAdmin._id, batchname, groupname]);

  let onChangeselect = e => {
    if (e?.value) {
      setBatchTrainerData(e?.value);
      setParticularStudent("");
    } else {
      // setSelectedUser(nametrainer);
    }
  };
  let Loadmore = () => {
    // SetLoadMore(true)
    SetNumber(number + 150);
    // SetNumber1(number1+150)
  };
  //(number)
  let Loadmoregroup = () => {
    // SetLoadMore(true)
    SetNumber1(number1 + 150);
  };
  // //(number1)
  // //(number1)

  return (
    <>
      <div className={Styles.Head}>
        <div className={Styles.drop}>
          <Select
            styles={{ width: "200px" }}
            labelKey="name"
            className={Styles.option}
            options={usernames}
            onChange={onChangeselect}
          />
        </div>
        {}
        {switchComp ? (
          <button
            onClick={() => setSwitchComp(false)}
            className={Styles.Broadcast}
          >
            <span>
              <BsBroadcast></BsBroadcast>
            </span>{" "}
            Broadcast
          </button>
        ) : (
          ""
        )}
      </div>
      <div></div>
      <div>
        <div className={Styles.chatMenuWrapper}>
          <span>
            <input
              type="text"
              placeholder="search student"
              name="search"
              id="search"
              onChange={e => setSearch(e.target.value)}
            />
          </span>
          <span className={Styles.searchIcon}>
            <FiSearch />
          </span>
          <div className={Styles.ConversationBlock}>
            <>
              {batchStdent?.length > 0 &&
                batchStdent
                  ?.filter(students =>
                    students?.username?.toLowerCase()?.includes(search)
                  )
                  .map((students, ind) => {
                    return (
                      <div
                        className={Styles.listUser}
                        key={ind}
                        onClick={() => {
                          setParticularStudent({
                            studentId: students?._id,
                            studentName: students?.username,
                          });
                        }}
                      >
                        <div className={Styles.conversation}>
                          <span>{students?.username}</span>
                        </div>
                      </div>
                    );
                  })}
            </>
          </div>
          <>
            {batchStdent?.length < 150 ? (
              ""
            ) : (
              <div className={Styles.loadmoreBlock}>
                {batchname ? (
                  <button onClick={Loadmore}>loadmore....</button>
                ) : (
                  <button onClick={Loadmoregroup}>loadmore....</button>
                )}
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default Conversation;
