import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import useDownloader from "react-use-downloader";
import AxiosInstance from "../../api/AxiosInstance";
import Styles from "./_UserToUser.module.css";
import { GiWideArrowDunk } from "react-icons/gi";
import Linkify from "react-linkify";
import linkifyHtml from "linkify-html";
import { RotatingLines } from "react-loader-spinner";
import { AiFillDelete } from "react-icons/ai";
import Moment from "react-moment";
import wavEncoder from "wav-encoder";

import {
  BsFillMicFill,
  BsFillMicMuteFill,
  BsFillPlayCircleFill,
} from "react-icons/bs";
import { Tooltip, Whisper, Modal } from "rsuite";
import { ButtonToolbar, Dropdown, IconButton } from "rsuite";
import { ImAttachment } from "react-icons/im";
import { IoIosShareAlt } from "react-icons/io";
import { socket } from "../../ioContext/socketIo";
import { useNavigate } from "react-router-dom";
import FileUpload from "./FileUpload";
import { FaSearchMinus, FaSearchPlus } from "react-icons/fa";
import handleError from "../Users/ErrorHandler";
import fetchImageUrl from "../functions/FetchFileWithToken";
import {
  Modal as MuiModal,
  Box,
  IconButton as MuiIconButton,
} from "@mui/material";
const StudentHelpChatCompNew = ({
  messagesNew,
  ticketTitle,
  setReply,
  reply,
  selectedMessages,
  onClick,
  setSelectedMessages,
  newTicketMsg,
}) => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const userToken = sessionStorage.getItem("token");

  let scrollRef = useRef();
  let navigate = useNavigate();

  // modal data ends here
  // ? state for creating new ticket
  const [pastedImage, setPastedImage] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [previewImage, setPreviewImage] = useState("");

  let [message, setMessage] = useState(messagesNew);

  const [arrivalMessage, setArrivalMessage] = useState([]);
  const [arrivalMessageFile, setArrivalMessageFile] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isPlaybackActive, setIsPlaybackActive] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [stop, setStop] = useState(false);
  const [isIconDisabled, setIsIconDisabled] = useState(false);
  let [loading, setLoading] = useState(false);
  const [backgroundColor1, setBackgroundColor1] = useState("");

  useEffect(() => {
    setBackgroundColor1("");
  }, [ticketTitle || reply]);

  const item123 = [
    <Dropdown.Item onClick={() => HandleReply()}>Reply</Dropdown.Item>,
  ];
  let HandleReply = () => {
    setReply(true);
  };

  const [openImg, setOpenImg] = React.useState(false);

  const [zoomLevel, setZoomLevel] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const imgRef = useRef(null);

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };
  const handleMouseDown = e => {
    if (zoomLevel > 1) {
      setDragging(true);
      setStartX(e.pageX - offsetX);
      setStartY(e.pageY - offsetY);
    }
  };

  const handleMouseMove = e => {
    if (dragging) {
      e.preventDefault();
      const x = e.pageX - startX;
      const y = e.pageY - startY;
      setOffsetX(x);
      setOffsetY(y);
    }
  };

  const [currentTitle, setCurrentTitle] = useState(null);

  useEffect(() => {
    const fetchExistingList = async () => {
      try {
        const { data } = await AxiosInstance.get(`/help/getTitle?search=`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (data.message) {
          setCurrentTitle(null);
          // toast.warn(data.message);
        } else {
          let filteredData = data.data.filter(
            fil => fil.title === ticketTitle.title
          )[0];
          setCurrentTitle(filteredData);
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          handleError(navigate, socket);
        }
      }
    };
    fetchExistingList();
  }, [ticketTitle]);

  useEffect(() => {
    socket.emit("chatwith", {
      senderId: user?._id,
      role: "student",
      receiverId: newTicketMsg?.requestId,
    });
  }, []);

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseLeave = () => {
    setDragging(false);
  };

  const { download } = useDownloader();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  function stopPropagation(e) {
    e.stopPropagation();
  }
  const openImagePreview = imageSrc => {
    setPreviewImage(imageSrc);
  };
  let handleImage = e => {
    setOpenImg(true);
    openImagePreview(e);
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
      inline: "nearest",
    });
  }, [message]);

  useEffect(() => {
    socket.emit("notification", {
      userId: user?._id,
      role: user?.role1,
      requestId: currentTitle?.requestId,
      seen: true,
    });
  }, [currentTitle]);

  useEffect(() => {
    socket.on("getHelpMsg", data => {
      setArrivalMessage(data);
    });
  }, []);

  useEffect(() => {
    if (arrivalMessage) {
      if (arrivalMessage.length > 0) {
        setMessage(prev => [...prev, arrivalMessage]);
      }
    }
  }, [arrivalMessage]);

  useEffect(() => {
    if (arrivalMessageFile?.length > 0) {
      setArrivalMessageFile([]);
    }
    setMessage(prevMessages => prevMessages.concat(arrivalMessageFile));
  }, [arrivalMessageFile]);
  useEffect(() => {
    let audioStream;
    let recorder;

    const startRecording = async () => {
      try {
        audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        recorder = new MediaRecorder(audioStream);
        recorder.addEventListener("dataavailable", handleDataAvailable);
        recorder.start();
        setMediaRecorder(recorder);
        setIsRecording(true);
      } catch (error) {
        alert("Error accessing microphone");

        setIsRecording(false);

        console.error("Error accessing microphone:", error);
      }
    };

    const stopRecording = () => {
      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
        mediaRecorder.removeEventListener("dataavailable", handleDataAvailable);
        setIsRecording(false);
      }
      if (audioStream) {
        audioStream.getTracks().forEach(track => track.stop());
      }
    };

    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
      setRecordedChunks([]); // Clear recorded
    }

    return () => {
      stopRecording();
    };
  }, [isRecording]);

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleDataAvailable = event => {
    if (event.data.size > 0) {
      const audioData = event.data;

      setRecordedAudio(audioData);
      setRecordedChunks(prevChunks => [...prevChunks, event.data]);
    }
  };
  const handleStopRecording = () => {
    setStop(true);
    setIsRecording(false);
    if (recordedAudio) {
    }
  };

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
    setIsPlaybackActive(prevPlaybackState => !prevPlaybackState);
    setIsIconDisabled(true); // Disable the icon when clicked
  };
  const getAudioURL = () => {
    if (recordedChunks.length === 0) {
      console.error("No audio recorded");
      return null;
    }
    const audioBlob = new Blob(recordedChunks, { type: "audio/webm" });
    //(audioBlob);

    return URL.createObjectURL(audioBlob);
  };

  const audioPlayer = isPlaybackActive ? (
    <audio
      controls
      autoPlay
      key={isPlaybackActive ? "audio-playback" : "audio-empty"}
      // className={!stop ? `${Styles.AudioNot}` : `${Styles.Audio}`}
    >
      <source src={getAudioURL()} type="audio/webm" />
      Your browser does not support the audio element.
    </audio>
  ) : null;

  const storeAudioLocally = audioBlob => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        localStorage.setItem("audioBlob", base64String);
        //("Audio stored locally");
      };
      reader.readAsDataURL(audioBlob);
    } catch (error) {
      console.error("Error storing audio locally:", error);
    }
  };
  let handelSubmitButton = e => {
    handelSubmit();
  };
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      handelSubmit();
    }
  };
  const renderIconButton = (props, ref) => {
    return (
      <IconButton
        {...props}
        ref={ref}
        icon={<IoIosShareAlt />}
        circle
        color="white"
        size="10px"
      />
    );
  };
  let handleDeleteButtonClick = () => {
    localStorage.removeItem("audioBlob");
    setStop(false);
    setIsPlaying(false);
    setRecordedAudio(null);
    setIsPlaybackActive(false);
    setRecordedChunks([]);
    setIsPlaying(false);
  };

  let HandleAudio = async e => {
    e.preventDefault();
    setStop(false);
    setIsPlaying(false);
    const base64String = localStorage.getItem("audioBlob");
    const blobToArrayBuffer = blob => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    };

    const decodeAudioData = arrayBuffer => {
      return new Promise((resolve, reject) => {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        audioContext.decodeAudioData(arrayBuffer, resolve, reject);
      });
    };
    if (base64String) {
      const arrayBuffer = await blobToArrayBuffer(recordedAudio);
      const audioBuffer = await decodeAudioData(arrayBuffer);

      const wavArrayBuffer = await wavEncoder.encode({
        sampleRate: audioBuffer.sampleRate,
        channelData: [audioBuffer.getChannelData(0)], // Assuming mono audio
      });

      const wavBlob = new Blob([wavArrayBuffer], { type: "audio/wav" });
      const wavFile = new File(
        [wavBlob],
        `audio${Math.round(Math.random() * 100)}.wav`,
        {
          type: "audio/wav",
        }
      );
      if (wavFile !== "") {
        try {
          let payload = {
            wavFile,
            sender: user._id,
          };
          //(payload);
          const options = {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "multipart/form-data",
            },
          };
          const res = await AxiosInstance.post(
            "/chat/newmsgfiles",
            payload,
            options
          );
          let filesData = res?.data?.files;

          socket.emit(
            "helpGroup",
            {
              files: filesData,
              senderId: user?._id,
              senderName: user.username,
              receiverId: null,
              role: user?.role1,
              title: ticketTitle.title,
              section: ticketTitle.subject,
              requestId: currentTitle.requestId,
            },
            function callback(msg) {
              setMessage([...message, msg]);
              setIsPlaybackActive(false);
              setRecordedChunks([]);
              setIsPlaying(false);
            }
          );
          setRecordedChunks([]);
          setIsPlaying(false);
        } catch (error) {
          //(error);
        }
      } else {
        //("type msg")
      }
    }
    return null;
  };

  const items = [
    <Dropdown.Item
    // onClick={() => handleOpen2()}
    >
      Forward To
    </Dropdown.Item>,
  ];
  const handlePaste = e => {
    e.preventDefault(); // Prevent the default paste behavior

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text"); // Get the text from the clipboard
    if (pastedText) {
      setNewMessage(pastedText);
    }

    // Handle image paste
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf("image") !== -1) {
        setOpen(true);
        const file = item.getAsFile();
        const imageUrl = URL.createObjectURL(file);
        setPastedImage(imageUrl); // Store pasted image
        break;
      }
    }
  };
  let handelSubmit = async e => {
    e?.preventDefault();
    if (newMessage.trim() !== "") {
      socket?.emit(
        "helpGroup",
        {
          senderId: user?._id,
          senderName: user?.username,
          receiverId: null,
          text: newMessage,
          role: user?.role1,
          type: "help",
          device: "web",
          status: null,
          title: ticketTitle.title,
          section: ticketTitle.subject,
          requestId: currentTitle.requestId,
        },
        function callback(msg) {
          if (msg.read === false) {
            msg.tick = false;
            setMessage([...message, msg]);
          } else {
            setMessage([...message, msg]);
          }
        }
      );
    }

    try {
      setNewMessage("");
    } catch (error) {
      if (error?.response?.status === 403) {
        handleError(navigate, socket);
      }
      //(error);
    }
  };

  function openInNewTab(url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    );
  }

  function stopPropagation(e) {
    e.stopPropagation(); // Stop the event from propagating to the outer div
  }

  function openInNewTab(url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    );
  }

  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    const fetchUrls = async () => {
      const newFileUrls = {};

      await Promise.all(
        message.map(async message => {
          if (message?.message?.files) {
            const fileData = await fetchImageUrl(
              message?.message?.files[0]?.path,
              user?.TOKEN
            );

            if (fileData) {
              newFileUrls[message?.message?.files[0]?.path] = fileData;
            }
          }
        })
      );

      setImageUrls(prevUrls => ({ ...prevUrls, ...newFileUrls }));
    };

    fetchUrls();
  }, [message]);

  function linkifyHtmlContent(text) {
    // Process the text to convert URLs into clickable links
    const linkifyOptions = {
      defaultProtocol: "https",
      target: "_blank", // Open links in a new tab
      rel: "noopener noreferrer", // For security
    };

    return linkifyHtml(text, linkifyOptions);
  }

  return (
    <>
      {/* <Modal onClose={() => setOpenImg(false)} open={openImg} size="full">
        <Modal.Body>
          <div
            style={{
              overflow: "hidden",
              cursor: zoomLevel > 1 ? "grab" : "auto",
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
          >
            <img
              ref={imgRef}
              src={previewImage}
              style={{
                transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
                transition: "transform 0.3s ease",
                maxWidth: "700px",
                maxHeight: "80%",
                width: "80%",
                margin: "auto",
                display: "block",

                
              }}
              alt="Preview"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleZoomOut}>
            <FaSearchMinus />
          </Button>
          <Button variant="secondary" onClick={handleZoomIn}>
            <FaSearchPlus />
          </Button>
          <Button variant="primary" onClick={() => setOpenImg(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
      <MuiModal
        open={openImg}
        onClick={() => setOpenImg(false)}
        // aria-labelledby="image-modal"
        // aria-describedby="image-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "100%",
            maxHeight: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            outline: "none",
            overflow: "hidden",
            cursor: zoomLevel > 1 ? "grab" : "auto",
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
        >
          <MuiIconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: "2222",
            }}
            onClick={() => setOpenImg(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={22}
              height={22}
              viewBox="0 0 24 24"
            >
              <path
                fill="crimson"
                d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12z"
              ></path>
            </svg>
          </MuiIconButton>
          <img
            ref={imgRef}
            src={previewImage}
            alt="Modal Content"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "contain",
              display: "block",
              margin: "0 auto",
              transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
              transition: "transform 0.3s ease",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              top: "35px",
              right: "-3px",
            }}
          >
            <MuiIconButton variant="secondary" onClick={handleZoomOut}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                viewBox="0 0 24 24"
              >
                <path
                  fill="#555"
                  d="M9 2a7 7 0 0 1 7 7c0 1.57-.5 3-1.39 4.19l.8.81H16l6 6l-2 2l-6-6v-.59l-.81-.8A6.9 6.9 0 0 1 9 16a7 7 0 0 1-7-7a7 7 0 0 1 7-7M5 8v2h8V8z"
                ></path>
              </svg>
            </MuiIconButton>
            <MuiIconButton variant="secondary" onClick={handleZoomIn}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                viewBox="0 0 24 24"
              >
                <path
                  fill="#555"
                  d="M9 2a7 7 0 0 1 7 7c0 1.57-.5 3-1.39 4.19l.8.81H16l6 6l-2 2l-6-6v-.59l-.81-.8A6.9 6.9 0 0 1 9 16a7 7 0 0 1-7-7a7 7 0 0 1 7-7M8 5v3H5v2h3v3h2v-3h3V8h-3V5z"
                ></path>
              </svg>
            </MuiIconButton>
          </Box>
        </Box>
      </MuiModal>
      <aside className={Styles.ChatBlock} style={{ background: "#D7DBDD" }}>
        {message?.length > 0 &&
          message.map((messages, ind) => {
            return (
              <div className={Styles.chat} key={ind}>
                <div
                  className={
                    messages?.senderName === user?.username
                      ? `${Styles.sendermsg}`
                      : messages.senderName !== user.username
                      ? `${Styles.recivermsg}`
                      : `${Styles.displaynone}`
                  }
                >
                  <main
                  // style={{ color: "red" }}
                  >
                    <div>
                      {/* <Linkify componentDecorator={openInNewTab}> */}
                      <p className={Styles.textmsg}>
                        {messages?.message?.text ? (
                          messages?.message?.text && messages?.html ? (
                            // <pre
                            //   dangerouslySetInnerHTML={{
                            //     __html: messages?.message?.text,
                            //   }}
                            //   className={Styles.textEditStyle}
                            // />
                            <Linkify
                              componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                              ) => (
                                <a
                                  href={decoratedHref}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  key={key}
                                >
                                  {decoratedText}
                                </a>
                              )}
                            >
                              <pre
                                dangerouslySetInnerHTML={{
                                  __html: linkifyHtmlContent(
                                    messages?.message?.text || ""
                                  ),
                                }}
                                className={Styles.textEditStyle}
                              />
                            </Linkify>
                          ) : (
                            <Linkify componentDecorator={openInNewTab}>
                              {" "}
                              {messages?.message?.text}
                            </Linkify>
                          )
                        ) : messages?.message?.files?.length > 0 ? (
                          <>
                            {(() => {
                              switch (messages?.message?.files[0]?.mimetype) {
                                case "image/jpg":
                                case "image/jpeg":
                                case "image/avif":
                                case "image/png":
                                case "image/webp":
                                case "image/tiff":
                                case "image/bmp":
                                  return (
                                    <>
                                      <img
                                        src={
                                          imageUrls[
                                            messages?.message?.files[0]?.path
                                          ]?.url
                                        }
                                        alt=""
                                        style={{
                                          width: "200px",
                                          height: "130px",
                                          borderRadius: "5px",
                                          display: "flex",
                                        }}
                                        onClick={e => {
                                          // stopPropagation(e);
                                          handleImage(
                                            imageUrls[
                                              messages?.message?.files[0]?.path
                                            ]?.url
                                          );
                                        }}
                                      />{" "}
                                      <button
                                        className={Styles.btndownload}
                                        onClick={e =>
                                          download(
                                            imageUrls[
                                              messages?.message?.files[0]?.path
                                            ]?.url,
                                            messages?.message?.files[0]
                                              ?.originalname,
                                            messages?.message?.files[0].filename
                                          )
                                        }
                                      >
                                        download
                                      </button>
                                    </>
                                  );
                                case "audio/mpeg":
                                case "audio/mp3":
                                case "audio/webm":
                                case "application/wav":
                                case "video/webm":
                                case "audio/wav":
                                  return (
                                    <>
                                      <audio
                                        src={
                                          imageUrls[
                                            messages?.message?.files[0]?.path
                                          ]?.url
                                        }
                                        controls
                                        style={{
                                          width: "250px",
                                          height: "40px",
                                        }}
                                      ></audio>

                                      <>
                                        <span>
                                          {messages.forwarded ? (
                                            <span className={Styles.broadcast}>
                                              <GiWideArrowDunk />
                                              {/* {message.broadcastchat} */}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </>
                                    </>
                                  );
                                case "video/mp4":
                                case "video/x-matroska":
                                case "application/docx":
                                case "application/doc":
                                case "application/msword":
                                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
                                case "application/vnd.ms-word.document.macroEnabled.12":
                                case "application/vnd.ms-excel":
                                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                                case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
                                case "application/vnd.ms-excel.sheet.macroEnabled.12":
                                case "application/vnd.ms-powerpoint":
                                case "application/xlsx":
                                case "application/pptx":
                                case "application/octet-stream":
                                case "text/plain":
                                case "text/html":
                                case "application/apk":
                                case "application/pdf":
                                case "application/x-pdf":
                                case "application/x-gzpdf":
                                case "applications/vnd.pdf":
                                case "application/acrobat":
                                case "application/x-google-chrome-pdf":
                                case "text/pdf":
                                case "text/x-pdf":
                                case "text/csv":
                                case "application/zip":
                                case "application/x-zip":
                                case "application/octet-stream":
                                case "application/x-zip-compressed":
                                case "application/json":
                                  return (
                                    <>
                                      <button
                                        className={Styles.btndownload1}
                                        onClick={() =>
                                          download(
                                            imageUrls[
                                              messages?.message?.files[0]?.path
                                            ]?.url,
                                            messages?.message?.files[0]
                                              ?.originalname,
                                            messages?.message?.files[0]
                                              ?.filename
                                          )
                                        }
                                      >
                                        {
                                          messages?.message?.files[0]
                                            ?.originalname
                                        }
                                        <span
                                          className={Styles.Download}
                                        ></span>
                                        <span className={Styles.linkBlock}>
                                          Click to download
                                        </span>
                                      </button>

                                      <>
                                        <span>
                                          {messages?.forwarded ? (
                                            <span className={Styles.broadcast}>
                                              <GiWideArrowDunk />
                                              {/* {message.broadcastchat} */}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </>
                                    </>
                                  );
                                default:
                                  return (
                                    <button
                                      className={Styles.btndownload1}
                                      onClick={() =>
                                        download(
                                          imageUrls[
                                            messages?.message?.files[0]?.path
                                          ]?.url,
                                          messages?.message?.files[0]
                                            ?.originalname,
                                          messages?.message?.files[0]?.filename
                                        )
                                      }
                                    >
                                      {
                                        messages?.message?.files[0]
                                          ?.originalname
                                      }
                                      <span className={Styles.Download}></span>
                                      <span className={Styles.linkBlock}>
                                        Click to download
                                      </span>
                                    </button>
                                  );
                              }
                            })()}
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                      {/* </Linkify> */}
                    </div>
                    {user?.username !== messages?.senderName && (
                      <div
                        className={Styles.HelpName}
                        style={{
                          fontSize: "x-small",
                          textTransform: "capitalize",
                        }}
                      >
                        {messages?.senderName}
                      </div>
                    )}{" "}
                    <div className={Styles.messageBottom}>
                      {Date.now ? (
                        <Moment format="MMM DD - h:mm a">
                          {messages?.createdAt}
                        </Moment>
                      ) : (
                        <Moment format="MMM DD YYYY h A">
                          {messages?.createdAt}
                        </Moment>
                      )}

                      {backgroundColor1 === "rgb(0 0 0 / 12%)" ? (
                        <span
                          className={Styles.ForwardMsgBlock}
                          onClick={e => stopPropagation(e)}
                        >
                          {" "}
                          <Dropdown
                            renderToggle={renderIconButton}
                            placement="leftEnd"
                            style={{ zIndex: 2222222 }}
                          >
                            {items}
                          </Dropdown>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </main>
                </div>
              </div>
            );
          })}
        <div ref={scrollRef} style={{ visibility: "hidden" }}>
          top
        </div>
      </aside>
      <form onSubmit={handelSubmit} className={Styles.FormMessage}>
        <div className={Styles.messageinput}>
          <input
            type="text"
            placeholder="Type your message"
            value={newMessage}
            onPaste={handlePaste}
            onChange={e => {
              setNewMessage(e.target.value);
            }}
            onKeyPress={handleKeyPress}
            autoFocus
          />
          <aside
            style={{ position: "relative" }}
            className={Styles.UploadfileContainer}
          >
            <>
              {isRecording ? (
                ""
              ) : stop ? (
                ""
              ) : (
                <>
                  <ButtonToolbar>
                    <ImAttachment onClick={handleOpen}></ImAttachment>
                  </ButtonToolbar>
                </>
              )}

              <Modal
                // backdrop={backdrop}
                keyboard={false}
                open={open}
                onClose={handleClose}
              >
                <Modal.Header>
                  <Modal.Title>Send File</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <FileUpload
                    setMessages={setMessage}
                    messages={message}
                    setOpen={setOpen}
                    setArrivalMessage={setArrivalMessage}
                    setArrivalMessageFile={setArrivalMessageFile}
                    selectedTitle={currentTitle}
                    pastedImage={pastedImage}
                    setPastedImage={setPastedImage}
                  />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            </>
          </aside>
          <aside className={Styles.AudioBlock}>
            <div>
              {!stop && !isRecording && !isPlaying && (
                <Whisper followCursor speaker={<Tooltip>{"Start"}</Tooltip>}>
                  <main
                    className={Styles.StartAudio}
                    onClick={handleStartRecording}
                    disabled={isRecording}
                  >
                    <span>
                      {/* start */}
                      <BsFillMicFill />
                    </span>
                  </main>
                </Whisper>
              )}
              <div
                className={`${Styles.recordingAnimation} ${
                  isRecording ? Styles.active : ""
                }`}
              ></div>
              {isRecording && (
                <main
                  className={Styles.StopAudio}
                  onClick={handleStopRecording}
                  disabled={!isRecording}
                >
                  <span>
                    {/* stop */}
                    <BsFillMicMuteFill />
                  </span>
                </main>
              )}
              {stop && (
                <Whisper followCursor speaker={<Tooltip>{"Play"}</Tooltip>}>
                  <main
                    className={Styles.PlayAudio}
                    onClick={handlePlayButtonClick}
                    disabled={isRecording}
                  >
                    <span>
                      {/* play */}
                      <BsFillPlayCircleFill />
                    </span>
                  </main>
                </Whisper>
              )}
              {/* {stop &&<p>{recordingStatus}</p>} */}
              {audioPlayer}
            </div>
          </aside>
          {stop && (
            <div className={Styles.SendAudioBlock}>
              <Whisper followCursor speaker={<Tooltip>{"Delete"}</Tooltip>}>
                <span
                  onClick={handleDeleteButtonClick}
                  className={Styles.DeleteAudio}
                >
                  <AiFillDelete />
                </span>
              </Whisper>
              <span
                onClick={HandleAudio}
                className={Styles.SendAudio}
                style={{ cursor: "pointer" }}
              >
                send
                {/* <AiOutlineSend /> */}
              </span>
            </div>
          )}
          {!stop && (
            <div>
              {loading || isRecording ? (
                <button
                  className="chatSubmitButton"
                  disabled
                  onClick={handelSubmitButton}
                >
                  <div className={Styles.loader}>
                    <RotatingLines
                      strokeColor="#f16f1d"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                      visible={true}
                    />
                  </div>
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className={Styles.sendbutton}
                    onClick={handelSubmitButton}
                  >
                    Send
                  </button>
                  <button
                    type="submit"
                    style={{ display: "none" }}
                    className={Styles.sendbutton}
                    onClick={handelSubmitButton}
                  >
                    Send
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default StudentHelpChatCompNew;
