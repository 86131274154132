import React, { useEffect, useState } from "react";
import { Button, Modal, Tooltip, Whisper } from "rsuite";
import { BddtAxios } from "../../api/AxiosInstance";
import Styles from "../Student/_user.module.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import { socket } from "../../ioContext/socketIo";
import classes from "../Student/profile.module.css";
import darkBack from "../../assets/background.webp";
import { Typography } from "@mui/material";
import handleError from "./ErrorHandler";

const StudentProfile = () => {
  let showBackButton = useLocation();
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  let { number } = useParams();
  let navigate = useNavigate();

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let [profile, setProfile] = useState([]);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const handleClose = () => setOpen(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsers1, setSelectedUsers1] = useState([]);

  const [number1, setNumber1] = useState([]);
  const [email1, setEmail1] = useState([]);

  useEffect(() => {
    async function fetchlist() {
      setLoading(true);

      if (number) {
        await BddtAxios.get(`/hr/student-personaldetails/?number=${number}`, {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        })
          .then(x => {
            setLoading(false);
            let data = x?.data?.results;
            let data1 = data.slice(0, 1);
            setProfile(data1);
            setNumber1(data[0]?.number);
            setEmail1(data[0]?.email);

            let eee = data[0]?.number.map(x => {
              return x.show_to_hr === true ? x?.number : "";
            });
            setSelectedUsers(eee);
            let fff = data[0]?.email.map(x => {
              return x.show_to_hr === true ? x?.email : "";
            });
            setSelectedUsers1(fff);
          })
          .catch(y => {
            setLoading(false);
          });
      }
    }
    fetchlist();
  }, [number]);

  const handlePhoneNumberClick = () => {
    setShowPhoneNumber(prevShowPhoneNumber => !prevShowPhoneNumber);
    setShowEmail(false);
  };
  const handleEmailClick = () => {
    setShowEmail(prevShowEmail => !prevShowEmail);
    setShowPhoneNumber(false);
  };
  let HandleClickLink = () => {
    const newTab = window.open(
      "https://devstudent.qspiders.com/profile",
      "_blank"
    );
    newTab.focus();
  };

  let handleClickLogout1 = e => {
    handleOpen1();
  };
  const HandleSelectUser = user => {
    setSelectedUsers(prevUsers => {
      const userIndex = prevUsers.findIndex(u => u === user);
      if (userIndex !== -1) {
        // If user is already selected, remove it from selectedUsers
        return prevUsers.filter((_, index) => index !== userIndex);
      } else {
        // If user is not selected, add it to selectedUsers
        return [...prevUsers, user];
      }
    });
  };

  const HandleSelectUserEmail = user => {
    setSelectedUsers1(prevUsers => {
      const userIndex = prevUsers.findIndex(u => u === user);
      if (userIndex !== -1) {
        // If user is already selected, remove it from selectedUsers
        return prevUsers.filter((_, index) => index !== userIndex);
      } else {
        // If user is not selected, add it to selectedUsers
        return [...prevUsers, user];
      }
    });
  };

  let HandleUpdateNumber = () => {
    let num = number1?.map(x => {
      return x?.number;
    });

    let ttt = num.map(x => {
      const showToHR = selectedUsers?.includes(x);
      return {
        mob: x,
        show_to_hr: showToHR,
      };
    });

    async function students() {
      if (ttt.length <= 0) {
        toast.error("Select atlast One Number", { position: "top-right" });
      } else {
        let payload = {
          studid: profile[0].id,
          mob_nums: ttt,
        };

        await BddtAxios.post("/hr/update_number/", payload, {
          headers: {
            Authorization: `Token ${user.hr_Token}`,
          },
        })
          .then(x => {
            setOpen(false);

            async function fetchlist() {
              setLoading(true);

              if (number) {
                await BddtAxios.get(
                  `/hr/student-personaldetails/?number=${number}`,
                  {
                    headers: {
                      Authorization: `Token ${user?.hr_Token}`,
                    },
                  }
                )
                  .then(x => {
                    setLoading(false);

                    let data = x?.data?.results;
                    let data1 = data.slice(0, 1);

                    // let img = data[0].image;
                    // setImage(img);
                    setProfile(data1);
                    setNumber1(data[0].number);
                    let eee = data[0].number.map(x => {
                      return x.show_to_hr === true ? x.number : "";
                    });
                    setSelectedUsers(eee);
                    setEmail1(data[0].email);
                  })
                  .catch(y => {
                    setLoading(false);
                  });
              }
            }
            fetchlist();
          })
          .catch(y => {
            setOpen(false);

            if (y?.response?.status === 403) {
              handleError(navigate, socket);
            }
          });
      }
    }
    students();
  };

  let HandleUpdateEmail = () => {
    let num = email1?.map(x => {
      return x.email;
    });

    let ttt = num.map(x => {
      const showToHR = selectedUsers1.includes(x);
      return {
        email: x,
        show_to_hr: showToHR,
      };
    });

    async function students() {
      if (ttt.length <= 0) {
        toast.error("Select atlast One Number", { position: "top-right" });
      } else {
        let payload = {
          studid: profile[0].id,
          emails: ttt,
        };

        await BddtAxios.post("/hr/update_email/", payload, {
          headers: {
            Authorization: `Token ${user.hr_Token}`,
          },
        })
          .then(x => {
            setOpen1(false);
            async function fetchlist() {
              setLoading(true);

              if (number) {
                await BddtAxios.get(
                  `/hr/student-personaldetails/?number=${number}`,
                  {
                    headers: {
                      Authorization: `Token ${user?.hr_Token}`,
                    },
                  }
                )
                  .then(x => {
                    setLoading(false);

                    let data = x?.data?.results;
                    let data1 = data.slice(0, 1);

                    // let img = data[0].image;
                    // setImage(img);
                    setProfile(data1);
                    setNumber1(data[0].number);
                    let eee = data[0].number.map(x => {
                      return x.show_to_hr === true ? x.number : "";
                    });
                    setSelectedUsers(eee);
                    setEmail1(data[0].email);
                  })
                  .catch(y => {
                    setLoading(false);
                  });
              }
            }
            fetchlist();
          })
          .catch(y => {
            setOpen1(false);

            if (y?.response?.status === 403) {
              handleError(navigate, socket);
            }
          });
      }
    }
    students();
  };

  const handleBackButtonClick = () => {
    navigate("/user-dashboard/students");
  };

  return (
    <section style={{ width: "100%", height: "100%", position: "relative" }}>
      {!loading && showBackButton.state && (
        <button
          className={classes.backButtonStudentProfile}
          onClick={handleBackButtonClick}
        >
          <svg width={20} height={20} viewBox="0 0 48 48">
            <path
              fill="#f16f1d"
              fillRule="evenodd"
              stroke="#f16f1d"
              strokeLinejoin="round"
              strokeWidth={4}
              d="M44 40.836c-4.893-5.973-9.238-9.362-13.036-10.168c-3.797-.805-7.412-.927-10.846-.365V41L4 23.545L20.118 7v10.167c6.349.05 11.746 2.328 16.192 6.833c4.445 4.505 7.009 10.117 7.69 16.836Z"
              clipRule="evenodd"
            ></path>
          </svg>{" "}
          Back
        </button>
      )}
      {loading === true ? (
        <div className={Styles.loader}>
          <RotatingLines
            strokeColor="#f16f1d"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      ) : (
        <>
          {profile?.length === 0 ? (
            <section className={Styles.MainProfileBlock}>
              <h6>Student has not filled profile details </h6>
              {/* <span style={{ color: "black" }}>contact- 7338471266</span> */}
            </section>
          ) : (
            profile?.map((ele, ind) => {
              return (
                <main key={ind} className={classes.profileRoot}>
                  <div
                    className={classes.rootProfileContent1}
                    key={ind}
                    style={{
                      backgroundImage: `url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw0QDQ0NDg0NDQ0NDQ0NDQ0NDQ8NDg0NFREWFhURFRUYHSggGBolGxUVITEhJSkrLi4uFx8zODM4NygtLisBCgoKDQ0NDg0NDisZFRk3Kys3LS0tLSs3KysrKzcrKysrKys3KysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAKQBMwMBIgACEQEDEQH/xAAbAAEBAQEBAQEBAAAAAAAAAAAAAQIDBAYFB//EACUQAQEAAgEEAgIDAQEAAAAAAAABAhEDEiExUUFxYYETkbHxof/EABcBAQEBAQAAAAAAAAAAAAAAAAABAgP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAMAwEAAhEDEQA/AP7iAAAAAAAAAAAAAAAAAAAAAACWgqbS1m0HTabcrknWDrWcmf5DqijnaxatZoiWs5LWKqMZ4PNyS/Mem1mqjyddny1Ob3P6Xk4442Kjv/JPY4BCvpgHN0AAAAAAAAAAAAAAAAAAAAGbUzrPUBkxa1tjKelQtZtZtS0FtZtNs2qiXOp/L7jGflkHbql+Wa42nXfajdrNqfye4ls9/wBiJXLPH06ZMWqOI6oI+iAc3QAAAAAAAAAAAAAAAAABm1Ntuec/IOdrPUmUrO1RrqTqZ2m1Gr3YyNmxGds2rlPTnaCZ1i1c2LVRdptLWdqLWbTaWiL1JaiUF3PYzsUfSgOToAAAAAAAAAAAAAACbS0Gk2ztNg1tzzq3JyuSotrNsLWLQLj6YsrWzao57Nt3VYuHqgbZyLLGdgxyOVrvl4ccsVRi1FrNVDaWjILs2gqAAPpgHJ1AAAAAAAAAAAAAS0FYsW1Ngzaza3WLFRjkyc9rne7ALs2ztNqi2pstYoNbOpz2bB16ksjnsmQLlh6cbLHfrZ2qOGUc7HpuM/4xlx/so81RvkwsYaQAEAAfTAOTqAACbTYNCSqAAAAADNAtQQC1LUtZ2qLtLkMcl+AZrNxNqqM7ZsdKxcQc7tNtpcYDnU23cb9udihs2zsEXadSbZtB0mbW3DZMgdcq55ccv4+kmbcoOOXFWLHpLFqR5h26IFI+gNpaza5ujW0tZ2mwa2m2bW8J8qNYxQQBKmwUtIUDaVmpsGqzlV6nPLIBENqiuOV7t5Xs5gGxi1Ua2bZXEGrGbi0AwWS+W7GbiDjnxeu7jXrZyxlVHl2za658Xr/1yyxvpUTaWr0plIDMrWO1xalBrH89nWYxxlblRXTpnqDPWA/WtS1m1NstNbTbO1xm6DWGO/p2SQ2iqlqbQATagG0QGqybS1UZzy1HHa55bv4+GQXqWVkVDOssdXynWDVokqglaxI6dIMi9JoEUALizcP23s2DlY5ZO+c7OOc/YOOWM+nHkldrXHky7tYzqY1prDGWT3pLhQXHy254tbFaGdgP1rUtZtRhpqbtejDHU0xxYa+/8bNFtQRFVNolqoqpABBNgu2M6tccsgZyhK0xliqNM53ssc+TLd+gYtRVUSRuVIIOuGnWOPHHSUG2csfSzItRXIdMo55SxUNm2QE5K57OW+HO5KLyaeSx1zy7Vy2uM66Y3tHTHJbh2n1HO42IrtMZWcuP01x3s2Dz6vqj0BR6rXThw+b+meLDd/H+u7LSoIAbRAVEWAu0EABLQZ5MnMyrO1RpdsylBnKuNb5fDjM/i/2o6StRmRdINBG8cQakVdGgIjTAL1LtlQTLD05122bB4uW93HK7ejn83X7cdqjlmxqunJe7Mqo9clS4VtcfMRUvEsxvy61i1FToGbVUfpSamkBloqUBEQARoAQAEY5KgDmgKgsoA5cnlz5J2BRjHKzw9EA1FbwBFdIoAzmyAEUAKmV7UAeO+b9s5KKjz5eaTzAaZe1rj8gw261jknaUAYAB/9k=')`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      border: "0.3px ridge #999",
                    }}
                  >
                    <div className={classes.leftSide1}>
                      <main className={Styles.imageBlock}>
                        {ele?.thumbnail100 === null ? (
                          <img
                            src={darkBack}
                            className={Styles.profileimage}
                            alt=""
                          />
                        ) : (
                          <img
                            src={`https://hrstbackend.qspiders.com//${ele.thumbnail100}`}
                            className={Styles.profileimage}
                            alt=""
                          />
                        )}
                      </main>
                      <h2 className={Styles.profilename}>{ele.name}</h2>

                      <div className={classes.mobileEmailView}>
                        <h5 className={classes.NumberBlock1}>
                          <span
                            onClick={handlePhoneNumberClick}
                            className={classes.iconHolderProfile}
                          >
                            <span>
                              <svg width="22" height="22" viewBox="0 0 24 24">
                                <path
                                  fill="green"
                                  d="M19.95 21q-3.125 0-6.175-1.362t-5.55-3.863q-2.5-2.5-3.862-5.55T3 4.05q0-.45.3-.75t.75-.3H8.1q.35 0 .625.238t.325.562l.65 3.5q.05.4-.025.675T9.4 8.45L6.975 10.9q.5.925 1.187 1.787t1.513 1.663q.775.775 1.625 1.438T13.1 17l2.35-2.35q.225-.225.588-.337t.712-.063l3.45.7q.35.1.575.363T21 15.9v4.05q0 .45-.3.75t-.75.3M6.025 9l1.65-1.65L7.25 5H5.025q.125 1.025.35 2.025T6.025 9m8.95 8.95q.975.425 1.988.675T19 18.95v-2.2l-2.35-.475zm0 0"
                                />
                              </svg>
                            </span>
                            &nbsp;
                          </span>{" "}
                          {showPhoneNumber && (
                            <>
                              <span
                                className={`${Styles.Valuenumber} ${Styles.active}`}
                              >
                                {ele.number.map((x, i) => {
                                  return x.show_to_hr === true ? (
                                    <div
                                      key={i}
                                      className={classes.titleEmailMobile}
                                    >
                                      {x.number} -{" "}
                                      <span
                                        style={{
                                          color: "green",
                                          fontSize: "x-small",
                                        }}
                                      >
                                        Primary
                                      </span>
                                    </div>
                                  ) : (
                                    <div className={classes.titleEmailMobile}>
                                      {x.number} -{" "}
                                      <span
                                        style={{
                                          color: "#2EC2F8",
                                          fontSize: "x-small",
                                        }}
                                      >
                                        Secondary
                                      </span>
                                    </div>
                                  );
                                })}
                              </span>
                            </>
                          )}
                          <Modal
                            backdrop="static"
                            role="alertdialog"
                            open={open}
                            onClose={handleClose}
                            size="xs"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Modal.Body style={{ fontSize: "16px" }}>
                              <h5> Update Your Number to Hr</h5>
                              <aside>
                                {ele.number.map((x, index) => (
                                  <div
                                    className={Styles.ModalNumber}
                                    key={index}
                                  >
                                    {x.number}

                                    <span>
                                      <input
                                        type="checkbox"
                                        defaultChecked={x.show_to_hr === true}
                                        // checked={x.show_to_hr === true}
                                        onChange={() =>
                                          HandleSelectUser(x.number)
                                        }
                                      />
                                    </span>
                                  </div>
                                ))}
                              </aside>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                appearance="primary"
                                onClick={() => {
                                  HandleUpdateNumber(ele);
                                }}
                              >
                                Update Number
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </h5>
                        <h5 className={classes.NumberBlock1}>
                          <span
                            onClick={handleEmailClick}
                            className={classes.iconHolderProfile}
                          >
                            <span>
                              <svg width="22" height="22" viewBox="0 0 24 24">
                                <path
                                  fill="crimson"
                                  d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm8-7L4 8v10h16V8zm0-2l8-5H4zM4 8V6v12z"
                                />
                              </svg>
                            </span>
                            &nbsp;
                          </span>
                          {showEmail && (
                            <>
                              <span className={Styles.Value}>
                                {ele.email.map((x, i) => {
                                  return x.show_to_hr === true ? (
                                    <div
                                      key={i}
                                      className={classes.titleEmailMobile}
                                    >
                                      {x.email} -{" "}
                                      <span
                                        style={{
                                          color: "green",
                                          fontSize: "x-small",
                                        }}
                                      >
                                        Primary
                                      </span>
                                    </div>
                                  ) : (
                                    <div
                                      key={i}
                                      className={classes.titleEmailMobile}
                                    >
                                      {x.email} -{" "}
                                      <span
                                        style={{
                                          color: "#2EC2F8",
                                          fontSize: "x-small",
                                        }}
                                      >
                                        Secondary
                                      </span>
                                    </div>
                                  );
                                })}
                              </span>
                              {ele.email.length > 1 && (
                                <Button
                                  appearance="primary"
                                  onClick={() => {
                                    handleClickLogout1();
                                  }}
                                >
                                  Update
                                </Button>
                              )}
                            </>
                          )}
                          <Modal
                            backdrop="static"
                            role="alertdialog"
                            open={open1}
                            onClose={handleClose1}
                            size="xs"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Modal.Body style={{ fontSize: "16px" }}>
                              <h5> Update Your Email to Hr</h5>
                              <aside>
                                {ele.email.map((x, index) => (
                                  <div
                                    className={Styles.ModalNumber}
                                    key={index}
                                  >
                                    {x.email}

                                    <span>
                                      <input
                                        type="checkbox"
                                        defaultChecked={x.show_to_hr === true}
                                        // checked={x.show_to_hr === true}
                                        onChange={() =>
                                          HandleSelectUserEmail(x.email)
                                        }
                                      />
                                    </span>
                                  </div>
                                ))}
                              </aside>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                appearance="primary"
                                onClick={() => {
                                  HandleUpdateEmail(ele);
                                }}
                              >
                                Update email
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </h5>
                      </div>
                    </div>
                    <div className={classes.rightSide}>
                      {ele?.percentage_10 <= 0 &&
                      ele?.percentage_iti <= 0 &&
                      ele?.percentage_12 <= 0 &&
                      ele?.percentage_deg <= 0 ? (
                        <aside>
                          <>
                            <h4 style={{ color: "red" }}>
                              * Student has not filled the Profile Details
                            </h4>
                          </>
                        </aside>
                      ) : (
                        <>
                          <div className={classes.headContentDisplay}>
                            <Typography
                              variant="h6"
                              component="h2"
                              className={classes.heading}
                            >
                              Educational Details
                            </Typography>
                          </div>
                          <div className={classes.detailsContainerBlock}>
                            <>
                              {ele?.percentage_10 > 0 && (
                                <div className={classes.educationItem}>
                                  <h5>SSLC</h5>
                                  <main
                                    className={classes.educationItemContent}
                                  >
                                    <div>
                                      Percentage :{" "}
                                      <span>{ele?.percentage_10}%</span>
                                    </div>
                                    <div>
                                      YOP : <span>{ele?.yop_10}</span>
                                    </div>
                                  </main>
                                </div>
                              )}
                              {ele?.percentage_iti > 0 && (
                                <div className={classes.educationItem}>
                                  <h5>ITI</h5>
                                  <main
                                    className={classes.educationItemContent}
                                  >
                                    <div>
                                      Percentage :{" "}
                                      <span>{ele?.percentage_iti}%</span>
                                    </div>
                                    <div>
                                      YOP : <span>{ele?.yg_iti}</span>
                                    </div>
                                  </main>
                                </div>
                              )}

                              {ele?.percentage_12 > 0 ? (
                                <div className={classes.educationItem}>
                                  <h5>PUC</h5>
                                  <main
                                    className={classes.educationItemContent}
                                  >
                                    <div>
                                      Percentage :{" "}
                                      <span>{ele?.percentage_12}%</span>
                                    </div>
                                    <div>
                                      YOP : <span>{ele?.yop_12}</span>
                                    </div>
                                  </main>
                                </div>
                              ) : ele?.percentage_dip > 0 ? (
                                <div className={classes.educationItem}>
                                  <h5>Diploma</h5>
                                  <main
                                    className={classes.educationItemContent}
                                  >
                                    <div>
                                      Percentage :{" "}
                                      <span>{ele?.percentage_dip}%</span>
                                    </div>
                                    <div>
                                      YOP : <span>{ele?.yop_dip}</span>
                                    </div>
                                  </main>
                                </div>
                              ) : null}
                              {/* degree part */}
                              <div>
                                {ele.otherdegree === true ? (
                                  <>
                                    <div className={classes.educationItem}>
                                      <h5>
                                        Degree -{" "}
                                        <span> {ele?.other_degree}</span>
                                      </h5>
                                      {ele.other_stream_deg === true ? (
                                        <div className={classes.educationItem}>
                                          <h5>
                                            Degree Stream -{" "}
                                            <span>
                                              {" "}
                                              {ele?.stream_other_deg}
                                            </span>
                                          </h5>
                                        </div>
                                      ) : ele?.d_stream === null ||
                                        ele?.d_stream?.name === "" ? null : (
                                        <div className={classes.educationItem}>
                                          <h5>
                                            Degree Stream -{" "}
                                            <span> {ele?.d_stream?.name}</span>
                                          </h5>
                                        </div>
                                      )}
                                      <main
                                        className={classes.educationItemContent}
                                      >
                                        <div>
                                          Percentage :{" "}
                                          <span>{ele?.percentage_deg}%</span>
                                        </div>
                                        <div>
                                          YOP : <span>{ele?.yop_deg}</span>
                                        </div>
                                      </main>
                                    </div>
                                  </>
                                ) : ele?.degree === null ? null : (
                                  <>
                                    <div className={classes.educationItem}>
                                      <h5>
                                        Degree -{" "}
                                        <span> {ele?.degree?.name}</span>
                                      </h5>
                                      {ele.other_stream_deg === true ? (
                                        <div className={classes.educationItem}>
                                          <h5>
                                            Degree Stream -{" "}
                                            <span>
                                              {" "}
                                              {ele?.stream_other_deg}
                                            </span>
                                          </h5>
                                        </div>
                                      ) : ele?.d_stream === null ||
                                        ele?.d_stream?.name === "" ? null : (
                                        <div className={classes.educationItem}>
                                          <h5>
                                            Degree Stream -{" "}
                                            <span> {ele?.d_stream?.name}</span>
                                          </h5>
                                        </div>
                                      )}
                                      <main
                                        className={classes.educationItemContent}
                                      >
                                        <div>
                                          Percentage :{" "}
                                          <span>{ele?.percentage_deg}%</span>
                                        </div>
                                        <div>
                                          YOP : <span>{ele?.yop_deg}</span>
                                        </div>
                                      </main>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div>
                                {ele.othermaster === true ? (
                                  <>
                                    <div className={classes.educationItem}>
                                      <h5>
                                        Masters -{" "}
                                        <span> {ele?.other_master}</span>
                                      </h5>
                                      {ele.other_stream_mas === true ? (
                                        <div className={classes.educationItem}>
                                          <h5>
                                            Masters Stream -{" "}
                                            <span>
                                              {" "}
                                              {ele?.stream_other_mas}
                                            </span>
                                          </h5>
                                        </div>
                                      ) : ele?.m_stream === null ||
                                        ele?.m_stream?.name === "" ? null : (
                                        <div className={classes.educationItem}>
                                          <h5>
                                            Masters Stream -{" "}
                                            <span> {ele?.m_stream?.name}</span>
                                          </h5>
                                        </div>
                                      )}
                                      <main
                                        className={classes.educationItemContent}
                                      >
                                        <div>
                                          Percentage :{" "}
                                          <span>{ele?.percentage_mas}%</span>
                                        </div>
                                        <div>
                                          YOP : <span>{ele?.yop_mas}</span>
                                        </div>
                                      </main>
                                    </div>
                                  </>
                                ) : ele?.masters === null ? null : (
                                  <>
                                    <div className={classes.educationItem}>
                                      <h5>
                                        Masters -{" "}
                                        <span> {ele?.masters?.name}</span>
                                      </h5>
                                      {ele.other_stream_mas === true ? (
                                        <div className={classes.educationItem}>
                                          <h5>
                                            Masters Stream -{" "}
                                            <span>
                                              {" "}
                                              {ele?.stream_other_mas}
                                            </span>
                                          </h5>
                                        </div>
                                      ) : ele?.m_stream === null ||
                                        ele?.m_stream?.name === "" ? null : (
                                        <div className={classes.educationItem}>
                                          <h5>
                                            Masters Stream -{" "}
                                            <span> {ele?.m_stream?.name}</span>
                                          </h5>
                                        </div>
                                      )}
                                      <main
                                        className={classes.educationItemContent}
                                      >
                                        <div>
                                          Percentage :{" "}
                                          <span>{ele?.percentage_mas}%</span>
                                        </div>
                                        <div>
                                          YOP : <span>{ele?.yop_mas}</span>
                                        </div>
                                      </main>
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          </div>
                        </>
                      )}
                      {ele?.added_specified_time === "" ? (
                        ""
                      ) : (
                        <aside className={Styles.ReightBottom}>
                          <div className={classes.headContentDisplay}>
                            <Typography
                              variant="h6"
                              component="h2"
                              className={classes.heading}
                            >
                              Requirement Info
                            </Typography>
                          </div>
                          <div className={classes.educationItem}>
                            <main className={classes.educationItemContent1}>
                              <div>
                                Requirement Count :{" "}
                                <span>
                                  {ele?.added_specified_time?.req_count}
                                </span>
                              </div>
                              <div>
                                Scheduled Count :{" "}
                                <span>
                                  {ele?.added_specified_time?.sch_count}
                                </span>
                              </div>
                              <div>
                                Removed Count :{" "}
                                <span>
                                  {ele?.added_specified_time?.removed_count}
                                </span>
                              </div>
                              <div>
                                Total Attended Count :{" "}
                                <span>
                                  {
                                    ele?.added_specified_time
                                      ?.total_attended_count
                                  }
                                </span>
                              </div>
                            </main>
                          </div>
                        </aside>
                      )}
                      <div className={Styles.AddReq}>
                        {ele?.percentage_10 <= 0 &&
                        ele?.percentage_iti <= 0 &&
                        ele?.percentage_12 <= 0 &&
                        ele?.percentage_deg <= 0 ? (
                          <Whisper
                            followCursor
                            placement="bottom"
                            speaker={
                              <Tooltip>
                                <span>
                                  Student has not filled academic details, to
                                  get requirement list student has to fill the
                                  academic details
                                </span>
                              </Tooltip>
                            }
                          >
                            <Button appearance="primary">
                              Requirement List
                            </Button>
                          </Whisper>
                        ) : (
                          <Link
                            to={`/user-dashboard/studentrequirementlist/${ele?.id}`}
                          >
                            <Button appearance="primary">
                              Requirement List
                            </Button>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </main>
              );
            })
          )}
        </>
      )}
    </section>
  );
};

export default StudentProfile;
