import React, { useContext, useEffect, useState } from "react";

import styles from "./Chat/Modal.module.css";
import { FiSend } from "react-icons/fi";
import { RotatingLines } from "react-loader-spinner";

import { useNavigate } from "react-router-dom";
import AxiosInstance from "../../api/AxiosInstance";
import { SocketContext } from "../../ioContext/socketIo";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import handleError from "../Users/ErrorHandler";

const FileUpload = ({
  setOpen,
  setArrivalMessageFile,
  selectedTitle,
  selectedMessages,
  reply,
  setReply,
  setSelectedMessages,
  setActiveIndex,
  pastedImage,
  setPastedImage,
}) => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const userToken = sessionStorage.getItem("token");

  const socket = useContext(SocketContext);
  const [files, setFiles] = useState([]);
  let [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  // Integrate pastedImage into files state if it's available
  useEffect(() => {
    if (pastedImage) {
      fetch(pastedImage)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "pasted-image.png", {
            type: blob.type,
          });
          setFiles([...files, file]); 
        });
    }
  }, [pastedImage]);
  const previewFile = e => {
    const selectedFiles = e.target.files;

    if (selectedFiles.length > 5) {
      toast.error("Please select up to 5 files.");
      return;
    }

    const newFiles = Array.from(selectedFiles);
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const handleDelete = index => {
    setFiles(prevFiles => prevFiles.filter((file, i) => i !== index));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (files.length > 0) {
      setLoading(true);

      const abc = files.map(x => x);

      try {
        const payload = {
          files: abc,
          sender: user._id,
        };

        const options = {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "multipart/form-data",
          },
        };

        const res = await AxiosInstance.post(
          "/chat/newmsgfiles",
          payload,
          options
        );
        const filesData = res.data.files;

        const promises = filesData.map(file => {
          return new Promise((resolve, reject) => {
            const messagePayload = {
              files: [file],
              requestId: selectedTitle.requestId,
              senderId: user._id,
              senderName: user.username,
              role: user?.role1,
              section: selectedTitle.section,
              title: selectedTitle.title,
            };

            if (reply) {
              messagePayload.replayback = {
                message: selectedMessages[0]?.message?.text || "",
                file: selectedMessages[0]?.message?.files || "",
              };
            }

            socket.emit("helpGroup", messagePayload, msg => {
              if (msg) {
                resolve(msg);
              } else {
                reject(new Error("Message delivery failed"));
              }
            });
          });
        });

        const messages = await Promise.all(promises);
        messages.forEach(msg => {
          setArrivalMessageFile(prevMessages => [...prevMessages, msg]);
        });

        setReply(false);
        setSelectedMessages([]);
        setActiveIndex(null);
        setPastedImage(null);
        setFiles([]);
        setOpen(false);
        setLoading(false);
      } catch (error) {
        setLoading(false); 
        if (error?.response?.status === 403) {
          handleError(navigate, socket);
        } else {
          console.error("Error:", error);
          toast.error("An error occurred while sending the message.");
        }
      }
    } else {
      toast.warn("Please select a file");
    }
  };

  let handelSubmitButton = e => {
    handleSubmit(e);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className={styles.fileupload}>
          <Button
            size="small"
            variant="contained"
            component="label"
            className={styles.fileUpload}
            title="Add File"
            color="success"
          >
            <input
              type="file"
              name="image"
              onChange={e => previewFile(e)}
              hidden
              multiple
              style={{ display: "none" }}
            />
            Choose File
          </Button>
          <span style={{ fontWeight: "bold" }}>{files[0]?.name}</span>
        </div>
        <br />
        <div className={styles.previewContainer}>
          {files.length > 0 &&
            files.map((file, index) => (
              <div className={styles.imagePreviewContainer} key={index}>
                {file.type && file.type.includes("image") ? (
                  <React.Fragment>
                    <img
                      src={URL.createObjectURL(file)}
                      alt=""
                      width={100}
                      height={100}
                      className={styles.imagePreview}
                    />
                    <span
                      className={styles.deleteButton}
                      onClick={() => {
                        handleDelete(index);
                      }}
                    >
                      <svg width={26} height={26} viewBox="0 0 12 12">
                        <path
                          fill="crimson"
                          d="M5 3h2a1 1 0 0 0-2 0M4 3a2 2 0 1 1 4 0h2.5a.5.5 0 0 1 0 1h-.441l-.443 5.17A2 2 0 0 1 7.623 11H4.377a2 2 0 0 1-1.993-1.83L1.941 4H1.5a.5.5 0 0 1 0-1zm3.5 3a.5.5 0 0 0-1 0v2a.5.5 0 0 0 1 0zM5 5.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5M3.38 9.085a1 1 0 0 0 .997.915h3.246a1 1 0 0 0 .996-.915L9.055 4h-6.11z"
                        ></path>
                      </svg>
                    </span>
                  </React.Fragment>
                ) : (
                  <div className={styles.nonImagePreview}>
                    <div className={styles.fileIcon}>
                      <span role="img" aria-label="File Icon">
                        📄
                      </span>
                    </div>
                    <div className={styles.fileName}>{file.name}</div>
                    <span
                      className={styles.deleteButton}
                      onClick={() => {
                        handleDelete(index);
                      }}
                    >
                      <svg width={26} height={26} viewBox="0 0 12 12">
                        <path
                          fill="crimson"
                          d="M5 3h2a1 1 0 0 0-2 0M4 3a2 2 0 1 1 4 0h2.5a.5.5 0 0 1 0 1h-.441l-.443 5.17A2 2 0 0 1 7.623 11H4.377a2 2 0 0 1-1.993-1.83L1.941 4H1.5a.5.5 0 0 1 0-1zm3.5 3a.5.5 0 0 0-1 0v2a.5.5 0 0 0 1 0zM5 5.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5M3.38 9.085a1 1 0 0 0 .997.915h3.246a1 1 0 0 0 .996-.915L9.055 4h-6.11z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                )}
              </div>
            ))}
        </div>
        <div className={styles.sendbtnfile}>
          {loading ? (
            <button disabled id={styles.filesubmit}>
              <div className={styles.loader}>
                <RotatingLines
                  strokeColor="#f16f1d"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              </div>
            </button>
          ) : (
            <button
              id={styles.filesubmit}
              disabled={files.length === 0}
              title={files.length === 0 ? "Select file" : "Send"}
              onClick={handelSubmitButton}
            >
              <FiSend color="#fff" />
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default FileUpload;
