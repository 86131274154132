import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosInstance from "../../../api/AxiosInstance";
import { socket } from "../../../ioContext/socketIo";
import Styles from "./_UserToUser.module.css";
import handleError from "../ErrorHandler";

const CreateUserGroup = () => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let navigate = useNavigate();
  let [branchUserList, setBranchUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  let [groupname, setGroupName] = useState("");
  let [search, setSearch] = useState("");

  useEffect(() => {
    async function fetchlist() {
      //(searchapi);
      await AxiosInstance.get(`/employee/users_list`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(data => {
          let list = data?.data?.UsersList;
          setBranchUserList(list);
        })
        .catch(x => {
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchlist();
  }, []);

  let HandleSelectUser = user => {
    if (selectedUsers?.some(x => x._id === user._id)) {
      setSelectedUsers(
        selectedUsers?.filter(selectedUser => selectedUser._id !== user._id)
      );
    } else {
      setSelectedUsers([
        ...selectedUsers,
        { _id: user._id, username: user?.username, role: user?.role[0] },
      ]);
    }
  };

  let HandleGroupName = e => {
    setGroupName(e?.target?.value?.trim());
  };
  let HandleCreateGroup = () => {
    let payload = {
      groupName: groupname, 
      id: user._id,
      usersList: selectedUsers,
    };

    if (groupname === "") {
      toast.error("Enter Group Name", {
        position: "top-right",
      });
    } else if (selectedUsers.length <= 0) {
      toast.error("Select user to create group", {
        position: "top-right",
      });
    } else {
      async function students() {
        let data = await AxiosInstance.post(
          `/employee/users_creategroup`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        )
          .then(x => {
            toast.success(x?.data?.message, {
              position: "top-right",
            });
            navigate("/user-dashboard/userConversation");
          })
          .catch(x => {});
      }
      students();
    }
  };
  let HandleBackButton = () => {
    navigate("/user-dashboard/userConversation");
  };
  let handleSearch = e => {
    setSearch(e.target.value);
  };
  return (
    <>
      <section>
        <div className={Styles.app}>
          <div className={Styles.sidebar}>
            <header className={Styles.header1}>
              {/* <!-- WhatsApp Logo --> */}
              <span
                className={Styles.UserList}
                onClick={() => {
                  HandleBackButton();
                }}
              >
                <FaArrowLeft />
              </span>

              <span
                className={Styles.UserList}
                //   onClick={() => {
                //     HandleBranchUserList();
                //   }}
              >
                Add Group Participants
              </span>
            </header>
            <div className={Styles.search}>
              {/* <!-- Search Bar --> */}
              <div className={Styles.SearchBlock}>
                <span className={Styles.SearchText}>
                  <input
                    type="text"
                    value={search}
                    placeholder="Search Users"
                    onChange={e => setSearch(e.target.value)}
                  />
                </span>
                <span>
                  <FiSearch />
                </span>
              </div>
            </div>
            <div className={Styles.contacts}>
              {/* <!-- List of Contacts --> */}
              {branchUserList?.length > 0 &&
                branchUserList
                  ?.filter(students =>
                    students?.username
                      ?.toLowerCase()
                      ?.includes(search.toLowerCase())
                  )
                  .map((ele, ind) => {
                    return (
                      <div
                        className={Styles.message}
                        key={ind}
                        style={{
                          backgroundImage: `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7t0PfqiaGFMmJDHK4GReTM2kZBwY4dfB6EQ&usqp=CAU')`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <label
                          className={Styles.senderinfo}
                          style={{
                            color: "#111",
                            textTransform: "capitalize",
                            fontWeight: "700",
                          }}
                        >
                          {ele.username}
                          <input
                            type="checkbox"
                            onClick={() => HandleSelectUser(ele)}
                          />
                        </label>
                      </div>
                    );
                  })}
            </div>
          </div>
          <div className={Styles.chatarea}>
            <h2 style={{ textAlign: "center", color: "#f16f1d" }}>
              Create Group
            </h2>
            <aside className={Styles.CreateGroupBlock}>
              <div className={Styles.GroupName}>
                <label
                  htmlFor=""
                  className={Styles.LabelGroup}
                  style={{ color: "#f16f1d" }}
                >
                  Group Name
                </label>
                <input
                  type="text"
                  onChange={e => HandleGroupName(e)}
                  placeholder="Enter GroupName"
                  className={Styles.EnterGroupName}
                />
              </div>
              <header className={Styles.header12}>
                <h6 style={{ color: "#f16f1d" }}>
                  {" "}
                  Number of selected Users{" "}
                  <span className={Styles.Count}>
                    {selectedUsers.length}
                  </span>{" "}
                </h6>
                {/* <!-- Contact Info and Actions --> */}
              </header>
              <br />
              <div className={Styles.chat}>{/* <!-- Messages --> */}</div>
              <div className={Styles.messageinput}>
                {/* <!-- Message Input Field --> */}
                <button
                  className={Styles.sendbutton}
                  onClick={() => HandleCreateGroup()}
                >
                  Create
                </button>
              </div>
            </aside>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateUserGroup;
