import React, { useEffect } from "react";
// import { socket } from "./ioContext/socketIo"; // Import your socket instance
// import { SocketContext } from "./ioContext/socketIo"; // Your context
import { toast } from "react-toastify";
import { SocketContext, socket } from "./socketIo";

export const SocketProvider = ({ children }) => {
  useEffect(() => {
    const handleOnline = () => {
      if (socket.disconnected) {
        socket.connect();
        toast.info("Reconnecting to the server...", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    };

    const handleOffline = () => {
      toast.error("Internet connection lost. Please check your connection.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    };

    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    socket.on("reconnect", () => {
      console.log("Socket reconnected");
    });

    socket.on("reconnect_failed", () => {
      console.log("Socket reconnection failed");
    });

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      socket.off("disconnect");
      socket.off("reconnect");
      socket.off("reconnect_failed");
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};


