import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosInstance from "../../api/AxiosInstance";
import Styles from "../auth/_auth.module.css";
import Select from "react-select";
import { IoMdAddCircle } from "react-icons/io";
import { Modal } from "rsuite";
import { CreateReasons } from "../../redux/Batch/BatchSlice";
const BlockUsers = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let { id } = useParams();
  //   let {username} = useParams()
  //(id);

  let [userId, setUserId] = useState(id);
  let [username, setUsername] = useState("");
  let [batchCode, setBatchCode] = useState([]);
  let [batchcode, setBatchcode] = useState([]);
  let [groupCode, setGroupCode] = useState([]);

  let [groupcode, setGroupcode] = useState([]);
  let [reasons, setReasons] = useState("");
  let [inputReason, setInputReason] = useState("");
  let [reasonsList, setReasonsList] = useState([]);
  const [openCourse, setopenCourse] = useState(false);
  const [errors, setErrors] = useState({});
  let { userAdmin } = useSelector(state => state.auth);

  //validation
  const validate = () => {
    let errors = {};
    if (!reasons) {
      errors.reasons = "Reason field is required";
    }
    return errors;
  };

  useEffect(() => {
    async function fetchData(e) {
      let resondata = await AxiosInstance.get(
        `auth/getdata?data=block_reasons
      `,
        {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }
      );
      // //(Coursedata);
      //(resondata);
      let Coursedataaa = resondata?.data[0].block_reasons;
      // //(Coursedataaa);
      let eee = Coursedataaa.map(function (ele) {
        return { value: ele, label: ele };
      });
      setReasonsList(eee);
    }
    fetchData();
  }, [inputReason, userId]);

  useEffect(() => {
    async function fetchlist() {
      let data = await AxiosInstance.post(
        `/admin/batch_groupdata`,
        { userId: id },
        {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }
      );
      //(data);
      let batch = data?.data?.batchdata;
      let group = data?.data?.groupdata;
      //   setGroup(group);
      let namee = data?.data?.username;

      setUsername(namee);
      //(data);
      let eee = batch.map(function (ele) {
        return { value: ele.batchCode, label: ele.batchCode };
      });
      let fff = group?.map(function (ele) {
        return { value: ele.groupCode, label: ele.groupCode };
      });
      setGroupcode(fff);
      //(fff);
      setBatchcode(eee);
    }
    fetchlist();
  }, []);

  const handleChangeStudent = e => {
    // //(e)
    setBatchCode(Array.isArray(e) ? e.map(x => x.value) : []);
  };

  const handleChangeGroup = e => {
    setGroupCode(Array.isArray(e) ? e.map(x => x.value) : []);
  };

  //(errors)
  let handleSubmit = async e => {
    e.preventDefault();
    let payload = { userId, batchCode, groupCode, reasons };

    //(reasons);
    if (batchCode.length === 0 && groupCode.length === 0) {
      toast.error("Select BatchCode or GroupCode", {
        position: "top-right",
      });
    } else if (reasons === "") {
      setErrors(validate(reasons));
    } else {
      await AxiosInstance.post(`/admin/block_to_batch_group`, payload, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          //(x);
          toast.success(x?.data?.message, {
            position: "top-right",
          });
          navigate("/admin-dashboard/admin/allroles");
        })
        .catch(x => {
          //(x?.response?.data?.message);
          toast.error(x?.response?.data?.message, {
            position: "top-right",
          });
        });
    }
    //(payload);
  };

  let handleReason = e => {
    if (e?.value) {
      setReasons(e.value);
    } else {
      setReasons("");
    }
  };

  function OpenCourse() {
    //("clicked");
    setopenCourse(true);
  }
  function closeCourse() {
    setopenCourse(false);
    // window.location.reload()
  }
  let CourseSubmit = e => {
    let payload = { block_reasons: inputReason };
    //(reasons);
    if (inputReason === "") {
      toast.error("Add Reason", { position: "top-right" });
      // //("enter data")
    } else {
      dispatch(CreateReasons(payload));
      toast.success("Reason is Added", { position: "top-right" });
      setInputReason("");
    }
  };
  const handleCancel = () => {
    navigate(-1);
  };
  return (
    <section id={Styles.authBlock}>
      <article>
        <div>
          <main>
            <form onSubmit={handleSubmit}>
              <h1>Block Users</h1>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  className="form-control"
                  type="text"
                  name="username"
                  readOnly
                  value={username}
                  placeholder="Enter username"
                  onChange={e => setUsername(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="addStudentstogroup">BatchCode</label>
                <Select
                  // closeMenuOnSelect={false}
                  isMulti
                  name="students"
                  options={batchcode}
                  onChange={handleChangeStudent}
                />
              </div>
              <div>
                <label htmlFor="addStudentstogroup">GroupCode</label>
                <Select
                  // closeMenuOnSelect={false}
                  isMulti
                  name="students"
                  options={groupcode}
                  onChange={handleChangeGroup}
                />
              </div>
              <div className={Styles.reasons}>
                <label htmlFor="reasons">
                  Reason To Block
                  <span className={Styles.AddIconBlock}>
                    <IoMdAddCircle onClick={OpenCourse}></IoMdAddCircle>
                  </span>
                  <Modal
                    size="sm"
                    // backdrop={backdrop}
                    keyboard={false}
                    open={openCourse}
                    onClose={closeCourse}
                    // style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Modal.Header>
                      <Modal.Title>Add reason to Block</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <div className={Styles.AddReasonBlock}>
                        {/* <form onSubmit={CourseSubmit}> */}
                        <input
                          value={inputReason}
                          type="text"
                          placeholder="Add Reason"
                          className={Styles.inputforBranch}
                          onChange={e => setInputReason(e.target.value)}
                        />
                        <button onClick={CourseSubmit}>Add</button>
                        {/* </form> */}
                      </div>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                  </Modal>
                </label>

                <Select
                  name="reasons"
                  options={reasonsList}
                  onChange={handleReason}
                />
                <p
                  className={
                    errors.reasons ? `${Styles.errrr}` : `${Styles.gapbtw}`
                  }
                ></p>
                {errors.reasons && (
                  <p className={Styles.errors}>{errors.reasons}</p>
                )}
              </div>

              <div style={{ display: "flex", gap: "5px" }}>
                <button
                  style={{ background: "red", border: "1px solid red" }}
                  type="button"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button>Block</button>
              </div>
            </form>
          </main>
        </div>
      </article>
    </section>
  );
};

export default BlockUsers;

// export default BlockStudents
