import React from "react";
import Styles from "./_help.module.css";
import { BsChatDots } from "react-icons/bs";
import { useState } from "react";
import { useEffect } from "react";
import bg6 from "../../assets/bg3.webp";
import bg2 from "../../assets/bg2.webp";
import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import { ImCross } from "react-icons/im";
import { Fragment } from "react";
import { useRef } from "react";
import { socket } from "../../ioContext/socketIo";
import AxiosInstance from "../../api/AxiosInstance";
import { FiSearch } from "react-icons/fi";
import InfiniteScroll from "react-infinite-scroll-component";
import { Autocomplete, Box, Button } from "@mui/material";
import { Divider, Grid, Modal, TextField } from "@mui/material";
import ChatComp from "./ChatComp";
import { toast } from "react-toastify";
import OldChats from "./OldChats";
import handleError from "../Users/ErrorHandler";

const styleModal1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
};

const UserHelp = () => {
  let navigate = useNavigate();
  let scrollRef = useRef();

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);

  let [branchUserList, setBranchUserList] = useState([]);
  const [loadBranchUser, setLoadBranchUser] = useState(false);
  let [branchUserClicked, setBranchUserClicked] = useState(false);

  let [conversationList, setConversationList] = useState([]);
  let [defaultStudentList, setDefaultStudentList] = useState([]);

  const [loadConv, setLoadConv] = useState(false);
  const [number, SetNumber] = useState(0);
  const [stopscroll, setStopScroll] = useState(true);

  const [newMessage, setNewMessage] = useState("");

  let [particularStudent, setParticularStudent] = useState(null);
  const [existingTitleList, setExistingTitleList] = useState([]);
  const [defaultExistingTitleList, setDefaultExistingTitleList] = useState([]);
  const [titleSearchTerm, setTitleSearchTerm] = useState("");
  const [searchTrueList, setSearchTrueList] = useState(false);
  const [loadExisting, setLoadExisting] = useState(false);
  const [selectedExisting, setSelectedExisting] = useState(null);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [groupMembersList, setGroupMembersList] = useState([]);
  const [loadMembers, setLoadMembers] = useState(false);
  const [openRemoveUser, setOpenRemoveUser] = useState(false);
  const [selectedUserToRemove, setSelectedUserToRemove] = useState(null);
  const [addedUserToGroup, setAddedUserToGroup] = useState([]);

  const [finalNotiCount, setFinalNotiCount] = useState({});
  const [studentNotificationStatus, setStudentNotificationStatus] = useState(
    {}
  );

  const [searchStu, setSearchStu] = useState("");
  const [isSearchStu, setIsSearchStu] = useState(false);

  const handleSearchStudent = async () => {
    if (searchStu?.length > 2) {
      setIsSearchStu(true);
      try {
        const response = await AxiosInstance.get(
          `/help/getAllStudents?name=${searchStu?.toLowerCase()}`,
          {
            headers: {
              Authorization: `Bearer ${user?.TOKEN}`,
            },
          }
        );
        setConversationList(response.data.students);
      } catch (error) {
        console.log("error", error);
      }
    } else {
      toast.warn("Please enter at least 3 characters of student name");
    }
  };
  const handleResetStudent = () => {
    setSearchStu("");
    setIsSearchStu(false);
    setConversationList(defaultStudentList.students);
  };

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      handleSearchStudent();
    }
  };
  const handleBackspaceUser = event => {
    if (event.key === "Backspace" && searchStu.length === 1) {
      setSearchStu("");
      setIsSearchStu(false);
      setConversationList(defaultStudentList.students);
    }
  };

  const updateNotification = (studentId, data) => {
    setFinalNotiCount(prevState => ({
      ...prevState,
      [studentId]: {
        ...(prevState[studentId] || {}),
        ...data,
      },
    }));
  };

  useEffect(() => {
    if (Object.keys(finalNotiCount).length > 0) {
      setStudentNotificationStatus(prevState => {
        const updatedStudentNotificationStatus = { ...prevState };

        Object.keys(finalNotiCount).forEach(studentId => {
          const studentData = finalNotiCount[studentId];
          const allNotificationsFalse = Object.values(studentData).every(
            notificationData => !notificationData.notification
          );

          // Update notification status for the current studentId
          updatedStudentNotificationStatus[studentId] = {
            ...updatedStudentNotificationStatus[studentId], // Preserve other data for the studentId
            notification: !allNotificationsFalse,
          };
        });

        return updatedStudentNotificationStatus;
      });
    }
  }, [finalNotiCount]);

  const removeUserFunction = async () => {
    let payload = {
      requestId: selectRequestId.requestId,
      userId: selectedUserToRemove.userId,
    };
    try {
      const { data } = await AxiosInstance.delete(`help/removeMembers`, {
        data: payload,
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      });
      toast.success("user removed successfully");
      socket?.emit(
        "helpGroup",
        {
          senderId: user._id,
          senderName: user.username,
          receiverId: particularStudent?.studentId,
          text: `removed user ${selectedUserToRemove.username} from group`,
          role: user?.role1,
          section: selectRequestId.section,
          requestId: selectRequestId.requestId,
          title: selectRequestId.title,
          device: "web",
          status: null,
        },
        function callback(msg) {}
      );
      fetchUsers();
      setSelectedUserToRemove(null);
      setOpenRemoveUser(false);
    } catch (error) {}
  };

  let [selectedUser, setSelectedUser] = useState({});

  let [message, setMessage] = useState([]);
  let [notification, setNotification] = useState([]);

  let [recentMsg, setRecentMsg] = useState({});

  let d = Date.now();
  let today = dateFormat(d, "mmm d, yyyy");

  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isPlaybackActive, setIsPlaybackActive] = useState(false);

  const [recordedAudio, setRecordedAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [stop, setStop] = useState(false);
  const [isAudioVisible, setIsAudioVisible] = useState(false);
  const [removeNotification, setRemoveNotification] = useState([]);
  const [allNotificationsFalse, setAllNotificationsFalse] = useState([]);

  const removeNotificationCount = (a, b) => {
    const isNotificationPresent = removeNotification.some(
      notification => notification.id === a
    );
    if (!isNotificationPresent) {
      setRemoveNotification([...removeNotification, { id: a, title: b }]);
    }
  };

  // get notification function
  const getNoticount = (studentId, requestId) => {
    if (finalNotiCount[studentId]) {
      if (finalNotiCount[studentId][requestId]) {
        return finalNotiCount[studentId][requestId].noticount === 0 ? null : (
          <span
            style={{
              fontSize: "small",
              display: "inline-block",
              padding: "1px 4px 0px",
              borderRadius: "30px",
              border: "0.2px solid #1bc523",
              marginLeft: "7%",
              color: "#fff",
              backgroundColor: "#1bc523",
            }}
          >
            {finalNotiCount[studentId][requestId].noticount}
          </span>
        );
      }
    }
    return null;
  };

  // function to update notification on click of title
  const updateNotificationState = (studentId, requestId) => {
    setFinalNotiCount(prevState => {
      // Clone the previous state
      const newState = { ...prevState };

      if (newState[studentId]) {
        if (newState[studentId][requestId]) {
          newState[studentId][requestId] = {
            noticount: 0,
            notification: false,
          };
        }
      }

      // Return the updated state
      return newState;
    });
  };

  let [search, setSearch] = useState("");
  let [searchapi, setSearchApi] = useState("");
  let [searchUser, setSearchUser] = useState("");
  let [clear, setClear] = useState(false);

  useEffect(() => {
    const fetchExistingList = async () => {
      try {
        setLoadExisting(true);
        const { data } = await AxiosInstance.post(
          `/help/getTitleUserStu`,
          {
            studentId: particularStudent.studentId,
          },
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );

        setLoadExisting(false);
        if (data.message) {
          setExistingTitleList(null);
          // toast.warn(data.message);
        } else {
          setExistingTitleList(data.results);
          setDefaultExistingTitleList(data.results);

          const notificationData = {};
          data.results.forEach(item => {
            const { requestId, conversation } = item;
            if (conversation.length > 0) {
              const { noticount, notification } = conversation[0];
              notificationData[requestId] = { noticount, notification };
            }
          });
          updateNotification(particularStudent.studentId, notificationData);
        }
      } catch (error) {
        setLoadExisting(null);
        console.log(error);
      }
    };
    if (particularStudent !== null) fetchExistingList();
  }, [particularStudent]);

  // handle add user function
  const [openAddUser, setOpenAddUser] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectRequestId, setSelectRequestId] = useState(null);
  const [showOldChats, setShowOldChats] = useState(false);

  const [usersList, setUsersList] = useState([]);

  const handleAddUserSubmit = async e => {
    e.preventDefault();
    if (selectedBranch === null) {
      toast.warn("Please select branch");
    } else if (selectedUsers.length === 0) {
      toast.warn("Please select user");
    } else {
      try {
        const user1 = selectedUsers.map(e => e._id);
        const userNames = selectedUsers.map(e => e.username);

        let payload = {
          requestId: selectRequestId.requestId,
          studentId: particularStudent.studentId,
          users: user1,
        };
        let { data } = await AxiosInstance.post(`/help/addMembers`, payload, {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        });
        socket?.emit(
          "helpGroup",
          {
            senderId: user._id,
            senderName: user.username,
            receiverId: particularStudent?.studentId,
            text: `added user ${userNames.join(", ")} to group`,
            role: user?.role1,
            section: selectRequestId.section,
            requestId: selectRequestId.requestId,
            title: selectRequestId.title,
            device: "web",
            status: null,
          },
          function callback(msg) {}
        );

        toast.success("User added successfully");
        setSelectedUsers([]);
        setUsersList([]);
        setSelectedBranch(null);
        setOpenAddUser(false);
        fetchUsers();
        setAddedUserToGroup([...addedUserToGroup, selectRequestId.requestId]);
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const submitAddUser = async () => {
    setOpenAddUser(true);

    if (
      particularStudent?.branch !== null &&
      particularStudent?.branch?.length === 0
    ) {
      fetchBranches();
    } else {
      const newData = particularStudent?.branch?.map(branch => ({
        value: branch,
        label: branch,
      }));
      setBranchList(newData);
    }
  };

  const handleClickViewDescription = item => {
    // fetchUsers();
    setSelectRequestId(item);
    setShowOldChats(false);
    setIsDescriptionOpen(true);
  };

  const fetchUsers = async () => {
    setLoadMembers(true);
    try {
      let { data } = await AxiosInstance.get(
        `/help/getGroupMembers/${selectRequestId.requestId}`,
        {
          headers: { Authorization: `Bearer ${user.TOKEN}` },
        }
      );
      setLoadMembers(false);
      setGroupMembersList(data.members);
    } catch (error) {
      setLoadMembers(false);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setLoadMembers(true);
      try {
        let { data } = await AxiosInstance.get(
          `/help/getGroupMembers/${selectRequestId.requestId}`,
          {
            headers: { Authorization: `Bearer ${user.TOKEN}` },
          }
        );
        setLoadMembers(false);
        setGroupMembersList(data.members);
      } catch (error) {
        setLoadMembers(false);
      }
    };
    if (isDescriptionOpen) {
      fetchUsers();
    }
  }, [isDescriptionOpen]);

  const fetchBranches = async () => {
    await AxiosInstance.get(`/auth/getdata?data=branch`, {
      headers: { Authorization: `Bearer ${user.TOKEN}` },
    })
      .then(response => {
        let branchData = response?.data[0]?.branch;
        const finalData = branchData?.map(val => {
          return { value: val, label: val };
        });
        setBranchList(finalData);
      })
      .catch(error => {});
  };

  const fetchBranch = async () => {
    try {
      const { data } = await AxiosInstance.get(
        `/help/get_stu_branch?studentId=${particularStudent.studentId}`,
        {
          headers: { Authorization: `Bearer ${user.TOKEN}` },
        }
      );

      if (data.branch.length <= 0) {
        toast.warning("student has no branch");
      } else {
        let eee = data?.branch?.map(function (ele) {
          return { value: ele, label: ele };
        });
        setBranchList(eee);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchUser = async () => {
    let branch = selectedBranch ? selectedBranch.value : "";
    try {
      const { data } = await AxiosInstance.get(
        `/help/getUsersBranch/${branch}`,
        {
          headers: { Authorization: `Bearer ${user.TOKEN}` },
        }
      );

      setUsersList(data.allUsers);
    } catch (error) {}
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
      inline: "nearest",
    });
  }, [message]);

  useEffect(() => {
    let audioStream;
    let recorder;

    const startRecording = async () => {
      try {
        audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        recorder = new MediaRecorder(audioStream);
        recorder.addEventListener("dataavailable", handleDataAvailable);
        recorder.start();
        setMediaRecorder(recorder);
        setIsRecording(true);
        // setRecordingStatus("Recording...");
      } catch (error) {
        alert("Error accessing microphone");
        // setStop(true)
        setIsRecording(false);

        console.error("Error accessing microphone:", error);
      }
    };

    const stopRecording = () => {
      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
        mediaRecorder.removeEventListener("dataavailable", handleDataAvailable);
        setIsRecording(false);
        // setRecordingStatus("Recording stopped.");
      }
      if (audioStream) {
        audioStream.getTracks().forEach(track => track.stop());
      }
    };

    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
      setRecordedChunks([]); // Clear recorded
    }

    return () => {
      stopRecording();
    };
  }, [isRecording]);

  const handleDataAvailable = event => {
    if (event.data.size > 0) {
      const audioData = event.data;
      //(event.data);
      //(audioData);
      setRecordedAudio(audioData);
      setRecordedChunks(prevChunks => [...prevChunks, event.data]);
    }
  };

  useEffect(() => {
    async function fetchlist() {
      //(searchapi);
      setLoadConv(true);
      setParticularStudent(null);
      setSelectedExisting(null);

      await AxiosInstance.get(`/help/getAllStudents`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          setLoadConv(false);
          let list = data.data.students;

          if (list?.length === 0) {
            setStopScroll(false);
            setConversationList([]);
          } else if (list?.length > 0) {
            setDefaultStudentList(data.data);
            setConversationList(list);
            const updatedState = {};
            list.forEach(item => {
              updatedState[item.studentId] = {
                notification: item.notification,
              };
            });
            setStudentNotificationStatus(updatedState);
          }
          setLoadConv(false);
        })

        .catch(x => {
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
            setLoadConv(null);
          }
        });
    }
    fetchlist();
  }, [notification]);

  useEffect(() => {
    socket.on("helpNotification", data => {
      setNotification(prev => [...prev, data]);

      setFinalNotiCount(prevState => {
        // Clone the previous state
        const newState = { ...prevState };

        if (newState[data.senderId]) {
          if (newState[data.senderId][data.requestId]) {
            newState[data.senderId][data.requestId] = {
              noticount: newState[data.senderId][data.requestId].noticount + 1,
              notification: true,
            };
          }
        }

        // Return the updated state
        return newState;
      });
    });
  }, [message]);

  useEffect(() => {
    socket.on("recentChats", data => {
      setRecentMsg(data);
    });
  }, []);

  let handleNotification = sid => {
    let data1 = conversationList?.map(x => {
      if (x.userNotification === true) {
        socket.emit("helpmsgRead", {
          receiverId: sid,
          senderId: user?._id,
          seen: "true",
          type: "help",
          role: user?.role1,
          device: "web",
        });
      }

      return x.studentId === sid
        ? x.userNotification === true
          ? {
              ...x,
              userNotification: false,
            }
          : { ...x }
        : x;
    });
    // //(data1);
    setConversationList(data1);
  };

  let Loadmore = () => {
    SetNumber(number + 50);
  };

  let HandleStudents = ele => {
    // //(ele._id);
    setParticularStudent(ele);
    setSelectedUser(ele);
    setBranchUserClicked(false);
    setMessage([]);
  };

  const handlePaste = e => {
    e.preventDefault(); // Prevent the default paste behavior

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text"); // Get the text from the clipboard
    setNewMessage(pastedText);
  };

  // const handleBackspaceUser = event => {
  // if (event.key === "Backspace" && userName.length === 1) {
  //   setSearchTrue(false);
  //   setUsername("");
  //   setUserName("");
  // }
  // };

  return (
    <section className={Styles.HelpBlock}>
      <Modal
        open={openAddUser}
        onClose={() => {
          setSelectedBranch(null);
          setOpenAddUser(false);
          setSelectedUsers([]);
          setSelectedBranch(null);
        }}
      >
        <Box sx={styleModal1} position="relative">
          <button
            style={{
              background: "transparent",
              position: "absolute",
              right: "5px",
              top: "5px",
            }}
            onClick={() => {
              setSelectedBranch(null);
              setOpenAddUser(false);
              setSelectedUsers([]);
              setSelectedBranch(null);
            }}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <h4 style={{ textAlign: "center", color: "#f16f1d" }}>
            Add User to Group
          </h4>
          <Divider />
          <br />
          <form action="" onSubmit={handleAddUserSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Autocomplete
                  fullWidth
                  onOpen={fetchBranch}
                  value={selectedBranch}
                  onChange={(event, newValue) => {
                    setSelectedBranch(newValue);
                  }}
                  size="small"
                  color="warning"
                  // disablePortal
                  options={branchList || []}
                  getOptionLabel={option => option.label || ""}
                  renderInput={params => (
                    <TextField {...params} label="Branch" color="warning" />
                  )}
                />
              </Grid>
              <Grid item={true} sm={12}>
                <Autocomplete
                  fullWidth
                  onOpen={fetchUser}
                  value={selectedUsers}
                  onChange={(event, newValue) => {
                    setSelectedUsers([...newValue]);
                  }}
                  multiple
                  size="small"
                  color="warning"
                  disablePortal
                  options={usersList}
                  getOptionLabel={option =>
                    `${option?.username} - ${option?.role}`
                  }
                  renderInput={params => (
                    <TextField {...params} label="User" color="warning" />
                  )}
                />
              </Grid>
              <Grid
                item
                sm={12}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <Button
                  type="button"
                  size="small"
                  variant="outlined"
                  color="warning"
                  onClick={() => {
                    setSelectedBranch(null);
                    setOpenAddUser(false);
                    setSelectedUsers([]);
                    setSelectedBranch(null);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="warning"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
      <Modal
        open={isDescriptionOpen}
        onClose={() => {
          setIsDescriptionOpen(false);
        }}
      >
        <Box sx={styleModal1} position="relative">
          <button
            style={{
              background: "transparent",
              position: "absolute",
              right: "5px",
              top: "5px",
            }}
            onClick={() => {
              setIsDescriptionOpen(false);
            }}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <h4 style={{ textAlign: "center", color: "#f16f1d" }}>Group Info</h4>
          <Divider />
          <br />
          {user?.role1 === "qtalkHelp" && (
            <button
              onClick={() => {
                submitAddUser();
                setIsDescriptionOpen(false);
              }}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                color: "#f16f1d",
                border: "1px solid #f16f1d",
                padding: "3px",
                borderRadius: "6px",
              }}
            >
              <svg width={18} height={18} viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                  <path d="M24 0v24H0V0zM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036c-.01-.003-.019 0-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z"></path>
                  <path
                    fill="#f16f1d"
                    d="M9 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v4h4a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-4v4a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-4H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h4z"
                  ></path>
                </g>
              </svg>{" "}
              Add User
            </button>
          )}
          <br />
          {loadMembers === true ? (
            "Loading..."
          ) : (
            <div className={Styles.existingListUl}>
              <ul>
                {groupMembersList.map((val, ind) => {
                  return (
                    <li key={ind} style={{ textTransform: "capitalize" }}>
                      {val.username}&nbsp;
                      <small style={{ fontSize: "x-small", color: "gray" }}>
                        ({val?.role[0]})
                      </small>
                      {val?.role[0] === "student" ||
                      val?.role[0] === "qtalkHelp" ? null : (
                        <>
                          {user.role1 === "qtalkHelp" && (
                            <button
                              title="remove user"
                              style={{
                                float: "right",
                                background: "transparent",
                              }}
                              onClick={() => {
                                setOpenRemoveUser(true);
                                setSelectedUserToRemove(val);
                              }}
                            >
                              <svg width={24} height={24} viewBox="0 0 24 24">
                                <path
                                  fill="crimson"
                                  d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12z"
                                ></path>
                              </svg>
                            </button>
                          )}
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </Box>
      </Modal>
      <Modal
        open={openRemoveUser}
        onClose={() => {
          setOpenRemoveUser(false);
          setSelectedUserToRemove(null);
        }}
      >
        <Box sx={styleModal1} position="relative">
          <button
            style={{
              background: "transparent",
              position: "absolute",
              right: "5px",
              top: "5px",
            }}
            onClick={() => {
              setOpenRemoveUser(false);
              setSelectedUserToRemove(null);
            }}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <h6
            style={{
              textAlign: "center",
              color: "#f16f1d",
              fontSize: "large",
            }}
          >
            Click on remove button to remove user from group
          </h6>
          <br />
          <Button
            size="small"
            variant="outlined"
            color="success"
            style={{ float: "right" }}
            onClick={removeUserFunction}
          >
            Remove
          </Button>
        </Box>
      </Modal>
      <div className={Styles.app}>
        <div className={Styles.sidebar}>
          <div className={Styles.search}>
            <div className={Styles.SearchBlock}>
              <span className={Styles.SearchText}>
                <input
                  className={Styles.SearchTextInput}
                  type="text"
                  onKeyPress={handleKeyPress}
                  onKeyDown={handleBackspaceUser}
                  placeholder="Search student"
                  name="searchStu"
                  id="searchStu"
                  value={searchStu}
                  onChange={e => setSearchStu(e?.target?.value)}
                />
              </span>
              &nbsp;
              {isSearchStu === false ? (
                <button
                  className={Styles.searchIcon}
                  onClick={handleSearchStudent}
                >
                  <FiSearch />
                </button>
              ) : (
                <button
                  className={Styles.searchCancel}
                  onClick={handleResetStudent}
                >
                  <ImCross />
                </button>
              )}
            </div>
          </div>

          {/* <!-- List of Contacts --> */}

          <div
            className={Styles.contacts}
            id="scrollableDiv"
            style={{ overflow: "auto" }}
          >
            {branchUserClicked === true ? (
              <>
                {loadBranchUser === null ? (
                  <p style={{ textAlign: "center", color: "black" }}>
                    something went wrong
                  </p>
                ) : loadBranchUser ? (
                  <p style={{ textAlign: "center", color: "black" }}>
                    Loading...
                  </p>
                ) : (
                  branchUserList.map((ele, ind) => {
                    return (
                      <>
                        <div
                          className={Styles.message}
                          style={{
                            backgroundImage: `url('${bg2}')`,
                            width: "98%",
                          }}
                          onClick={() => {
                            HandleStudents(ele);
                          }}
                        >
                          <div className={Styles.senderinfo}>
                            {ele.username}
                          </div>
                        </div>
                      </>
                    );
                  })
                )}
              </>
            ) : (
              <>
                {loadConv === null ? (
                  <p style={{ textAlign: "center", color: "black" }}>
                    something went wrong
                  </p>
                ) : loadConv ? (
                  <p style={{ textAlign: "center", color: "black" }}>
                    Loading...
                  </p>
                ) : conversationList.length === 0 ? (
                  <p style={{ textAlign: "center", color: "black" }}>
                    Result not found
                  </p>
                ) : (
                  <InfiniteScroll
                    dataLength={conversationList?.length}
                    // next={Loadmore}
                    // hasMore={stopscroll}
                    // hasMore={true}
                    // loader={<h4>Loading...</h4>}
                    scrollableTarget="scrollableDiv"
                  >
                    {conversationList?.length > 0 &&
                      conversationList
                        ?.filter(item =>
                          item.username
                            ?.toLowerCase()
                            ?.includes(searchapi?.toLowerCase())
                        )
                        .map((ele, ind) => {
                          // //(ele);
                          return (
                            <Fragment key={ind}>
                              <div
                                style={{
                                  backgroundImage: `url('${bg6}')`,
                                  width: "98%",
                                  borderLeft:
                                    ele.studentId ===
                                    particularStudent?.studentId
                                      ? "5px solid #f16f1d"
                                      : "",
                                }}
                                className={Styles.message}
                                onClick={() => {
                                  setParticularStudent(ele);
                                  setShowOldChats(false);
                                  setSelectedUser(ele);
                                  handleNotification(ele.studentId);
                                  setStop(false);
                                  setIsPlaying(false);
                                  setRecordedAudio(null);
                                  setIsPlaybackActive(false);
                                  setIsAudioVisible(false); // Hide the audio tag
                                  setRecordedChunks([]); // Clear the recorded audio chunks
                                  setIsPlaying(false);
                                  setIsRecording(false);
                                  setSelectedExisting(null);
                                  setRemoveNotification([]);
                                  socket.emit("chatwith", {
                                    senderId: user?._id,
                                    role: "qtalkHelp",
                                    receiverId: null,
                                    device: "web",
                                  });
                                }}
                              >
                                <div className={Styles.senderinfo}>
                                  <div className={Styles.sendername}>
                                    {ele.username}{" "}
                                  </div>
                                  {studentNotificationStatus[ele.studentId]
                                    .notification ? (
                                    <div className={Styles.timestamp}></div>
                                  ) : null}
                                </div>
                              </div>
                            </Fragment>
                          );
                        })}
                  </InfiniteScroll>
                )}
              </>
            )}
          </div>
        </div>
        {particularStudent?.studentId ? (
          <div className={Styles.chatarea}>
            <div className={Styles.NameBlock}>
              <div className={Styles.HEadBlockName}>
                <span className={Styles.HeadName} style={{ width: "50%" }}>
                  <h6 style={{ textTransform: "capitalize" }}>
                    {selectedUser?.username}
                  </h6>
                </span>
                <span className={Styles.HeadDeatils}>
                  <h6>Chats are monitored by admin</h6>
                </span>
              </div>
            </div>
            <aside className={Styles.ChatBlock}>
              <div className={Styles.helpChatTitleList}>
                <h4 style={{ textAlign: "center", color: "#f16f1d" }}>
                  Ticket List
                </h4>
                <Divider />
                {existingTitleList && existingTitleList.length > 2 ? (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Search Title"
                      value={titleSearchTerm}
                      onChange={e => {
                        setTitleSearchTerm(e.target.value);
                      }}
                      onKeyPress={() => setSearchTrueList(true)}
                      onKeyDown={event => {
                        if (
                          event.key === "Backspace" &&
                          titleSearchTerm.length === 1
                        ) {
                          setSearchTrueList(false);
                          setTitleSearchTerm("");
                        }
                      }}
                      style={{
                        borderRadius: "10px",
                        padding: "2px 6px",
                        width: "90%",
                        border: "1px solid #f16f1d",
                      }}
                    />
                    {searchTrueList ? (
                      <button
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        title="click to remove search"
                        onClick={() => {
                          setSearchTrueList(false);
                          setTitleSearchTerm("");
                        }}
                      >
                        <svg width={22} height={22} viewBox="0 0 24 24">
                          <path
                            fill="crimson"
                            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
                          ></path>
                        </svg>
                      </button>
                    ) : (
                      <button
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        title="click to search"
                        onClick={() => {
                          if (titleSearchTerm === "") {
                            toast.warn("Please enter title");
                          } else {
                            setSearchTrueList(true);
                          }
                        }}
                      >
                        <svg width={22} height={18} viewBox="0 0 24 24">
                          <path
                            fill="#f16f1d"
                            d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5q0-1.875-1.312-3.187T9.5 5Q7.625 5 6.313 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14"
                          ></path>
                        </svg>
                      </button>
                    )}
                  </div>
                ) : null}

                <br />
                <main className={Styles.existingListUl}>
                  <ul>
                    {loadExisting === null ? (
                      <p style={{ textAlign: "center" }}>
                        something went wrong
                      </p>
                    ) : loadExisting ? (
                      <p style={{ textAlign: "center" }}>Loading...</p>
                    ) : existingTitleList === null ? (
                      <p style={{ textAlign: "center" }}>No New Ticket Found</p>
                    ) : (
                      existingTitleList
                        ?.filter(val =>
                          searchTrueList
                            ? val.title
                                ?.toLowerCase()
                                ?.includes(titleSearchTerm?.toLowerCase())
                            : true
                        )
                        .map((item, ind) => {
                          const allNotificationsAreFalse =
                            item.conversation.every(
                              notification => !notification.notification
                            );

                          if (allNotificationsAreFalse) {
                            const isEntryExists = allNotificationsFalse.some(
                              entry =>
                                entry.studentId === particularStudent.studentId
                            );

                            if (!isEntryExists) {
                              setAllNotificationsFalse(prevState => [
                                ...prevState,
                                {
                                  studentId: particularStudent.studentId,
                                  notification: false,
                                },
                              ]);
                            }
                          }

                          return (
                            <li
                              key={ind + 1}
                              onClick={() => {
                                setSelectedExisting(item);
                                setShowOldChats(false);

                                socket.emit("notification", {
                                  role: user?.role1,
                                  studentId: particularStudent.studentId,
                                  userId: user?._id,
                                  requestId: item.requestId,
                                  seen: true,
                                });
                                updateNotificationState(
                                  particularStudent.studentId,
                                  item.requestId
                                );
                              }}
                              style={{
                                fontSize: "small",
                                border:
                                  selectedExisting?.requestId === item.requestId
                                    ? "2px solid green"
                                    : "",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    color: "#888",
                                    fontSize: "12px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item?.section}
                                </div>
                                {/* <div>{item?.title}&nbsp;</div> */}
                                <div title={item?.title}>
                                  Title: {item?.title?.slice(0, 20)}
                                  {item?.title?.length > 20 ? "..." : ""}
                                </div>
                                {item?.escalate_user && (
                                  <>
                                    <div
                                      style={{
                                        color: "#555",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Escalated user details
                                    </div>
                                    <div
                                      style={{
                                        color: "#888",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Name: {item?.escalate_user?.username}
                                    </div>
                                    <div
                                      style={{
                                        color: "#888",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Email:{" "}
                                      {item?.escalate_user?.email.map(x => x)}
                                    </div>
                                    <div
                                      style={{
                                        color: "#888",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Number:{" "}
                                      {item?.escalate_user?.number.map(x => x)}
                                    </div>
                                    <div
                                      style={{
                                        color: "#888",
                                        fontSize: "12px",
                                      }}
                                      title={item?.escalate_user?.branch.join(
                                        ", "
                                      )}
                                    >
                                      Role:{" "}
                                      {item?.escalate_user?.role &&
                                        item?.escalate_user?.role
                                          .slice(0, 2)
                                          .join(", ")}
                                      {item?.escalate_user?.branch.length > 2
                                        ? "..."
                                        : ""}
                                    </div>
                                    <div
                                      style={{
                                        color: "#888",
                                        fontSize: "12px",
                                      }}
                                      title={item?.escalate_user?.branch.join(
                                        ", "
                                      )}
                                    >
                                      Branch:{" "}
                                      {item?.escalate_user?.branch
                                        .slice(0, 2)
                                        .join(", ")}
                                      {item?.escalate_user?.branch.length > 2
                                        ? "..."
                                        : ""}
                                    </div>
                                  </>
                                )}
                              </div>
                              {item.conversation.length > 0 ? (
                                <button
                                  style={{ fontSize: "small" }}
                                  type="button"
                                >
                                  {getNoticount(
                                    particularStudent.studentId,
                                    item.requestId
                                  )}
                                </button>
                              ) : null}
                              {item.escalate_user &&
                              item.escalate_user != null ? (
                                ""
                              ) : item.members > 2 ||
                                addedUserToGroup.includes(item.requestId) ? (
                                <button
                                  style={{
                                    float: "right",
                                    background: "transparent",
                                  }}
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleClickViewDescription(item);
                                  }}
                                  title="view description"
                                >
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 16 16"
                                  >
                                    <g
                                      fill="none"
                                      stroke="#f16f1d"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="3"
                                    >
                                      <circle cx="8" cy="2.5" r=".75" />
                                      <circle cx="8" cy="8" r=".75" />
                                      <circle cx="8" cy="13.5" r=".75" />
                                    </g>
                                  </svg>
                                </button>
                              ) : (
                                <button
                                  title="add user"
                                  style={{
                                    float: "right",
                                    background: "transparent",
                                  }}
                                  onClick={e => {
                                    e.stopPropagation();
                                    submitAddUser();
                                    setIsDescriptionOpen(false);
                                    setSelectRequestId(item);
                                  }}
                                >
                                  <svg
                                    width={18}
                                    height={18}
                                    viewBox="0 0 24 24"
                                  >
                                    <g fill="none" fillRule="evenodd">
                                      <path d="M24 0v24H0V0zM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036c-.01-.003-.019 0-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z"></path>
                                      <path
                                        fill="#f16f1d"
                                        d="M9 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v4h4a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-4v4a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-4H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h4z"
                                      ></path>
                                    </g>
                                  </svg>
                                </button>
                              )}
                            </li>
                          );
                        })
                    )}
                    <li
                      onClick={() => {
                        setShowOldChats(true);
                        setSelectedExisting(null);
                      }}
                    >
                      Old General Queries
                    </li>
                  </ul>
                </main>
              </div>
              {showOldChats ? (
                <OldChats particularStudent={particularStudent} />
              ) : selectedExisting === null ? null : (
                <ChatComp
                  existingTitle={selectedExisting}
                  particularStudent={particularStudent}
                />
              )}
            </aside>
          </div>
        ) : (
          <span className={Styles.noConversationText}>
            <p style={{ color: "#f16f1d" }}>
              Open a conversation to start a chat
            </p>
            <div>
              {" "}
              <span>
                {" "}
                <BsChatDots />
              </span>
              Chats Are Monitored By Admin
            </div>
          </span>
        )}
      </div>
    </section>
  );
};

export default UserHelp;
