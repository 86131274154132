import React, { useEffect, useState } from "react";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import Styles from "./_navbar.module.css";
import { getRandomColor, createImageFromInitials } from "./profilePic";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import { Modal, Whisper } from "rsuite";
import {
  Button as MUIButton,
  Modal as MUIModal,
  useMediaQuery,
  Box,
} from "@mui/material";
import Cookies from "js-cookie";
import { socket } from "../../ioContext/socketIo";
import { useDispatch, useSelector } from "react-redux";
import { StudentlogOut } from "../../redux/auth/AuthSlice";
import AxiosInstance, { testFreshers, videoApp } from "../../api/AxiosInstance";

const menuStyle = {
  borderBottom: "2px solid #f16f1d",
  color: "#f16f1d",
  fontWeight: "bold",
};

const UserMenu = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let IsCookie = sessionStorage.getItem("isCookie");
  const location = useLocation();
  let { isLogoutSuccess } = useSelector(state => state.auth);
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let name = user && user?.username;
  const [selectedMenu, setSelectedMenu] = useState("/user-dashboard");
  // notification socket
  const [brandNotification, setBrandNotification] = useState([]);
  const [helpNoti, setHelpNoti] = useState(false);
  useEffect(() => {
    socket.on("broadcastnotification", data => {
      setBrandNotification([123]);
    });
  }, []);

  useEffect(() => {
    const fetchNoti = async () => {
      const { data } = await AxiosInstance.get(`/chat/getUserNotification`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      });

      let noti = data.user?.userGetNotification;
      if (noti) {
        setBrandNotification([123]);
      }
    };
    fetchNoti();
  }, []);
  useEffect(() => {
    socket.on("helpNotification", data => {
      if (data) {
        setHelpNoti(true);
      }
      // setNotification(prev => [...prev, data]);
    });
    return () => {
      socket.off("helpNotification", handleNotification);
    };
  }, [socket]);
  let handleNotification = () => {
    setHelpNoti(false);
  };
  useEffect(() => {
    const fetchNoti = async () => {
      const { data } = await AxiosInstance.get(`help/helpgroup_notification`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      });

      setHelpNoti(data?.helpgroup_notification);
    };
    fetchNoti();
  }, [selectedMenu]);

  // media query
  const isMobile = useMediaQuery("(max-width: 620px)");
  const [showSideBar, setShowSideBar] = useState(false);
  const handleShowSideBar = () => {
    setShowSideBar(prevState => !prevState);
  };

  useEffect(() => {
    setSelectedMenu(location.pathname);
  }, [location]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  //logout
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const IsStudentLogout = () => {
    let finaldeviceId = Cookies.get("device");
    let payload = { deviceId: finaldeviceId };
    socket.emit("logoutuser", {
      userId: user?._id,

      device: "web",
    });
    dispatch(StudentlogOut(payload));

    if (socket) {
      socket.disconnect();
    }
    navigate("/");
  };

  let handleLogout = () => {
    IsStudentLogout();
  };
  useEffect(() => {
    if (isLogoutSuccess) {
      window.location.reload();
      navigate("/");
    }
  }, [isLogoutSuccess]);

  // demo videos
  const handleButtonClick = async () => {
    try {
      let app = "Qtalk";
      Cookies.set("QToken", user?.TOKEN, {
        domain: ".qspiders.com",
      });
      const otherProjectURL = `${videoApp}/user/videoList?app=${encodeURIComponent(
        app
      )}`;

      window.open(otherProjectURL, "_blank");
    } catch (error) {
      console.error("Error occurred while making the API call", error);
      // Handle errors such as network issues, etc.
    }
  };

  return (
    <section>
      <Modal
        backdrop="static"
        role="alertdialog"
        open={open}
        onClose={handleClose}
        size="xs"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal.Body style={{ fontSize: "16px" }}>
          Are You Sure You Want To Logout?
        </Modal.Body>
        <Modal.Footer>
          <MUIButton
            size="small"
            color="warning"
            variant="contained"
            onClick={handleLogout}
          >
            OK
          </MUIButton>{" "}
          &nbsp;
          <MUIButton size="small" variant="outlined" onClick={handleClose}>
            Cancel
          </MUIButton>
        </Modal.Footer>
      </Modal>
      {isMobile ? (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            style={{
              marginRight: "0.7rem",
              fontSize: "1.5rem",
              color: "#3C3838",
            }}
            viewBox="0 0 24 24"
            onClick={handleShowSideBar}
          >
            <path
              fill="currentColor"
              d="M4 6a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1m0 12a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1m1-7a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2z"
            />
          </svg>
          {showSideBar && (
            <Box
              style={{
                height: "100vh",
                width: "100%",
                position: "absolute",
                zIndex: "2",
                backgroundColor: "white",
                left: "1px",
                top: "0px",
                Margin: "20px",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  height: "7.5vh",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    height: "24px",
                    width: "24px",
                    backgroundColor: "#F4F4F4",
                    borderRadius: "50%",
                    display: "inline-block",
                    marginRight: "1.2rem",
                    justifyContent: "center",
                  }}
                  onClick={handleShowSideBar}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    style={{
                      marginRight: "0.7rem",
                      fontSize: "1.5rem",
                      color: "#3C3838",
                    }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M6.758 17.243L12.001 12m5.243-5.243L12 12m0 0L6.758 6.757M12.001 12l5.243 5.243"
                    />
                  </svg>
                </span>
              </Box>
              <Box sx={{ margin: "16px" }}>
                <NavLink
                  to="/user-dashboard/"
                  title="Batch List"
                  style={({ isActive }) => ({
                    textDecoration: "none",
                    color: "inherit",
                    color: isActive ? "#f96900" : "black",
                    border: isActive && "none",
                  })}
                  onClick={showSideBar}
                  exact
                  activeClassName="active-link"
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={35}
                        height={35}
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="none"
                          stroke="#333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 9h8m-8 3h8m-8 3h3m10-3a9 9 0 0 1-13.815 7.605L3 21l1.395-4.185A9 9 0 1 1 21 12"
                        ></path>
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Batch List
                    </span>
                  </Box>
                </NavLink>
                <NavLink
                  to="/user-dashboard/usergrouplist"
                  title="Group list page"
                  style={({ isActive }) => ({
                    textDecoration: "none",
                    color: "inherit",
                    color: isActive ? "#f96900" : "black",
                  })}
                  onClick={showSideBar}
                  exact
                  activeClassName="active-link"
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={35}
                        height={35}
                        viewBox="0 0 256 256"
                      >
                        <path
                          fill="#333"
                          d="M152 80a12 12 0 0 1 12-12h80a12 12 0 0 1 0 24h-80a12 12 0 0 1-12-12m92 36h-80a12 12 0 0 0 0 24h80a12 12 0 0 0 0-24m0 48h-56a12 12 0 0 0 0 24h56a12 12 0 0 0 0-24m-88.38 25a12 12 0 1 1-23.24 6c-5.72-22.23-28.24-39-52.38-39s-46.66 16.76-52.38 39a12 12 0 1 1-23.24-6c5.38-20.9 20.09-38.16 39.11-48a52 52 0 1 1 73 0c19.04 9.85 33.75 27.11 39.13 48M80 132a28 28 0 1 0-28-28a28 28 0 0 0 28 28"
                        ></path>
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Group List
                    </span>
                  </Box>
                </NavLink>
                <NavLink
                  to="/user-dashboard/userConversation"
                  title="User to user chat"
                  style={({ isActive }) => ({
                    textDecoration: "none",
                    color: "inherit",
                    color: isActive ? "#f96900" : "black",
                  })}
                  onClick={showSideBar}
                  exact
                  activeClassName="active-link"
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={35}
                        height={35}
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="none"
                          stroke="#333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="m13 18l-5 3v-3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v4.5M17 17a2 2 0 1 0 4 0a2 2 0 1 0-4 0m5 5a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2"
                        ></path>
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      User chat
                    </span>
                  </Box>
                </NavLink>
                <NavLink
                  to="/user-dashboard/creategroup"
                  title="Create Group"
                  style={({ isActive }) => ({
                    textDecoration: "none",
                    color: "inherit",
                    color: isActive ? "#f96900" : "black",
                  })}
                  onClick={showSideBar}
                  exact
                  activeClassName="active-link"
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={35}
                        height={35}
                        viewBox="0 0 24 24"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path d="M24 0v24H0V0zM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z"></path>
                          <path
                            fill="#333"
                            d="M12 12c1.873 0 3.57.62 4.815 1.487c1.183.825 2.185 2.051 2.185 3.37c0 .724-.309 1.324-.796 1.77c-.458.421-1.056.694-1.672.88C15.301 19.88 13.68 20 12 20s-3.301-.12-4.532-.493c-.616-.186-1.214-.459-1.673-.88C5.31 18.182 5 17.582 5 16.858c0-1.319 1.002-2.545 2.185-3.37C8.43 12.62 10.127 12 12 12m0 2c-1.44 0-2.743.48-3.67 1.127c-.989.69-1.33 1.392-1.33 1.73c0 .304.352.494.672.614l.205.07l.17.052c.94.284 2.32.407 3.953.407c1.508 0 2.799-.105 3.728-.344l.304-.087l.19-.06c.343-.117.778-.314.778-.652s-.341-1.04-1.33-1.73C14.744 14.481 13.44 14 12 14m7-1c1.044 0 1.992.345 2.693.833c.64.447 1.307 1.19 1.307 2.096c0 1.335-1.297 1.813-2.463 1.98l-.3.037l-.289.025l-.138.008c.122-.345.19-.72.19-1.122q-.002-.458-.107-.888c.386-.03.703-.08.939-.151c.104-.032.01-.13-.1-.215l-.107-.078l-.076-.051a2.7 2.7 0 0 0-.995-.418c-.38-.76-.964-1.418-1.586-1.943A4.8 4.8 0 0 1 19 13M5 13q.537.002 1.032.113c-.622.525-1.206 1.183-1.586 1.943a2.7 2.7 0 0 0-.995.418l-.128.088c-.127.092-.276.22-.155.256c.236.071.553.122.94.151Q4 16.4 4 16.857c0 .402.068.777.19 1.122l-.28-.02l-.296-.03c-1.202-.147-2.614-.607-2.614-2c0-.905.666-1.649 1.307-2.096A4.76 4.76 0 0 1 5 13m13.5-6a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5m-13 0a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5M12 3a4 4 0 1 1 0 8a4 4 0 0 1 0-8m6.5 6a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1m-13 0a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1M12 5a2 2 0 1 0 0 4a2 2 0 0 0 0-4"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Create Group
                    </span>
                  </Box>
                </NavLink>
                {(user?.role1 === "hr" ||
                  user?.role1 === "trainer" ||
                  user?.role1 === "human resource") && (
                  <NavLink
                    to="/user-dashboard/students"
                    title="Students List page"
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: "inherit",
                      color: isActive ? "#f96900" : "black",
                    })}
                    onClick={showSideBar}
                    exact
                    activeClassName="active-link"
                  >
                    <Box
                      className={Styles.conatinerName}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={35}
                          height={35}
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="none"
                            stroke="#333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={52.5}
                            d="M160 144h288M160 256h288M160 368h288"
                          ></path>
                          <circle
                            cx={80}
                            cy={144}
                            r={16}
                            fill="none"
                            stroke="#333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={32}
                          ></circle>
                          <circle
                            cx={80}
                            cy={256}
                            r={16}
                            fill="none"
                            stroke="#333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={32}
                          ></circle>
                          <circle
                            cx={80}
                            cy={368}
                            r={16}
                            fill="none"
                            stroke="#333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={32}
                          ></circle>
                        </svg>
                      </span>
                      <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                        Students List
                      </span>
                    </Box>
                  </NavLink>
                )}
                <NavLink
                  to="/user-dashboard/status"
                  title="Status page"
                  style={({ isActive }) => ({
                    textDecoration: "none",
                    color: "inherit",
                    color: isActive ? "#f96900" : "black",
                  })}
                  onClick={showSideBar}
                  exact
                  activeClassName="active-link"
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={35}
                        height={35}
                        viewBox="0 0 56 56"
                      >
                        <path
                          fill="#333"
                          d="m50.923 21.002l.046.131l.171.566l.143.508l.061.232l.1.42a23.93 23.93 0 0 1-2.653 17.167a23.93 23.93 0 0 1-13.57 10.89l-.404.12l-.496.128l-.717.17a1.89 1.89 0 0 1-2.288-1.558a2.127 2.127 0 0 1 1.606-2.389l.577-.145q.54-.142.929-.273a19.93 19.93 0 0 0 10.899-8.943a19.93 19.93 0 0 0 2.292-13.923l-.069-.313l-.092-.365l-.115-.418l-.138-.47a2.135 2.135 0 0 1 1.26-2.602a1.894 1.894 0 0 1 2.458 1.067M7.385 19.92q.065.02.128.044A2.127 2.127 0 0 1 8.78 22.55q-.27.909-.39 1.513a19.93 19.93 0 0 0 2.295 13.91a19.93 19.93 0 0 0 10.911 8.947l.306.097l.174.05l.39.106l.694.171a2.135 2.135 0 0 1 1.623 2.393a1.894 1.894 0 0 1-2.152 1.594l-.138-.025l-.576-.135l-.51-.13l-.446-.125l-.2-.06A23.93 23.93 0 0 1 7.22 39.972a23.93 23.93 0 0 1-2.647-17.197l.077-.32l.1-.375l.194-.665l.076-.25a1.89 1.89 0 0 1 2.365-1.246M28.051 12c8.837 0 16 7.163 16 16s-7.163 16-16 16s-16-7.163-16-16s7.164-16 16-16m0 4c-6.627 0-12 5.373-12 12s5.373 12 12 12c6.628 0 12-5.373 12-12s-5.372-12-12-12m0-12a23.93 23.93 0 0 1 16.217 6.306l.239.227l.275.274l.31.322l.346.369a1.89 1.89 0 0 1-.205 2.76a2.127 2.127 0 0 1-2.873-.196q-.326-.345-.605-.617l-.35-.334l-.16-.143A19.93 19.93 0 0 0 28.051 8a19.93 19.93 0 0 0-13.204 4.976l-.114.102l-.253.24l-.287.285l-.495.515c-.76.809-2.014.9-2.883.21a1.894 1.894 0 0 1-.305-2.662l.09-.106l.405-.431l.368-.378q.262-.263.484-.465A23.93 23.93 0 0 1 28.05 4"
                        ></path>
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Status
                    </span>
                  </Box>
                </NavLink>
                <NavLink
                  to="/user-dashboard/spideyaura"
                  title="Spidey aura page"
                  style={({ isActive }) => ({
                    textDecoration: "none",
                    color: "inherit",
                    color: isActive ? "#f96900" : "black",
                  })}
                  onClick={showSideBar}
                  exact
                  activeClassName="active-link"
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={35}
                        height={35}
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#333"
                          d="M12 2C6.47 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8m-4-6L7 8l3 2l2-3l2 3l3-2l-1 6zm.56 2c-.34 0-.56-.22-.56-.56V15h8v.44c0 .34-.22.56-.56.56z"
                        ></path>
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Spidey Aura
                    </span>
                    {brandNotification.length > 0 && (
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          right: "-7px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 48 48"
                        >
                          <path
                            fill="green"
                            stroke="green"
                            stroke-width="4"
                            d="M24 33a9 9 0 1 0 0-18a9 9 0 0 0 0 18Z"
                          />
                        </svg>
                      </div>
                    )}
                  </Box>
                </NavLink>

                {(user?.role1 === "hr" || user?.role1 === "human resource") && (
                  <span onClick={handleButtonClick}>
                    {" "}
                    <NavLink
                      title="Demo"
                      to={"/user-dashboard/demo"}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Box
                        className={Styles.conatinerName}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={35}
                          height={35}
                          viewBox="0 0 24 24"
                        >
                          <g fill="none" stroke="#333" strokeWidth={1.5}>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.196 7.873h17.608m-4.997 0V2.877M8.193 7.873V2.877m1.947 9.051v4.922c0 .101.032.2.091.286c.06.085.145.154.246.199a.66.66 0 0 0 .633-.057l3.798-2.65a.56.56 0 0 0 .176-.199a.5.5 0 0 0-.02-.492a.6.6 0 0 0-.192-.186l-3.798-2.272a.66.66 0 0 0-.616-.025a.6.6 0 0 0-.232.198a.5.5 0 0 0-.086.276"
                            ></path>
                            <rect
                              width={18.5}
                              height={18.5}
                              x={2.75}
                              y={2.75}
                              rx={6}
                            ></rect>
                          </g>
                        </svg>
                        <span
                          style={{ marginLeft: "15px", marginBottom: "4px" }}
                        >
                          Demo
                        </span>
                      </Box>
                    </NavLink>
                  </span>
                )}

                <NavLink
                  style={{ textDecoration: "none", color: "inherit" }}
                  onClick={e => {
                    setOpen(true);
                  }}
                  to={"/student-dashboard/logout"}
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={35}
                        height={35}
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#333"
                          d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h6q.425 0 .713.288T12 4t-.288.713T11 5H5v14h6q.425 0 .713.288T12 20t-.288.713T11 21zm12.175-8H10q-.425 0-.712-.288T9 12t.288-.712T10 11h7.175L15.3 9.125q-.275-.275-.275-.675t.275-.7t.7-.313t.725.288L20.3 11.3q.3.3.3.7t-.3.7l-3.575 3.575q-.3.3-.712.288t-.713-.313q-.275-.3-.262-.712t.287-.688z"
                        ></path>
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Logout
                    </span>
                  </Box>
                </NavLink>
              </Box>
            </Box>
          )}
        </div>
      ) : (
        <aside className={Styles.StudentNavBlock}>
          {user?.role1 === "hr" ||
          user?.role1 == "human resource" ||
          user?.role1 === "trainer" ||
          user?.role1 === "tracker" ||
          user?.role1 === "feetracker" ||
          user?.role1 === "counselor" ? (
            <>
              <div className={Styles.HomeBlock}>
                {" "}
                <span>
                  <Link
                    to={"/user-dashboard"}
                    className={Styles.StudHome}
                    style={selectedMenu === "/user-dashboard" ? menuStyle : {}}
                    state={{ model: true, chat: false }}
                  >
                    Batch List
                  </Link>
                </span>
              </div>
              <div className={Styles.HomeBlock}>
                {" "}
                <span>
                  <Link
                    to={"/user-dashboard/usergrouplist"}
                    className={Styles.StudHome}
                    style={
                      selectedMenu === "/user-dashboard/usergrouplist"
                        ? menuStyle
                        : {}
                    }
                    state={{ model: true, chat: false }}
                  >
                    Group List
                  </Link>
                </span>
              </div>
              <div className={Styles.HomeBlock}>
                {" "}
                <span>
                  <Link
                    to={"/user-dashboard/userConversation"}
                    className={Styles.StudHome}
                    style={
                      selectedMenu === "/user-dashboard/userConversation"
                        ? menuStyle
                        : {}
                    }
                    state={{ model: true, chat: false }}
                  >
                    User chat
                  </Link>
                </span>
              </div>
              <div className={Styles.HomeBlock}>
                {" "}
                <span>
                  <Link
                    to={"/user-dashboard/creategroup"}
                    className={Styles.StudHome}
                    style={
                      selectedMenu === "/user-dashboard/creategroup"
                        ? menuStyle
                        : {}
                    }
                    state={{ model: true, chat: false }}
                  >
                    Create Group
                  </Link>
                </span>
              </div>
              {(user?.role1 === "hr" ||
                user?.role1 === "trainer" ||
                user?.role1 === "human resource") && (
                <div className={Styles.HomeBlock}>
                  {" "}
                  <span>
                    <Link
                      to={"/user-dashboard/students"}
                      className={Styles.StudHome}
                      style={
                        selectedMenu === "/user-dashboard/students"
                          ? menuStyle
                          : {}
                      }
                      state={{ model: true, chat: false }}
                    >
                      Students List
                    </Link>
                  </span>
                </div>
              )}
              <div className={Styles.HomeBlock}>
                {" "}
                <span>
                  <Link
                    to={"/user-dashboard/status"}
                    className={Styles.StudHome}
                    style={
                      selectedMenu === "/user-dashboard/status" ? menuStyle : {}
                    }
                    state={{ model: true, chat: false }}
                  >
                    Status
                  </Link>
                </span>
              </div>
              <div
                className={Styles.HomeBlock}
                style={{ position: "relative" }}
              >
                {" "}
                <span>
                  <Link
                    to={"/user-dashboard/spideyaura"}
                    className={Styles.StudHome}
                    style={
                      selectedMenu === "/user-dashboard/spideyaura"
                        ? menuStyle
                        : {}
                    }
                    state={{ model: true, chat: false }}
                  >
                    Spidey Aura
                  </Link>
                </span>
                {brandNotification.length > 0 && (
                  <div
                    style={{ position: "absolute", top: "0", right: "-7px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="green"
                        stroke="green"
                        stroke-width="4"
                        d="M24 33a9 9 0 1 0 0-18a9 9 0 0 0 0 18Z"
                      />
                    </svg>
                  </div>
                )}
              </div>

              <div
                className={Styles.HomeBlock}
                style={{ position: "relative" }}
                onClick={() => handleNotification()}
              >
                {" "}
                <Link
                  to={"/help-dashboard"}
                  className={Styles.StudHome}
                  style={selectedMenu === "/help-dashboard" ? menuStyle : {}}
                  state={{ model: true, chat: false }}
                >
                  Help
                </Link>
                {helpNoti && (
                  <div
                    style={{ position: "absolute", top: "0", right: "-7px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="green"
                        stroke="green"
                        stroke-width="4"
                        d="M24 33a9 9 0 1 0 0-18a9 9 0 0 0 0 18Z"
                      />
                    </svg>
                  </div>
                )}
              </div>

              {(user?.role1 === "hr" || user?.role1 === "human resource") && (
                <div className={Styles.HomeBlock} onClick={handleButtonClick}>
                  {" "}
                  <span>
                    <Link className={Styles.StudHome}>Demo</Link>
                  </span>
                </div>
              )}
            </>
          ) : (
            <>
              {user?.role1 === "branding" ? (
                <div className={Styles.HomeBlock}>
                  {" "}
                  <Link
                    to={"/spideyhome"}
                    className={Styles.StudHome}
                    style={selectedMenu === "/spideyhome" ? menuStyle : {}}
                    state={{ model: true, chat: false }}
                  >
                    Home
                  </Link>
                </div>
              ) : (
                <div className={Styles.HomeBlock}>
                  {" "}
                  <Link
                    to={"/help-dashboard"}
                    className={Styles.StudHome}
                    style={selectedMenu === "/help-dashboard" ? menuStyle : {}}
                    state={{ model: true, chat: false }}
                  >
                    Home
                  </Link>
                </div>
              )}
              <div className={Styles.HomeBlock}>
                {" "}
                <span>
                  <Link
                    to={"/user-dashboard/userConversation"}
                    className={Styles.StudHome}
                    style={
                      selectedMenu === "/user-dashboard/userConversation"
                        ? menuStyle
                        : {}
                    }
                    state={{ model: true, chat: false }}
                  >
                    User chat
                  </Link>
                </span>
              </div>
              <div className={Styles.HomeBlock}>
                {" "}
                <span>
                  <Link
                    to={"/user-dashboard/status"}
                    className={Styles.StudHome}
                    style={
                      selectedMenu === "/user-dashboard/status" ? menuStyle : {}
                    }
                    state={{ model: true, chat: false }}
                  >
                    Status
                  </Link>
                </span>
              </div>
            </>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <span> */}
            <React.Fragment>
              {/* <Tooltip title="Account settings"> */}
              <IconButton
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open1 ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={handleClick}
              >
                <img
                  loading="lazy"
                  id={Styles.preview}
                  src={createImageFromInitials(500, name, getRandomColor())}
                  alt="profile-pic"
                  width="100%"
                  height="100%"
                />
              </IconButton>
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2.5"
                  d="m7 10l5 5m0 0l5-5"
                />
              </svg> */}
              {/* </Tooltip> */}
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open1}
                onClose={handleClose1}
                onClick={handleClose1}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={() => navigate("/user-dashboard/profile")}>
                  <Avatar sx={{ background: "#2677B0", color: "#ECECEC" }} />{" "}
                  View Profile
                </MenuItem>
                <Divider />

                {!IsCookie && (
                  <MenuItem
                    onClick={e => {
                      setOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <span
                        title="logout"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <svg
                          width={26}
                          height={26}
                          viewBox="0 0 26 26"
                          strokeWidth={3}
                        >
                          <path
                            fill="crimson"
                            d="M13 0a2 2 0 0 0-2 2v10a2 2 0 0 0 4 0V2a2 2 0 0 0-2-2M5.844 2.594a1.5 1.5 0 0 0-.782.344A12.796 12.796 0 0 0 .188 13C.188 20.058 5.94 25.813 13 25.813S25.813 20.058 25.813 13c0-4.073-1.902-7.717-4.875-10.063a1.5 1.5 0 1 0-1.875 2.344A9.804 9.804 0 0 1 22.813 13A9.789 9.789 0 0 1 13 22.813A9.789 9.789 0 0 1 3.187 13a9.808 9.808 0 0 1 3.75-7.719a1.5 1.5 0 0 0-1.093-2.687"
                          ></path>
                        </svg>
                      </span>{" "}
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                )}
              </Menu>
            </React.Fragment>
            {/* </span> */}
          </div>
        </aside>
      )}
    </section>
  );
};

export default UserMenu;
