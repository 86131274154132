import React, { useEffect, useState } from "react";
import Styles from "./_admin-login.module.css";
import { AdminWithLogin, reset } from "../../redux/auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { ImMail } from "react-icons/im";
import { MdPrivacyTip } from "react-icons/md";
import Style from "./_footer.module.css";

import { BiUser } from "react-icons/bi";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { RotatingLines } from "react-loader-spinner";
import { Box, Button, TextField, Typography } from "@mui/material";
import styles from "./LoginForm.module.css";
const schema = yup.object().shape({
  password: yup.string().required("password is required"),
  number: yup
    .string()
    .matches(/^[0-9]{10}$/, "Must be exactly 10 digits")
    .required("Number is required"),
});

const AdminLogin = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let { isLoading, isSuccess, isError, message, user, userAdmin } = useSelector(
    state => state.auth
  );

  const [isRevealPwd, setIsRevealPwd] = useState(false);

  useEffect(() => {
    if (isError) {
      toast.error(message?.data?.message, {
        position: "top-right",
      });
    }
    if (isSuccess) {
      navigate("/admin-dashboard");
      dispatch(reset());
    }
    dispatch(reset());
  }, [isError, isSuccess, message]);

  // //(userAdmin, "userAdmin")
  return (
    // <>
    //   <section id={Styles.authBlock}>
    //     <article>
    //       <div className="container">
    //         <Formik
    //           initialValues={{
    //             number: "",
    //             password: "",
    //           }}
    //           validationSchema={schema}
    //           onSubmit={values => {
    //             let browser = navigator.userAgent;
    //             let trim = browser.slice(50, -1);

    //             let deviceId = trim;
    //             localStorage.setItem("device", JSON.stringify(deviceId));
    //             let finaldeviceId = localStorage.getItem("device");
    //             let payload = { ...values, deviceId: finaldeviceId };
    //             dispatch(AdminWithLogin(payload));
    //           }}
    //         >
    //           {({
    //             values,
    //             errors,
    //             touched,
    //             handleChange,
    //             handleBlur,
    //             handleSubmit,
    //             isValid,
    //           }) => {
    //             return (
    //               <form onSubmit={handleSubmit}>
    //                 <h1>Login </h1>
    //                 <div className={Styles.pass}>
    //                   <label htmlFor="number">Phone Number</label>
    //                   <aside>
    //                     <Input
    //                       maxLength={10}
    //                       size="lg"
    //                       type="text"
    //                       name="number"
    //                       placeholder="Number"
    //                       style={{
    //                         borderColor:
    //                           errors.number && touched.number
    //                             ? "red"
    //                             : "inherit",
    //                         marginBottom: 5,
    //                       }}
    //                       onChange={(val, event) => {
    //                         handleChange(event);
    //                         // updateValFromStore ('username', val)
    //                       }}
    //                       onBlur={handleBlur}
    //                       value={values.number}
    //                     />
    //                     <span className={Styles.eye}>
    //                       <BiUser />
    //                     </span>
    //                   </aside>
    //                   <p className={Styles.errors}>
    //                     <ErrorMessage name="number" />
    //                   </p>
    //                   {/* </Whisper> */}
    //                 </div>

    //                 <div className={Styles.pass}>
    //                   <label htmlFor="password">password</label>
    //                   <aside>
    //                     <Input
    //                       size="lg"
    //                       type={isRevealPwd ? "text" : "password"}
    //                       name="password"
    //                       placeholder="password"
    //                       style={{
    //                         borderColor:
    //                           errors.password && touched.password
    //                             ? "red"
    //                             : "inherit",
    //                         marginBottom: 5,
    //                       }}
    //                       onChange={(val, event) => {
    //                         handleChange(event);
    //                         // updateValFromStore('password', val)
    //                       }}
    //                       onBlur={handleBlur}
    //                       value={values.password}
    //                     />
    //                     <span
    //                       className={Styles.eye}
    //                       title={
    //                         isRevealPwd ? "Hide password" : "Show password"
    //                       }
    //                       onClick={() =>
    //                         setIsRevealPwd(prevState => !prevState)
    //                       }
    //                     >
    //                       {isRevealPwd ? <IoMdEye /> : <IoMdEyeOff />}
    //                     </span>
    //                   </aside>
    //                   {/* </Whisper> */}
    //                   <p className={Styles.errors}>
    //                     <ErrorMessage name="password" />
    //                   </p>
    //                 </div>
    //                 <Button type="submit" disabled={!isValid}>
    //                   {isLoading ? (
    //                     <div className={Styles.loader}>
    //                       <RotatingLines
    //                         strokeColor="#f16f1d"
    //                         strokeWidth="5"
    //                         animationDuration="0.75"
    //                         width="50"
    //                         visible={true}
    //                         // z-index={111}
    //                       />
    //                     </div>
    //                   ) : (
    //                     ""
    //                   )}
    //                   Submit
    //                 </Button>
    //               </form>
    //             );
    //           }}
    //         </Formik>
    //       </div>
    //     </article>
    //   </section>
    //   <section className={Style.FooterBlock}>
    //     <article>
    //       <div>
    //         <span></span>
    //         <h3>2022 QTalk</h3>
    //       </div>
    //       <div>
    //         <span className={Style.icons}>
    //           <MdPrivacyTip />
    //         </span>
    //         <h3>Helpline Number - 7338471266</h3>
    //       </div>
    //       <div>
    //         <span className={Style.icons}>
    //           <ImMail />
    //         </span>
    //         <h3> email - help@qspiders.com</h3>
    //       </div>
    //     </article>
    // </section>
    // </>
    // onSubmit={handleSubmit}
    <>
      <section className={styles.authBlock}>
        <article className={styles.formBlock}>
          <div className={styles.container}>
            <Formik
              initialValues={{ number: "", password: "" }}
              validationSchema={schema}
              // validationSchema={validationSchema}
              onSubmit={values => {
                let browser = navigator.userAgent;
                let deviceId = browser.slice(50, -1);
                localStorage.setItem("device", JSON.stringify(deviceId));
                let finaldeviceId = localStorage.getItem("device");
                let payload = { ...values, deviceId: finaldeviceId };
                dispatch(AdminWithLogin(payload));
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <Typography variant="h4" className={styles.heading}>
                      Login
                    </Typography>
                    <img
                      src={process.env.PUBLIC_URL + "/QtalkLogo.png"}
                      alt=""
                      style={{ width: "50px" }}
                    />
                  </div>
                  <div className={styles.inputGroup}>
                    <label htmlFor="number" className={styles.labelHeading}>
                      Phone Number
                    </label>
                    <aside className={styles.inputWrapper}>
                      <TextField
                        name="number"
                        placeholder="Number"
                        variant="outlined"
                        fullWidth
                        value={values.number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.number && Boolean(errors.number)}
                        helperText={touched.number && errors.number}
                        InputProps={{
                          endAdornment: <BiUser className={styles.icon} />,
                        }}
                      />
                    </aside>
                    {/* <ErrorMessage
                      name="number"
                      component="p"
                      className={styles.errorMessage}
                    /> */}
                  </div>

                  <div className={styles.inputGroup}>
                    <label htmlFor="password" className={styles.labelHeading}>
                      Password
                    </label>
                    <aside className={styles.inputWrapper}>
                      <TextField
                        name="password"
                        placeholder="Password"
                        variant="outlined"
                        fullWidth
                        type={isRevealPwd ? "text" : "password"}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        InputProps={{
                          endAdornment: (
                            <span
                              className={styles.icon}
                              onClick={() => setIsRevealPwd(prev => !prev)}
                              title={
                                isRevealPwd ? "Hide password" : "Show password"
                              }
                            >
                              {isRevealPwd ? <IoMdEye /> : <IoMdEyeOff />}
                            </span>
                          ),
                        }}
                      />
                    </aside>
                    {/* <ErrorMessage
                      name="password"
                      component="p"
                      className={styles.errorMessage}
                    /> */}
                  </div>

                  <Button
                    type="submit"
                    fullWidth
                    color="warning"
                    variant="contained"
                    disabled={!isValid || isLoading}
                    className={styles.submitButton}
                  >
                    {isLoading ? (
                      <RotatingLines
                        strokeColor="#f16f1d"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="50"
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        </article>
      </section>

      <section className={styles.footerBlock}>
        <article className={styles.footerContent}>
          <div>
            <p>2022 QTalk</p>
          </div>
          <div>
            {/* <span className={styles.icon}>
              <MdPrivacyTip />
            </span> */}
            <p>Helpline Number - 7338471266</p>
          </div>
          <div>
            {/* <span className={styles.icon}>
              <ImMail />
            </span> */}
            <p>Email - help@qspiders.com</p>
          </div>
        </article>
      </section>
    </>
  );
};

export default AdminLogin;
